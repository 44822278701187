import axios from "axios";
import { FB_API_GRAPH_URL, COUNTRY_DATA_LIST } from "utils/constants";

export function FBApiService(token) {
  const getBusinesses = async () => {
    const response = await axios({
      url: `${FB_API_GRAPH_URL}/me/businesses?access_token=${token}&fields=name,client_ad_accounts.limit(500){name}`,
    });

    return response.data.data;
  };

  const getAllAdAccounts = async () => {
    const response = await axios({
      url: `${FB_API_GRAPH_URL}/me?access_token=${token}&fields=adaccounts.limit(500){name,business}`,
    });

    return response.data.adaccounts;
  };

  const getCustomAudiences =
    (id) =>
    async ({ pageParam = "" }) => {
      const response = axios({
        url: `${FB_API_GRAPH_URL}/${id}/customaudiences?access_token=${token}&fields=id,name,subtype,time_created&limit=5000&after=${pageParam}`,
      });

      return response;
    };

  const searchAudience = async ({ accountId, keyword }) => {
    const response = await axios.get(
      `${FB_API_GRAPH_URL}/${accountId}/customaudiences?fields=name&filtering=[{"field":"name","operator":"CONTAIN","value":"${keyword}"}]&access_token=${token}`
    );
    return response.data;
  };

  const getCampaigns =
    (id) =>
    async ({ pageParam = "" }) => {
      const response = axios({
        url: `${FB_API_GRAPH_URL}/${id}/campaigns?access_token=${token}&fields=name&limit=25&after=${pageParam}`,
      });

      return response;
    };

  const getAdSets =
    (id) =>
    async ({ pageParam = "" }) => {
      const response = axios({
        url: `${FB_API_GRAPH_URL}/${id}/adsets?access_token=${token}&fields=name&limit=25&after=${pageParam}`,
      });
      return response;
    };

  const getAdSetAsyncStatuses = (sessionIds) => async () => {
    const response = await Promise.all(
      sessionIds.map((sessionId) =>
        axios.get(`${FB_API_GRAPH_URL}/${sessionId}?access_token=${token}`)
      )
    );
    return response;
  };

  const getAdSetById = async (adSetId) => {
    const response = await axios({
      url: `${FB_API_GRAPH_URL}/${adSetId}?access_token=${token}&fields=name,targeting`,
    });

    return response.data;
  };

  const duplicateAdSet = async ({ adSetId, count, suffix }) => {
    const suffixLetter = suffix.split(/[0-9]/)[0];
    const suffixNumber = Number(suffix.split(suffixLetter)[1]);

    const asyncbatch = [...Array(count - 1).keys()].map((_, i) => ({
      method: "POST",
      relative_url: `${adSetId}/copies`,
      name: `copy_adset_${suffixLetter}${suffixNumber + (i + 1)}`,
      body: `rename_options={"rename_suffix":"/${suffixLetter}${
        suffixNumber + (i + 1)
      }"}&deep_copy=true&status_option=INHERITED_FROM_SOURCE`,
    }));

    const response = await axios.post(
      `${FB_API_GRAPH_URL}/${adSetId}/copies?access_token=${token}`,
      {
        asyncbatch,
      }
    );

    return response;
  };

  const renameDuplicatedAdSets = async ({ campaignId, adSetName }) => {
    function renameDuplicates(duplicates) {
      function renameBySuffix(name) {
        const [origin, suffix] = name.split(" /");
        const splittedOrigin = origin.split("/");
        const originWithoutSuffix = splittedOrigin.slice(0, -1).join("/");

        return `${originWithoutSuffix}/${suffix}`;
      }

      return duplicates.map((dupl) => ({
        id: dupl.id,
        name: renameBySuffix(dupl.name),
      }));
    }

    const resp = await axios({
      url: `${FB_API_GRAPH_URL}/${campaignId}/adsets?access_token=${token}&fields=name&limit=100&filtering=[{"field":"name","operator":"CONTAIN","value":"${adSetName}"}]`,
    });

    const duplicates = resp.data.data.filter((dupl) => dupl.name !== adSetName);

    const renamedAdSets = renameDuplicates(duplicates);

    const response = await Promise.all(
      renamedAdSets.map((adSet, i) =>
        axios.post(
          `${FB_API_GRAPH_URL}/${adSet.id}?access_token=${token}&fields=name,id`,
          {
            name: adSet.name,
          }
        )
      )
    );
    return response;
  };

  const addCustomAudiencesToAdSet = async (input) => {
    const { id, ...body } = input;
    console.log({body})
    const resp = await getAdSetById(id);
    console.log({'adset': resp})
    const targeting = {
      ...resp.targeting,
      custom_audiences: [
        ...(resp.targeting?.custom_audiences
          ? resp.targeting.custom_audiences
          : []),
        ...body.audiences.map((aud) => { return {id: aud.id, name: aud.name} }),
      ],
    };
    console.log({targeting})

    const response = await axios.post(
      `${FB_API_GRAPH_URL}/${id}?access_token=${token}&fields=name,targeting`,
      { targeting }
    );
    console.log({response})
    return response;
  };

  async function createLookalike(data, params) {
    let { accountId, audienceId, audienceName } = data;

    const result = {
      errorMessages: [],
      successMessages: [],
    };

    const promiseList = [];
    
    let filteredCountries = COUNTRY_DATA_LIST.filter((d) =>
        params.countryData.includes(d.name)
    );
    
    let mergedList = [];
    let llNames = [];
    let llName = "lookalike";
    if (params.merge) {
      filteredCountries.forEach((zone) => {
        mergedList = mergedList.concat(zone.list);
        llNames.push(zone.name);
      });
      llName = llNames.join("-");
      filteredCountries = [{name: llName, list: mergedList}];
    }

    params.ratios.forEach((ratio) => {
      for (const countryData of filteredCountries) {
        const lookalikeAudienceName = `${audienceName}-${ratio}%-${countryData["name"]}-lookalike`;

        promiseList.push({
          promise: axios.post(
            `${FB_API_GRAPH_URL}/${accountId}/customaudiences?access_token=${token}`,
            {
              account_id: accountId.split("act_")[1],
              name: lookalikeAudienceName,
              subtype: "LOOKALIKE",
              origin_audience_id: audienceId,
              lookalike_spec: {
                location_spec: {
                  geo_locations: {
                    countries: countryData["list"],
                  },
                },
                // is_financial_service: false,
                allow_international_seeds: true,
                ratio: parseFloat((Number(ratio) / 100).toFixed(2)),
                type: "custom_ratio",
              },

              locale: "en_US",
              pretty: 1,
            }
          ),
          metadata: {
            lookalikeAudienceName,
          },
        });
      }
    });

    const payload = await Promise.allSettled(promiseList.map((i) => i.promise));

    payload.forEach((item, i) => {
      const { lookalikeAudienceName } = promiseList[i].metadata;
      if (item.status === "fulfilled") {
        result.successMessages.push(
          `Success to create lookalike with params: ${lookalikeAudienceName}`
        );
      } else {
        result.errorMessages.push(
          `Failed to create lookalike with params: ${lookalikeAudienceName}`
        );
      }
    });

    return result;
  }

  const createLookalikes = async ({ input, onProgress = () => {} }) => {
    const allResponses = [];
    let i = 0;
    for (const item of input.items) {
      try {
        const res = await createLookalike(item, input.params);
        allResponses.push(res);
      } catch (e) {
        console.error(e);
        allResponses.push(e);
      } finally {
        onProgress({
          total: input.items.length,
          done: ++i,
        });
      }
    }

    return allResponses;
  };

  const deleteCustomAudience = async (audId) => {
    const response = await axios({
      url: `${FB_API_GRAPH_URL}/${audId}?access_token=${token}`,
      method: 'DELETE'
    });

    return response.data;
  };

  const deleteCustomAudiences = async ({input}) => {
    const allResponses = [];
    for (const item of input) {
      try {
        const res = await deleteCustomAudience(item.id);
        const response = {name: item.name, result: res};
        allResponses.push(response);
      } catch (e) {
        const errorResponse = {name: item.name, result: {success: false, message: e.response.data.error.message}};
        allResponses.push(errorResponse);
      }
    }
    console.log({allResponses})
    return allResponses;
  }

  return {
    getBusinesses,
    getAllAdAccounts,
    getCustomAudiences,
    searchAudience,
    getCampaigns,
    getAdSets,
    getAdSetAsyncStatuses,
    duplicateAdSet,
    renameDuplicatedAdSets,
    addCustomAudiencesToAdSet,
    createLookalikes,
    deleteCustomAudiences
  };
}
