import { memo } from "react";
import { Checkbox, Flex, Text } from "@chakra-ui/react";
import ScrollbarFlex from "components/ScrollbarFlex";

function ParametersCheck({ title, params, onCheck, ...props }) {
  const customStyle =
    title === "Similarity"
      ? {
          flexDirection: "row",
          justifyContent: "center",
          gap: 3,
        }
      : {};
  return (
    <Flex {...props} direction="column">
      <Text>{title}</Text>
      <ScrollbarFlex
        overflowY="scroll"
        flexDirection="column"
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
        p={2}
        pb={0}
        pl={4}
        {...customStyle}
      >
        {params.map((param) => (
          <Checkbox
            key={param.name}
            isChecked={param.isChecked}
            pb={2}
            onChange={(e) =>
              onCheck({
                keyName: title.toLowerCase(),
                name: param.name,
                isChecked: e.target.checked,
              })
            }
          >
            {param.label}
          </Checkbox>
        ))}
      </ScrollbarFlex>
    </Flex>
  );
}

export default memo(ParametersCheck);
