import { Button, Container, Flex } from "@chakra-ui/react";

function LoginButton({ socialSignIn, isLoading }) {
  return (
    <Container height="100vh" maxW="container.lg">
      <Flex align="center" justify="center" h="100%">
        <Button
          _hover={{
            background: "blue.700",
          }}
          p={3}
          h="45px"
          bg="blue.500"
          color="white"
          onClick={() => socialSignIn({ provider: "Facebook" })}
          isLoading={isLoading}
        >
          Login With Facebook
        </Button>
      </Flex>
    </Container>
  );
}

export default LoginButton;
