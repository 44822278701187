import { Select } from "@chakra-ui/react";

function AccountSelect({ businesses, business, account, onAccountChange }) {
  function handleAccountChange(e) {
    const currentBusiness = { ...business };

    if (!currentBusiness.client_ad_accounts) {
      const bsns = businesses.find((bus) => bus.id === currentBusiness.id);
      currentBusiness.client_ad_accounts = bsns.client_ad_accounts;
    }
    const account = currentBusiness?.client_ad_accounts?.data.find(
      (act) => act.id === e.target.value
    );

    onAccountChange(account);
  }

  return business ? (
    <Select
      onChange={handleAccountChange}
      placeholder="Ad Account"
      bg="white"
      mt={2}
      value={account?.id}
    >
      {businesses &&
        businesses
          .filter((bsns) => bsns.id === business.id)
          .map((bsns) =>
            bsns.client_ad_accounts.data
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((acnt) => (
                <option key={acnt.id} value={acnt.id}>
                  {acnt.name}
                </option>
              ))
          )}
    </Select>
  ) : null;
}

export default AccountSelect;
