import { Icon } from "@chakra-ui/react";

const IndiegogoIcon = (props) => (
  <Icon viewBox="0 0 25 24" fill="none" {...props}>
    <rect x="0.5" width="24" height="24" rx="4.9" fill="#EA1F73" />
    <g clipPath="url(#clip0_1854_13759)">
      <path
        d="M17.5649 6.74219C13.3875 6.74219 12.6299 9.49111 12.6299 12.4998C12.6299 15.5084 13.3658 18.2573 17.5649 18.2573C21.7424 18.2573 22.4784 15.5084 22.4784 12.4998C22.5 9.49111 21.7641 6.74219 17.5649 6.74219ZM17.5649 15.6166C15.9199 15.6166 15.6818 14.4695 15.6818 12.6296C15.6818 10.7898 15.9199 9.66427 17.5649 9.66427C19.21 9.66427 19.4264 10.8115 19.4264 12.6296C19.4481 14.4695 19.21 15.6166 17.5649 15.6166Z"
        fill="white"
      />
      <path
        d="M6.87229 13.7768H8.82035V13.9716C8.82035 15.2054 8.86364 15.7682 7.37013 15.7682C5.98485 15.7682 5.50866 15.1405 5.50866 12.5863C5.50866 9.94565 6.28788 9.62098 7.47835 9.62098C8.3658 9.62098 8.97186 9.85907 9.49134 10.1405L11.0931 7.62963C10.2706 7.11015 9.0368 6.74219 7.62987 6.74219C3.75541 6.74219 2.5 8.60366 2.5 12.4998C2.5 15.898 3.40909 18.2573 7.08874 18.2573C7.7381 18.2573 8.30087 18.214 8.77706 18.1275H11.5476V13.4305V11.3526H7.19697L6.87229 13.7768Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1854_13759">
        <rect
          width="20"
          height="11.5152"
          fill="white"
          transform="translate(2.5 6.74219)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default IndiegogoIcon;
