import { Button, Text } from "@chakra-ui/react";

function FbButton({ onOpen, isDisabled }) {
  return (
    <Button
      isDisabled={isDisabled}
      onClick={onOpen}
      _hover={{
        background: "blue.700",
      }}
      p={2}
      w="100%"
      h="45px"
      mt={5}
      bg="blue.500"
      color="white"
    >
      <Text>Send to Facebook Audience</Text> <Text ml={3}>{"->"}</Text>
    </Button>
  );
}

export default FbButton;
