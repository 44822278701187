import { memo } from "react";
import { Checkbox, Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import audienceStore from "stores/audienceStore";
import { trigger } from "utils/events";

function SelectAllButton({ allChecked, isIndeterminate }) {
  const location = useLocation();
  const isDatabasePath = location.pathname.includes("audience-db");

  return (
    <Flex color="gray.900">
      <Checkbox
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        p={2}
        pt={3}
        onChange={(e) => {
          if (isDatabasePath) {
            audienceStore.setState((prevState) => ({
              ...prevState,
              selectedAuds: e.target.checked
                ? [...audienceStore.getState().filteredAuds]
                : [],
            }));
          }

          trigger("customAudience:selectAll", e.target.checked);
        }}
      >
        Select All
      </Checkbox>
    </Flex>
  );
}

export default memo(SelectAllButton);
