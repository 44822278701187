import {Button, Flex, Heading, Switch, Text} from "@chakra-ui/react";
import ResetIcon from "components/icons/ResetIcon";
import ParametersCheck from "./ParametersCheck";
import {useEffect, useState} from "react";

function ParameterSection({ params, onReset, onCheck }) {
  const [isMerged, setIsMerged] = useState(false);

  const handleSwitchToggle = (toggled) => {
      console.log({toggled})
      setIsMerged(toggled);
  }

  useEffect(() => {
      params["merge"] = isMerged;
  }, [isMerged]);

  return (
    <Flex pt={2} direction="column" w="full">
      <Flex maxH="10%" pt={1}>
        <Heading lineHeight="24px" fontSize="16px" fontFamily="Inter">
          Parameters
        </Heading>
        <Button
          ml="auto"
          leftIcon={<ResetIcon height="13px" width="13px" />}
          color="gray.700"
          height="24px"
          disabled={false}
          onClick={onReset}
          fontSize="13px"
          p={1}
          pr={2}
          bg="none"
          border="1px"
          borderColor="gray.200"
          borderRadius="6px"
        >
          Reset
        </Button>
      </Flex>
      <Flex direction="column" minH="80%">
        <ParametersCheck
          mt={2}
          title="Similarity"
          params={params["similarity"]}
          onCheck={onCheck}
        />
        <Text mt={3}>
            Merge locations
        </Text>
        <Switch
            onChange={(e) => handleSwitchToggle(e.target.checked)}
            isChecked={isMerged}
        />
        <ParametersCheck
          minH="50%"
          mt={3}
          title="Locations"
          params={params["locations"]}
          onCheck={onCheck}
        />
      </Flex>
    </Flex>
  );
}

export default ParameterSection;
