import { Button, Text } from "@chakra-ui/react";

function CreateLookalikeButton(props) {
  return (
    <Button
      _hover={{
        background: "blue.700",
      }}
      p={2}
      w="100%"
      h="45px"
      bg="blue.500"
      color="white"
      {...props}
    >
      <Text>Create Lookalikes</Text> <Text ml={3}>{"->"}</Text>
    </Button>
  );
}

export default CreateLookalikeButton;
