import { Icon } from "@chakra-ui/react";

const ThreeDotIcon = (props) => (
  <Icon viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
      stroke="#718096"
      strokeWidth="1.475"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z"
      stroke="#718096"
      strokeWidth="1.475"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z"
      stroke="#718096"
      strokeWidth="1.475"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default ThreeDotIcon;
