import { AMPLIFY_API } from "utils/constants";

export function AdSetService({ API }) {
  const addRefLinksToAdSets = async (input) => {
    const response = await API.post(AMPLIFY_API, "/n-adsets", input);
    return response;
  };

  return {
    addRefLinksToAdSets,
  };
}
