export const listCategories = `
  query ListCategories {
    listCategories {
      items {
        id
        name
        subcategories(sortDirection: ASC) {
          items {
            id
            name
          }
        }
      }
    }
  }
`;

export const audienceByName = /* GraphQL */ `
  query AudienceByName(
    $type: String!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audienceByName(
      type: $type
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          items {
            characteristic {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      nextToken
    }
  }
`;

export const listAudienceLogs = `
  query ListAudienceLogs {
    listAudienceLogs {
      items {
        id
        username
        accountName
        type
        numberOfUsers
        createdAt
      }
    }
  }
`;

export const createAudienceLog = `
    mutation CreateAudienceLogMutation($input: CreateAudienceLogInput!) {
        createAudienceLog(input: $input) {
            username
            audienceName
            accountName
            businessName
            numberOfUsers
            type
        }
    }
`;