import { Fragment } from "react";
import { useCampaign } from "contexts/CampaignContext";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import Loading from "components/Loading";
import ScrollbarFlex from "components/ScrollbarFlex";
import CustomRadio from "components/CustomRadio";
import { trigger } from "utils/events";

function CampaignListSelect({
  campaigns,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  isFetching,
}) {
  const { setCampaign } = useCampaign();
  function handleCampaignScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 25) {
      !isFetchingNextPage && hasNextPage && fetchNextPage();
    }
  }
  return (
    <ScrollbarFlex
      direction="column"
      onScroll={handleCampaignScroll}
      overflowY="auto"
      overflowX="hidden"
      align="stretch"
      color="gray.900"
      width="full"
      height="full"
      pb={4}
    >
      {!isFetchingNextPage && isFetching ? (
        <Loading />
      ) : (
        campaigns?.pages?.map((page, index) => (
          <Fragment key={index}>
            {page.data.data.map((camp) => (
              <Box mb={4} color="gray.900" key={camp.id} lineHeight="24px">
                <CustomRadio
                  name="campaign"
                  onRadioChange={(campaign) => {
                    trigger("campaign:select", JSON.parse(campaign));
                    setCampaign(JSON.parse(campaign));
                  }}
                >
                  {camp}
                </CustomRadio>
              </Box>
            ))}
          </Fragment>
        ))
      )}
      <Flex p={2} align="center" justify="center">
        {isFetchingNextPage && <Spinner />}
      </Flex>
    </ScrollbarFlex>
  );
}

export default CampaignListSelect;
