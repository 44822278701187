import { useAmplify } from "contexts/AmplifyContext";
import { useMutation } from "react-query";
import { SyncService } from "services/sync";

const useSyncFactory = () => {
  const { API } = useAmplify();
  return SyncService({ API });
};

export const useSyncWithS3 = () => {
  const mutation = useMutation(useSyncFactory().syncWithS3);
  return mutation;
};

export const useSyncWithGsheet = () => {
  const mutation = useMutation(useSyncFactory().syncWithGsheet);
  return mutation;
};
