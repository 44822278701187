import { useState, useEffect } from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import SearchIcon from "components/icons/SearchIcon";

function SearchField({ onSearch }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => onSearch(value), 0);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <InputGroup mt={5}>
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="gray.400" />}
      />
      <Input
        type="tel"
        placeholder="Search"
        value={value}
        onChange={(e) => setValue(e.target.value.toLowerCase())}
      />
    </InputGroup>
  );
}

export default SearchField;
