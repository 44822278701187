import { useEffect, useState } from "react";
import subcategoryStore from "stores/subcategoryStore";

export const useSubcategoriesSelect = () => {
  const [deferredSelected, setDeferredSelected] = useState([]);
  const selectedSubcategories = subcategoryStore.useStore();

  useEffect(() => {
    function normalizeSelected(subs) {
      let allSubs = [];
      for (const key in subs) {
        allSubs.push(...subs[key].map((v) => v.id));
      }
      return allSubs;
    }
    const tId = setTimeout(() => {
      setDeferredSelected(normalizeSelected(selectedSubcategories));
    }, 100);
    return () => clearTimeout(tId);
  }, [selectedSubcategories]);

  useEffect(() => {
    return () => {
      subcategoryStore.setState(() => ({}));
    };
  }, []);

  return deferredSelected;
};
