import { Flex, Spinner } from "@chakra-ui/react";

function LoadingWithBg({ text }) {
  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      h="full"
      width="100%"
      position="absolute"
      right="0"
      top="0"
      bg="rgba(0, 0, 0, 0.5)"
      zIndex="999"
      backdropFilter="blur(24px)"
    >
      <Spinner color="white" size="lg" />
      <Flex
        width="40%"
        textAlign="center"
        mt={6}
        color="white"
        fontWeight="400"
      >
        {text}
      </Flex>
    </Flex>
  );
}

export default LoadingWithBg;
