import { useEffect, useState } from "react";
import { useAudience } from "contexts/AudienceContext";
import ScrollbarFlex from "components/ScrollbarFlex";
import { useGetAudiences } from "hooks/audience";
import Loading from "components/Loading";
import CheckboxDisplay from "components/CheckboxDisplay";
import filterStore from "stores/filterStore";
import audienceStore from "stores/audienceStore";
import helperStore from "stores/helperStore";
import generateSubcategoryNumbers from "utils/generateSubcategoryNumbers";
import { useSubcategoriesSelect } from "utils/useSubcategoriesSelect";

function Audiences({ checkedItems, handleSingleCheck, searchKeyword }) {
  const { data: audiences, isLoading, isRefetching } = useGetAudiences();
  const { setNumberOfLoadedAudiences } = useAudience();
  const filtersState = filterStore.useStore();

  const deferredSelected = useSubcategoriesSelect();

  useEffect(() => {
    if (audiences?.length > 0) {
      setNumberOfLoadedAudiences(audiences.length);
      helperStore.setState((prevState) => ({
        ...prevState,
        subcategoryByAudienceNumbers: generateSubcategoryNumbers(audiences),
      }));
    }
    audienceStore.setState((prevState) => ({
      ...prevState,
      filteredAuds: audiences,
    }));
  }, [audiences]);

  useEffect(() => {
    audienceStore.setState((prevState) => ({
      ...prevState,
      selectedAuds: [],
    }));

    return () => {
      audienceStore.setState(() => ({
        selectedAuds: [],
        filteredAuds: [],
      }));
      filterStore.setState(() => ({
        characteristics: [],
        campaignType: "",
        price: "",
        year: "",
      }));
    };
  }, []);

  if (isLoading || isRefetching) {
    return <Loading />;
  }

  return (
    <ScrollbarFlex
      direction="column"
      overflowY="auto"
      spacing={4}
      align="stretch"
      pt={2}
      color="gray.900"
      width="full"
      pl={2}
    >
      <CheckboxDisplay
        audiences={audiences}
        searchKeyword={searchKeyword}
        checkedItems={checkedItems}
        handleSingleCheck={handleSingleCheck}
        selectedSubcategoryIds={deferredSelected}
        filtersState={filtersState}
      />
    </ScrollbarFlex>
  );
}

export default Audiences;
