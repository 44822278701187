import { Box, Flex } from "@chakra-ui/react";
import { GOD_LEVEL_WIDTHS } from "utils/constants";

import ListRow from "./ListRow";

function GeneratedLists({ lists, onDownload, onUpload }) {
  return (
    <Flex w="full" mt={3} flexDir="column" mb={6}>
      <Flex
        border="1px"
        borderColor="gray.200"
        borderTopRadius={4}
        w="full"
        bg="gray.50"
      >
        <Box width={GOD_LEVEL_WIDTHS.nameWidth} pl={3}>
          Name
        </Box>
        <Box width={GOD_LEVEL_WIDTHS.scoreWidth}>Score</Box>
        <Box width={GOD_LEVEL_WIDTHS.sizeWidth}>Size</Box>
        <Box width={GOD_LEVEL_WIDTHS.actionWidth} textAlign="right" mr={5}>
          Action
        </Box>
      </Flex>
      {lists.map((item) => (
        <ListRow
          key={item[0]}
          item={item}
          onDownload={onDownload}
          onUpload={onUpload}
        />
      ))}
    </Flex>
  );
}

export default GeneratedLists;
