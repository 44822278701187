import { Icon } from "@chakra-ui/react";

const DownloadIcon = (props) => (
  <Icon viewBox="0 0 14 18" fill="none" {...props}>
    <path
      d="M11.6875 5.25H7.625V11.241L9.6832 9.1832C9.80137 9.07094 9.95871 9.00928 10.1217 9.01137C10.2847 9.01345 10.4404 9.07912 10.5556 9.19437C10.6709 9.30962 10.7365 9.46534 10.7386 9.62831C10.7407 9.79129 10.6791 9.94863 10.5668 10.0668L7.4418 13.1918C7.3246 13.3089 7.16569 13.3747 7 13.3747C6.83431 13.3747 6.6754 13.3089 6.5582 13.1918L3.4332 10.0668C3.32094 9.94863 3.25928 9.79129 3.26137 9.62831C3.26345 9.46534 3.32912 9.30962 3.44437 9.19437C3.55962 9.07912 3.71534 9.01345 3.87831 9.01137C4.04129 9.00928 4.19863 9.07094 4.3168 9.1832L6.375 11.241V5.25H2.3125C1.73253 5.25062 1.17649 5.48129 0.766389 5.89139C0.356288 6.30149 0.12562 6.85753 0.125 7.4375V15.5625C0.12562 16.1425 0.356288 16.6985 0.766389 17.1086C1.17649 17.5187 1.73253 17.7494 2.3125 17.75H11.6875C12.2675 17.7494 12.8235 17.5187 13.2336 17.1086C13.6437 16.6985 13.8744 16.1425 13.875 15.5625V7.4375C13.8744 6.85753 13.6437 6.30149 13.2336 5.89139C12.8235 5.48129 12.2675 5.25062 11.6875 5.25Z"
      fill="#718096"
    />
    <path
      d="M7.625 0.875C7.625 0.70924 7.55915 0.550268 7.44194 0.433058C7.32473 0.315848 7.16576 0.25 7 0.25C6.83424 0.25 6.67527 0.315848 6.55806 0.433058C6.44085 0.550268 6.375 0.70924 6.375 0.875V5.25H7.625V0.875Z"
      fill="#718096"
    />
  </Icon>
);

export default DownloadIcon;
