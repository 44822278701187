import { Outlet } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import Header from "./Header";

function Layout() {
  return (
    <Container maxW="container.xl" h="100vh">
      <Header />
      <Outlet />
    </Container>
  );
}

export default Layout;
