import { Select } from "@chakra-ui/react";

function BusinessSelect({ business, businesses, onBusinessChange }) {
  function handleBusinessChange(e) {
    const business = businesses.find((bsns) => bsns.id === e.target.value);
    onBusinessChange(business);
  }

  return (
    <Select
      onChange={handleBusinessChange}
      placeholder="Facebook Business Manager"
      bg="white"
      value={business?.id}
    >
      {businesses &&
        businesses.map((bsns) => (
          <option key={bsns.id} value={bsns.id}>
            {bsns.name}
          </option>
        ))}
    </Select>
  );
}

export default BusinessSelect;
