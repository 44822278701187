import { AmplifyProvider } from "providers/AmplifyProvider";
import { QueryClientProvider } from "providers/QueryClientProvider";
import { ChakraProvider } from "providers/ChakraProvider";
import { AccountProvider } from "contexts/AccountContext";
import { AudienceProvider } from "contexts/AudienceContext";
import { AdSetProvider } from "contexts/AdSetContext";
import { RouterProvider, Router } from "pages";
import { CampaignProvider } from "contexts/CampaignContext";
import { AsyncSessionProvider } from "contexts/AsyncSessionContext";
import { TCFTrackerProvider } from "contexts/TCFTrackerContext";

function App() {
  return (
    <AmplifyProvider>
      <QueryClientProvider>
        <RouterProvider>
          <AsyncSessionProvider>
            <AccountProvider>
              <AudienceProvider>
                <CampaignProvider>
                  <AdSetProvider>
                    <TCFTrackerProvider>
                      <ChakraProvider>
                        <Router />
                      </ChakraProvider>
                    </TCFTrackerProvider>
                  </AdSetProvider>
                </CampaignProvider>
              </AudienceProvider>
            </AccountProvider>
          </AsyncSessionProvider>
        </RouterProvider>
      </QueryClientProvider>
    </AmplifyProvider>
  );
}

export default App;
