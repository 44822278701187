import CategorySection from "pages/AudienceDB/CategorySection";
import BodyLayout from "pages/Layout/BodyLayout";
import LookalikeRightSection from "./LookalikeRightSection";
import FBAudienceSelectSection from "pages/AudienceManager/FBAudienceSelectSection";

function Lookalike() {
  return (
    <BodyLayout
      middleSection={{ element: <FBAudienceSelectSection /> }}
      rightSection={{ element: <LookalikeRightSection /> }}
    />
  );
}

export default Lookalike;
