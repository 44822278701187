import { AuthService } from "services/auth";
import { UserService } from "services/user";
import { useMutation } from "react-query";
import { useAmplify } from "contexts/AmplifyContext";

const useAuthFactory = () => {
  const { Amplify } = useAmplify();
  return AuthService(UserService, Amplify.Auth);
};

export const useSocialSignIn = () => {
  const mutation = useMutation(useAuthFactory().socialSignIn);
  return mutation;
};

export const useSignOut = () => {
  const mutation = useMutation(useAuthFactory().signOut);

  return mutation;
};
