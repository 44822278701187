import { Box, Checkbox } from "@chakra-ui/react";

function CustomCheckbox(props) {
  return (
    <Box position="relative">
      <Box
        width="15px"
        height="14px"
        bg="white"
        position="absolute"
        top="1"
        borderRadius="2"
      />
      <Checkbox {...props} />
    </Box>
  );
}

export default CustomCheckbox;
