import { useEffect } from "react";
import { Button, Flex, Input, Text } from "@chakra-ui/react";
import IndiegogoIcon from "components/icons/IndiegogoIcon";
import KickStarterIcon from "components/icons/KickStarterIcon";
import TCFIcon from "components/icons/TCFIcon";
import TrackerWarningIcon from "components/icons/TrackerWarningIcon";
import { useTCFTracker } from "contexts/TCFTrackerContext";
import { LINKS } from "utils/constants";

function GroupIdPage() {
  const { groupId, setGroupId, linkName, setLinkName } = useTCFTracker();

  useEffect(() => {
    return () => setGroupId("");
  }, []);

  function renderButton(icon, buttonText) {
    const selectedStyle = {
      bg: "#F7FAFC",
      borderColor: "gray.500",
    };

    const linksByButtonText = {
      "TCF Tracker": LINKS.TCF_TRACKER,
      "KS Referral": LINKS.KS_REFERRAL,
      Indiegogo: LINKS.INDIEGOGO,
    };

    return (
      <Button
        leftIcon={icon}
        color="gray.700"
        fontSize="13px"
        p={1}
        pr={2}
        bg="none"
        border="1px"
        borderColor="gray.200"
        borderRadius="8px"
        _focus={selectedStyle}
        {...(linkName === linksByButtonText[buttonText] && selectedStyle)}
        onClick={() => setLinkName(linksByButtonText[buttonText])}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <Flex justifyContent="center" alignItems="center" width="full">
      <Flex width="57%" flexDir="column">
        <Text fontWeight="600" align="center">
          You Are Going to Create N Referral tags on Kickstarter and Links in
          TCF Tracker.
        </Text>

        <Flex justifyContent="space-around" mt={12}>
          {renderButton(<TCFIcon height="24px" width="24px" />, "TCF Tracker")}
          {renderButton(
            <KickStarterIcon height="24px" width="24px" />,
            "KS Referral"
          )}
          {renderButton(
            <IndiegogoIcon height="24px" width="24px" />,
            "Indiegogo"
          )}
        </Flex>

        <Text fontSize="14px" color="gray.700" mt={5}>
          Add the TCF tracker group ID
        </Text>
        <Input
          placeholder={
            linkName === LINKS.INDIEGOGO ? "Indiegogo URL" : "Input ID"
          }
          mt={3}
          value={groupId}
          onChange={(e) => setGroupId(e.target.value.trim())}
        />
        <Flex backgroundColor="gray.50" mt={8} p={4} pl={2} borderRadius={8}>
          <Flex width="20%" justifyContent="center" alignItems="center">
            <TrackerWarningIcon width="32px" height="30px" />
          </Flex>
          <Flex fontSize="14px" color="gray.700" fontWeight="400" pl={1}>
            Please note: Referral tags on Kickstarter can’t be deleted. This
            change is irreversible and can’t be edited later!
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default GroupIdPage;
