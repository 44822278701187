import { Icon } from "@chakra-ui/react";

const CloseIcon = (props) => (
  <Icon viewBox="0 0 10 10" {...props}>
    <path
      d="m5 4.057 3.3-3.3.943.943-3.3 3.3 3.3 3.3-.943.943-3.3-3.3-3.3 3.3L.757 8.3l3.3-3.3-3.3-3.3L1.7.757l3.3 3.3Z"
      fill="currentColor"
    />
  </Icon>
);

export default CloseIcon;
