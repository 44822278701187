import {
  Button,
  Flex,
  Modal as MainModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import TrackerWarningIcon from "components/icons/TrackerWarningIcon";

function Modal({ isOpen, onClose, isLoading, onConfirm }) {
  return (
    <MainModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="575px">
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="center" alignItems="center" flexDir="column">
            <TrackerWarningIcon width="56px" height="53px" mt={8} />
            <Text
              align="center"
              paddingX="10"
              mt="5"
              fontSize="18px"
              fontWeight="600"
              color="#333"
            >
              Please note: Referral tags on Kickstarter can’t be deleted. This
              action is irreversible and can’t be edited later!
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            bg="white"
            _hover={{
              background: "white",
            }}
            _active={{
              background: "white",
            }}
            onClick={onClose}
            mr={3}
            color="gray.700"
            fontWeight="600"
          >
            Cancel
          </Button>
          <Button
            color="white"
            bg="gray.700"
            _hover={{
              background: "gray.800",
            }}
            fontWeight="600"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </MainModal>
  );
}

export default Modal;
