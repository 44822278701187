import {useLocation} from "react-router-dom";
import {
    Box, Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Tab,
    TabList,
    Tabs,
    Spinner,
    Text,
    Textarea, useToast
} from "@chakra-ui/react";
import {Select} from "chakra-react-select";
import {SELECT_FILTERS} from "../../utils/constants";
import {useGetCategories, useGetCharacteristics} from "../../hooks/audience";
import React, {useEffect, useRef, useState} from "react";
import {AudienceService} from "../../services/audience";
import {useAmplify} from "../../contexts/AmplifyContext";
import { useNavigate, useHistory } from "react-router-dom";
import Toast from "../../components/Toast";

export function ListForm() {
    const { API, graphqlOperation } = useAmplify();
    const { state } = useLocation();
    const files = state.files ? Array.from(state.files) : [];
    const audList = state.aud ?? {}
    const [tabFiles, setTabFiles] = useState(files);
    const { data: categories } = useGetCategories();
    const { data: characteristics } = useGetCharacteristics();
    const [subCategories, setSubCategories] = useState([]);
    const [activeFile, setActiveFile] = useState(files.length ? files[0].name : audList.id);
    const [formState, setFormState] = useState({});
    const [listType, setListType] = useState({"value": "custom", "label": "Custom"});
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
    const [formValidation, setFormValidation] = useState({});
    const [formSaveErrors, setFormSaveErrors] = useState({});
    const navigate = useNavigate();
    const toast = useToast();
    const toastSuccessRef = useRef();
    const toastErrorRef = useRef();
    const [tabIndex, setTabIndex] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const listTypeLabels = {'custom': 'Custom', 'merged': 'Merged'}

    useEffect(() => {

        let formData = {};
        let formValidationData = {};
        let formSaveErrorsData = {};
        if (tabFiles.length) {
            tabFiles.forEach((file) => {
                const fileReader = new FileReader();
                fileReader.onloadend = (e) => {
                    formData[file.name].fileContent = e.target.result;
                };
                fileReader.readAsText(file);
                formData[file.name] = {
                    listType: {'value': 'custom', 'label': 'Custom'},
                    name: '',
                    year: {},
                    campaignLink: '',
                    campaignType: {},
                    raised: '',
                    topLevelCat: {},
                    subCat: {},
                    characteristics: [],
                    price: '',
                    description: ''
                };

                formValidationData[file.name] = {
                    listType: true,
                    name: true,
                    year: true,
                    campaignLink: true,
                    campaignType: true,
                    raised: true,
                    topLevelCat: true,
                    subCat: true,
                    characteristics: true,
                    price: true,
                    description: true
                }

                formSaveErrorsData[file.name] = '';
            });
        } else {
            setIsEdit(true);
            setListType({'value': audList.listType, 'label': listTypeLabels[audList.listType]});
            formData[audList.id] = {
                listType: listType,
                id: audList.id,
                number: audList.number,
                name: audList.name,
                year: audList.year ? {'value': audList.year, 'label': audList.year} : {},
                campaignLink: audList.campaignLink ?? '',
                campaignType: audList.campaignType ? {'value': audList.campaignType, 'label': audList.campaignType} : {},
                raised: audList.raised ?? '',
                topLevelCat: audList.subcategory ? {'value': audList.subcategory.categorySubcategoriesId, 'label': categories?.filter((tcat) => tcat.id === audList.subcategory.categorySubcategoriesId)[0].name} : {},
                subCat: audList.subcategory ? {'value': audList.subcategory.id, 'label': audList.subcategory.name} : {},
                characteristics: audList.characteristics ? audList.characteristics.items.map((char) => { return {'value': char.characteristic.id, 'label': char.characteristic.name} }) : [],
                price: audList.price ?? '',
                description: audList.description ?? ''
            };

            formValidationData[audList.id] = {
                listType: true,
                number: true,
                name: true,
                year: true,
                campaignLink: true,
                campaignType: true,
                raised: true,
                topLevelCat: true,
                subCat: true,
                characteristics: true,
                price: true,
                description: true
            }

            formSaveErrorsData[audList.id] = '';
        }

        setFormState(formData);
        setFormValidation(formValidationData);
        setFormSaveErrors(formSaveErrorsData);
    }, []);

    const onTabChange = (tab) => {
        setActiveFile(files[tab].name);
        setListType(formState[files[tab].name].listType);
        if (formState[files[tab].name].topLevelCat.value) {
            setSubCategories(categories.filter((tcat) => tcat.id === formState[files[tab].name]?.topLevelCat.value)[0].subcategories.items);
        } else {
            setSubCategories([]);
        }
        setTabIndex(tab);
    }

    const onInputChange = (e, name) => {
        let inputValue;
        if (e.value || Array.isArray(e)) {
            inputValue = e
        } else {
            inputValue = e.target.value;
        }

        setFormState((prevState) => {
            return {
                ...prevState,
                [activeFile]: {
                    ...prevState[activeFile],
                    [name]: inputValue
                }
            };
        });

        setFormValidation((prevState) => {
            return {
                ...prevState,
                [activeFile]: {
                    ...prevState[activeFile],
                    [name]: (inputValue.length || Object.keys(inputValue).length)
                }
            };
        });
        setIsSaveButtonLoading(false);
    }

    useEffect(() => {
        setFormState((prevState) => {
            // prevState[activeFile] = {};
            prevState[activeFile]['listType'] = listType;
            return prevState;
        });
    }, [listType]);

    const validateForm = () => {
        let formIsValid = true;
        let formValidationData = {};
        for (const fileName in formState) {
            const formValues = formState[fileName];
            formValidationData[fileName] = {};
            for (const formValuesKey in formValues) {
                if (formValues[formValuesKey].length || Object.keys(formValues[formValuesKey]).length || formValues[formValuesKey] > 0) {
                    formValidationData[fileName][formValuesKey] = true;
                    if (formValuesKey === 'campaignLink' && !formValues[formValuesKey].startsWith("https://") && !formValues[formValuesKey].startsWith("http://")) {
                        formValidationData[fileName][formValuesKey] = false;
                    }
                } else {
                    formValidationData[fileName][formValuesKey] = false;
                    formIsValid = false;
                }
            }
        }
        setFormValidation(formValidationData);
        return formIsValid;
    }

    function closeToast(ref) {
        if (ref.current) {
            toast.close(ref.current);
        }
    }

    const onFormSubmit = () => {
        setIsSaveButtonLoading(true);
        if (validateForm()) {
            if (!isEdit) {
                createAudiences(Object.values(formState));
            } else {
                updateAudience(Object.values(formState)[0]);
            }
        }
    }

    const createAudiences = (audiences) => {
        AudienceService({API, graphqlOperation}).createLists({data: audiences}).then((res) => {
            const items = res.items;
            const errors = [];
            const success = [];
            let errorMessages = {};
            let firstTabIndex = -1;
            for (let i = 0; i < items.length; i++) {
                if (items[i].hasOwnProperty('error')) {
                    errors.push(items[i]);
                    errorMessages[files[i].name] = items[i].message;
                    if (firstTabIndex < 0) {
                        firstTabIndex = i;
                    }
                } else {
                    delete formState[files[i].name];
                    delete files[i];
                    success.push(items[i]);
                }
            }
            setTabFiles(files);
            setFormSaveErrors(errorMessages);

            if (success.length) {
                toastSuccessRef.current = toast({
                    duration: null,
                    position: 'top',
                    render: () => (
                        <Toast
                            title="Succeed"
                            text={`Created ${success.length} audiences of ${items.length}`}
                            onClick={() => closeToast(toastSuccessRef)}
                        />
                    )
                })
            }
            if (errors.length) {
                setTabIndex(firstTabIndex);
                setActiveFile(files[firstTabIndex].name);
                toastErrorRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Error"
                            type="error"
                            text={`Error for ${errors.length} audiences of ${items.length}`}
                            onClick={() => closeToast(toastErrorRef)}
                        />
                    ),
                })
            } else {
                navigate('/db-manager');
            }
        })
            .catch((err) => {
                toastErrorRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Error"
                            type="error"
                            text="Network error"
                            onClick={() => closeToast(toastErrorRef)}
                        />
                    ),
                })
            })
            .finally((res) => {
                setIsSaveButtonLoading(false);
            });
    }

    const updateAudience = (audience) => {
        AudienceService({API, graphqlOperation}).updateList({data: audience}).then((res) => {
            const items = res.items;
            let error = '';
            let errorMessages = {};

            if (res.hasOwnProperty('error')) {
                errorMessages[audList.id] = res.body;
                setFormSaveErrors(errorMessages);

                toastErrorRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Error"
                            type="error"
                            text={res.body}
                            onClick={() => closeToast(toastErrorRef)}
                        />
                    ),
                })
            } else {
                toastSuccessRef.current = toast({
                    duration: null,
                    position: 'top',
                    render: () => (
                        <Toast
                            title="Succeed"
                            text={`Updated 1 audience`}
                            onClick={() => closeToast(toastSuccessRef)}
                        />
                    )
                });
                navigate('/db-manager');
            }
        })
            .catch((err) => {
                toastErrorRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Error"
                            type="error"
                            text="Network error"
                            onClick={() => closeToast(toastErrorRef)}
                        />
                    ),
                })
            })
            .finally((res) => {
                setIsSaveButtonLoading(false);
            });
    }


    return (
        <Flex pt={3} pb={3}>
            <Box width="70%" p={3}>
                <FormControl mb={3} isRequired isDisabled={isEdit}>
                    <FormLabel>List type</FormLabel>
                    <Select
                        options={[
                            {
                                value: 'custom',
                                label: 'Custom'
                            },
                            {
                                value: 'merged',
                                label: 'Merged'
                            }
                        ]}
                        // defaultValue={{
                        //     value: 'custom',
                        //     label: 'Custom'
                        // }}
                        onChange={setListType}
                        value={listType}
                    />
                </FormControl>
                <FormControl  mb={3} isRequired isInvalid={!formValidation[activeFile]?.name}>
                    <FormLabel>List name</FormLabel>
                    <Input
                        onChange={(e) => onInputChange(e, 'name')}
                        value={formState[activeFile]?.name || ''}
                    />
                    {/*<FormErrorMessage>Field is required</FormErrorMessage>*/}
                </FormControl>
                { isEdit &&
                    <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.number}>
                        <FormLabel>Number</FormLabel>
                        <Input
                            type="number"
                            onChange={(e) => onInputChange(e, 'number')}
                            value={formState[activeFile]?.number || ''}
                        />
                    </FormControl>
                }
                { listType.value === 'custom' &&
                    <>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.year}>
                            <FormLabel>Year</FormLabel>
                            <Select
                                options={SELECT_FILTERS.year.map((v) => (
                                    {
                                        value: v.value,
                                        label: v.name
                                    }
                                ))}
                                onChange={(newValue) => {onInputChange(newValue, 'year')}}
                                value={formState[activeFile]?.year || {}}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.campaignLink}>
                            <FormLabel>Campaign link</FormLabel>
                            <Input
                                placeholder="example: https://www.kickstarter.com/... or https://www.indiegogo.com/..."
                                onChange={(e) => onInputChange(e, 'campaignLink')}
                                value={formState[activeFile]?.campaignLink || ''}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.campaignType}>
                            <FormLabel>Campaign type</FormLabel>
                            <Select
                                options={SELECT_FILTERS.campaignType.map((v) => (
                                    {
                                        value: v.value,
                                        label: v.name
                                    }
                                ))}
                                onChange={(newValue) => {onInputChange(newValue, 'campaignType')}}
                                value={formState[activeFile]?.campaignType || null}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.raised}>
                            <FormLabel>Raised</FormLabel>
                            <Input
                                type="number"
                                onChange={(e) => onInputChange(e, 'raised')}
                                value={formState[activeFile]?.raised || ''}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired>
                            <FormLabel>Top level category</FormLabel>
                            <Select
                                options={categories?.map((cat) => (
                                    {
                                        value: cat.id,
                                        label: cat.name
                                    }
                                ))}
                                onChange={(newValue) => {
                                        setSubCategories(categories.filter((tcat) => tcat.id === newValue.value)[0].subcategories.items);
                                        onInputChange(newValue, 'topLevelCat')
                                    }
                                }
                                value={formState[activeFile]?.topLevelCat || {}}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.subCat}>
                            <FormLabel>Subcategory</FormLabel>
                            <Select
                                options={subCategories?.map((scat) => (
                                    {
                                        value: scat.id,
                                        label: scat.name
                                    }
                                ))}
                                onChange={(e) => onInputChange(e, 'subCat')}
                                value={formState[activeFile]?.subCat ?? {}}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.characteristics}>
                            <FormLabel>Characteristics</FormLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={characteristics?.map((char) => (
                                    {
                                        value: char.id,
                                        label: char.name
                                    }
                                ))}
                                onChange={(e) => onInputChange(e, 'characteristics')}
                                value={formState[activeFile]?.characteristics ?? []}
                            />
                        </FormControl>
                        <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.price}>
                            <FormLabel>Price</FormLabel>
                            <Input
                                type="number"
                                onChange={(newValue) => {onInputChange(newValue, 'price')}}
                                value={formState[activeFile]?.price || ''}
                            />
                        </FormControl>
                    </>
                }
                <FormControl mb={3} isRequired isInvalid={!formValidation[activeFile]?.description}>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                        rows={5}
                        onChange={(newValue) => {onInputChange(newValue, 'description')}}
                        value={formState[activeFile]?.description || ''}
                    />
                </FormControl>
            </Box>
            <Flex
                flexDirection="column"
                width="30%"
            >
                <Tabs
                    orientation="vertical"
                    backgroundColor="#F7FAFC"
                    maxH="480px"
                    overflowY="auto"
                    pl={3}
                    pr={3}
                    borderRadius="8px"
                    onChange={(index) => onTabChange(index)}
                    index={tabIndex}
                >
                    <TabList width="100%">
                        {tabFiles.map((file, index) => (
                            <Tab
                                key={file.name.split('.')[0] + index}
                                id={index}
                                flexDirection="column"
                                alignItems="flex-start"
                                backgroundColor="#FFFFFF"
                                mt={3}
                            >
                                <Text fontSize="12px" color="#718096">File {index+1}/{files.length}</Text>
                                <Text>{file.name}</Text>
                                <Text color="red">{formSaveErrors[file.name]}</Text>
                            </Tab>
                        ))}
                    </TabList>
                </Tabs>
                {isSaveButtonLoading ? (
                    <Flex justifyContent="center">
                        <Spinner />
                    </Flex>
                ) :
                    <Button
                        mt={3}
                        bg="#2D3748"
                        color="#FFFFFF"
                        onClick={onFormSubmit}
                        isLoading={isSaveButtonLoading}
                        disabled={isSaveButtonLoading}
                    >Save</Button>
                }
            </Flex>
        </Flex>
    )
}