import { Box, Flex } from "@chakra-ui/react";

function ColumnLayout({
  title,
  hasRightBorder = false,
  columnWidth,
  children,
  ...props
}) {
  const rightBorderStyle = {
    borderRightWidth: "1px",
    borderRightColor: "gray.200",
  };

  return (
    <Flex
      width={columnWidth || "full"}
      direction="column"
      {...(hasRightBorder && rightBorderStyle)}
    >
      <Box color="gray.700" fontWeight="600" p={2} pl={6} bg="gray.50">
        {title}
      </Box>
      <Box p={6} pr={0} pb={0} height="95%" overflow="hidden" {...props}>
        {children}
      </Box>
    </Flex>
  );
}

export default ColumnLayout;
