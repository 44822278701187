import { AMPLIFY_API } from "utils/constants";

export function SyncService({ API }) {
  const syncWithS3 = async (input) => {
    const response = await API.post(AMPLIFY_API, "/sync-s3", input);
    return response;
  };

  const syncWithGsheet = async (input) => {
    const response = await API.post(AMPLIFY_API, "/sync-gSheet", input);
    return response;
  };

  return {
    syncWithS3,
    syncWithGsheet,
  };
}
