import { Select } from "@chakra-ui/react";

function FilterSelect(props) {
  return (
    <Select {...props} fontSize="12px" fontWeight="700" mr={2}>
      {props.options.map((opt) => (
        <option style={{ fontSize: "14px" }} key={opt.value} value={opt.value}>
          {opt.name}
        </option>
      ))}
    </Select>
  );
}

export default FilterSelect;
