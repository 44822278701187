import { createContext, useContext, useState } from "react";

export const AsyncSessionContext = createContext({});

export const useAsyncSession = () => useContext(AsyncSessionContext);

export const AsyncSessionProvider = ({ children }) => {
  const [adSetAsyncIds, setAdSetAsyncIds] = useState([]);

  return (
    <AsyncSessionContext.Provider
      value={{
        adSetAsyncIds,
        setAdSetAsyncIds,
      }}
    >
      {children}
    </AsyncSessionContext.Provider>
  );
};
