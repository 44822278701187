import { Icon } from "@chakra-ui/react";

const TCFIcon = (props) => (
  <Icon viewBox="0 0 25 24" fill="none" {...props}>
    <rect x="0.5" width="24" height="24" rx="4.9" fill="#FAA61A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1224 5.93679C17.1224 6.28198 16.9842 6.6128 16.7386 6.85537L12.5 11.0417L8.26148 6.85537C8.01589 6.6128 7.87766 6.28198 7.87766 5.93679V3L12.5 7.56545L17.1224 3V5.93679ZM7.35537 7.76146C7.11279 7.51586 6.78198 7.37763 6.43678 7.37763H3.5L8.06544 12L3.5 16.6224H6.43678C6.78198 16.6224 7.11279 16.4842 7.35537 16.2386L11.5417 12L7.35537 7.76146ZM18.5632 7.37763C18.218 7.37763 17.8872 7.51586 17.6446 7.76146L13.4583 12L17.6446 16.2386C17.8872 16.4842 18.218 16.6224 18.5632 16.6224H21.5L16.9345 12L21.5 7.37763H18.5632ZM17.1224 18.0632C17.1224 17.718 16.9842 17.3872 16.7386 17.1446L12.5 12.9583L8.26148 17.1446C8.01589 17.3872 7.87766 17.718 7.87766 18.0632V21L12.5 16.4345L17.1224 21V18.0632Z"
      fill="white"
    />
  </Icon>
);

export default TCFIcon;
