import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { addPointsToNumber } from "utils/helpers";

function BackersInfo({ info, isLoading }) {
  return (
    <Flex border="1px" borderColor="gray.200" borderRadius={4} w="full" py={3}>
      {isLoading ? (
        <Flex align="center" justify="center" w="full">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Box borderRight="1px" borderColor="gray.200" px={4}>
            <Text fontSize="14px">Backers</Text>
            <Text fontSize="18px" color="blue.400" fontWeight="600">
              {addPointsToNumber(info.backers)}
            </Text>
          </Box>
          <Box borderRight="1px" borderColor="gray.200" px={4}>
            <Text fontSize="14px">Super Backers</Text>
            <Text fontSize="18px" color="blue.400" fontWeight="600">
              {addPointsToNumber(info.super_backers)}
            </Text>
          </Box>
          <Box px={4}>
            <Text fontSize="14px">Number of Lists</Text>
            <Text fontSize="18px" color="blue.400" fontWeight="600">
              {addPointsToNumber(info.lists_count)}
            </Text>
          </Box>
        </>
      )}
    </Flex>
  );
}

export default BackersInfo;
