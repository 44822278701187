import {useGetCharacteristics} from "../../hooks/audience";
import {Box, Flex, IconButton, Input} from "@chakra-ui/react";
import {AddIcon, CheckIcon, EditIcon, SmallCloseIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {gql} from "graphql-tag";
import axios from "axios";

export function ListAttributes() {
    const { data: characteristics } = useGetCharacteristics();
    const [editableChar, setEditableChar] = useState(null);
    const [isNewFormOpen, setIsNewFormOpen] = useState(false);
    const [newChar, setNewChar] = useState({id: '', name: ''});
    const graphql = require("graphql");
    const { print } = graphql;
    const sortedCharacteristics = characteristics?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));

    const onInputChange = (e, characteristic) => {
        const charToUpdate = {id: characteristic.id, name: e.target.value};
        setEditableChar(charToUpdate);
    }

    const onNewInputChange = (e) => {
        const name = e.target.value;
        const id = name.replace(/[\W\s]/g, '_').toUpperCase();
        setNewChar({id, name});
    }

    const onCreateCloseBtnClick = () => {
        setIsNewFormOpen(false);
        setNewChar({id: '', name: ''});
    }

    const addCharacteristic = () => {
        createCharacteristic(newChar).then((res) => {
            console.log({res})
            characteristics.unshift({id: res.data.createCharacteristic.id, name: res.data.createCharacteristic.name});
            setIsNewFormOpen(false);
            setNewChar({id: '', name: ''});
        })
    }

    async function createCharacteristic(char) {
        const createCharacteristicMutation = gql`
            mutation MyMutation($input: CreateCharacteristicInput!) {
                createCharacteristic(input: $input) {
                    id
                    name
                }
            }
        `;

        try {
            const response = await axios({
                url: process.env.REACT_APP_AWS_GRAPHQL,
                method: "post",
                headers: {
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
                data: {
                    query: print(createCharacteristicMutation),
                    variables: {
                        input: {
                            id: char.id,
                            name: char.name
                        },
                    },
                },
            });

            console.log("response.data", response.data);
            return response.data;
        } catch (err) {
            console.error("error", err);
        }
    }

    const onEditCloseBtnClick = () => {
        setEditableChar(null);
    }

    const onPlusBtnClick = () => {
        setIsNewFormOpen(true);
    }

    const editCharacteristic = (characteristic) => {
        setEditableChar(characteristic);
        setIsNewFormOpen(false);
        setNewChar({id: '', name: ''});
    }

    const saveCharacteristic = () => {
        updateCharacteristic(editableChar).then((res) => {
            characteristics[characteristics.findIndex(char => char.id === editableChar.id)].name = editableChar.name;
            setEditableChar(null);
        });
    }

    async function updateCharacteristic(char) {
        const updateCharacteristicMutation = gql`
            mutation MyMutation($input: UpdateCharacteristicInput!) {
                updateCharacteristic(input: $input) {
                    id,
                    name
                }
            }`;

        try {
            const response = await axios({
                url: process.env.REACT_APP_AWS_GRAPHQL,
                method: "post",
                headers: {
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
                data: {
                    query: print(updateCharacteristicMutation),
                    variables: {
                        input: {
                            id: char.id,
                            name: char.name
                        },
                    },
                },
            });

            console.log("response.data", response.data);
            return response.data;
        } catch (err) {
            console.error("error", err);
        }
    }

    return (
        <Flex pt={3} pb={3}>
            <Box>
                <IconButton aria-label=""
                    background="transparent"
                    icon={<AddIcon />}
                    onClick={onPlusBtnClick}
                />
                {isNewFormOpen && (
                    <Flex>
                        <Input
                            value={newChar.name}
                            onChange={(e) => onNewInputChange(e)}
                        />
                        <IconButton aria-label=""
                            background="transparent"
                            icon={<SmallCloseIcon />}
                            onClick={onCreateCloseBtnClick}
                        />
                        <IconButton aria-label=""
                            background="transparent"
                            icon={<CheckIcon />}
                            onClick={addCharacteristic}
                        />
                    </Flex>
                )}
                {sortedCharacteristics?.map((char) =>
                    <Flex key={char.id} mt={3}>
                        <Input
                            placeholder={char.name}
                            value={char.id === editableChar?.id ? editableChar?.name : ''}
                            readOnly={char.id !== editableChar?.id}
                            onChange={(e) => onInputChange(e, char)}
                        />
                        {char.id !== editableChar?.id && (
                            <IconButton aria-label=""
                                background="transparent"
                                icon={<EditIcon />}
                                onClick={() => editCharacteristic(char)}
                            />
                        )}
                        {char.id === editableChar?.id && (
                            <>
                                <IconButton aria-label=""
                                    background="transparent"
                                    icon={<SmallCloseIcon />}
                                    onClick={onEditCloseBtnClick}
                                />
                                <IconButton aria-label=""
                                    background="transparent"
                                    icon={<CheckIcon />}
                                    onClick={saveCharacteristic}
                                />
                            </>
                        )}
                    </Flex>
                )}
            </Box>
        </Flex>
    );
}