import { useMutation, useQuery } from "react-query";
import { TCFTrackerService } from "services/tcftracker";

const useTCFTrackerFactory = () => {
  return TCFTrackerService();
};

export const useGetUrlsByGroupId = () => {
  const mutation = useMutation(useTCFTrackerFactory().getUrlsByGroupId);

  return mutation;
};
