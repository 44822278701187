import { Icon } from "@chakra-ui/react";

const DownIcon = (props) => (
  <Icon viewBox="0 0 12 8" fill="none" {...props}>
    <path
      d="M6.0006 4.97668L10.1256 0.851685L11.3039 2.03002L6.0006 7.33335L0.697266 2.03002L1.8756 0.851685L6.0006 4.97668Z"
      fill="#2D3748"
    />
  </Icon>
);

export default DownIcon;
