import {Box, Flex, FormLabel} from "@chakra-ui/react";
import {useState} from "react";


function DatePickers({props}) {
    const [selectedDates, setSelectedDates] = useState({ startDate: convertDate(props.filterData.startDate), endDate: convertDate(props.filterData.endDate) });

    function convertDate(date) {
        const dateArray = date.toLocaleDateString('default', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('/');
        return dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1];
    }

    function handleStartDateChange(e) {
        const dates = {startDate: e.target.value, endDate: selectedDates.endDate};
        setSelectedDates(dates);
        props.onDatesChange({startDate: new Date(e.target.value), endDate: new Date(selectedDates.endDate)});
    }

    function handleEndDateChange(e) {
        const dates = {startDate: selectedDates.startDate, endDate: e.target.value};
        setSelectedDates(dates);
        props.onDatesChange({startDate: new Date(selectedDates.startDate), endDate: new Date(e.target.value)});
    }

    if (props.selectedDate == 'custom') {
        return(
            <Flex>
                <Box>
                    <FormLabel>Start date</FormLabel>
                    <input
                        type="date"
                        value={selectedDates.startDate}
                        onChange={handleStartDateChange}
                    />
                </Box>
                <Box>
                    <FormLabel>End date</FormLabel>
                    <input
                        type="date"
                        value={selectedDates.endDate}
                        onChange={handleEndDateChange}
                    />
                </Box>
            </Flex>
        );
    }
    return '';
}

export default DatePickers;