import { Box, Flex, Heading } from "@chakra-ui/react";

function LogoSection() {
  return (
    <Flex flexGrow={1}>
      <Box w={6} bg="black" mr={3} borderRadius={4}></Box>
      <Heading lineHeight="24px" fontSize="16px">
        GLAM
      </Heading>
    </Flex>
  );
}

export default LogoSection;
