import { Icon } from "@chakra-ui/react";

const KickStarterIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M0 4.9C0 2.1938 2.1938 0 4.9 0H19.1C21.8062 0 24 2.1938 24 4.9V19.1C24 21.8062 21.8062 24 19.1 24H4.9C2.1938 24 0 21.8062 0 19.1V4.9Z"
      fill="#05CE78"
    />
    <path
      d="M20.0999 16.9699C20.0999 16.1341 19.8582 15.3281 19.3444 14.6117L17.41 11.955L19.3444 9.29839C19.8582 8.61183 20.0999 7.77604 20.0999 6.94023C20.0999 4.73131 18.2562 3.02985 16.0801 3.02985C14.8107 3.02985 13.5414 3.6567 12.7555 4.73131L11.7883 6.04472C11.4257 4.28356 9.88424 3 7.91968 3C5.65289 3 3.8999 4.79101 3.8999 6.99992V16.9997C3.8999 19.2087 5.68311 20.9996 7.91968 20.9996C9.85402 20.9996 11.3652 19.8057 11.7581 18.0744L12.6044 19.2683C13.3902 20.4027 14.6899 20.9996 15.9592 20.9996C18.2562 21.0295 20.0999 19.1788 20.0999 16.9699Z"
      fill="white"
    />
  </Icon>
);

export default KickStarterIcon;
