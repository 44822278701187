import {
    Box,
    Button, filter,
    Flex,
    Input,
    InputGroup,
    InputLeftElement, Tooltip
} from "@chakra-ui/react";
import {
    Select,
} from "chakra-react-select";
import SearchIcon from "../../components/icons/SearchIcon";
import {useEffect, useState} from "react";
import { SELECT_FILTERS } from "utils/constants";
import {useGetCategories, useGetCharacteristics} from "../../hooks/audience";
import FilterResetIcon from "../../components/icons/FilterResetIcon";
import {useAmplify} from "../../contexts/AmplifyContext";
import _ from 'lodash';


function ListsFilters({props}) {
    const { data: categories } = useGetCategories();
    const { data: characteristics } = useGetCharacteristics();
    const [selectedTopLevelCategories, setSelectedTopLevelCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchTextTimeout, setSearchTextTimeout] = useState(0);
    const [selectedCampaignTypes, setSelectedCampaignTypes] = useState([]);
    const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
    const [selectedPrices, setSelectedPrices] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [filterState, setFilterState] = useState({listType: 'custom'});
    const [mergeButtonDisabled, setMergeButtonDisabled] = useState(true);
    const { API, graphqlOperation } = useAmplify();

    const setSubcategoriesList = (topLevelCategories) => {
        if (topLevelCategories.length) {
            const topLevelCategoryValues = topLevelCategories.map(selcat => selcat.value);
            const subCats = [].concat(...categories.filter((cat) => {
                return topLevelCategoryValues.includes(cat.id);
            }).map(topcat => topcat.subcategories.items));

            setSubCategories(subCats)

            return subCats;
        }
        return [];
    };

    useEffect(() => {
        setSearchText('');
    }, [props.listType]);

    useEffect(() => {
        let filters = {...props.filterState}

        if (props.listType === 'merged') {
            filters['listType'] = 'merged';
        } else {
            if (selectedTopLevelCategories.length) {
                const subCats = setSubcategoriesList(selectedTopLevelCategories).map((scat) => {
                    return {value: scat.id, label: scat.name}
                });
                filters['subCats'] = subCats;
                if (selectedSubCategories.length) {
                    filters['subCats'] = selectedSubCategories;
                }
            } else {
                delete filters['subCats'];
            }

            if (selectedCampaignTypes.length) {
                filters['campaignTypes'] = selectedCampaignTypes;
            } else {
                delete filters['campaignTypes'];
            }

            if (selectedCharacteristics.length) {
                filters['characteristics'] = selectedCharacteristics;
            } else {
                delete filters['characteristics'];
            }

            if (selectedPrices.length) {
                filters['prices'] = selectedPrices;
            } else {
                delete filters['prices'];
            }

            if (selectedYears.length) {
                filters['years'] = selectedYears;
            } else {
                delete filters['years'];
            }
        }

        if (searchText.length > 2) {
            filters['searchText'] = searchText;
        }
        if (!searchText.length) {
            delete filters['searchText'];
        }

        if (!_.isEqual(props.filterState, filters)) {
            // setFilterState(filters);
            props.onFilterApply(filters);
        }
    }, [
        selectedTopLevelCategories,
        selectedSubCategories,
        selectedCampaignTypes,
        selectedCharacteristics,
        selectedPrices,
        selectedYears
    ]);

    function handleSearchInputChange(e) {
        const inputText = e.target.value;
        setSearchText(inputText);
    }

    useEffect(() => {
        clearTimeout(searchTextTimeout);
        const timeout = setTimeout(() => {
            let filters = {...props.filterState};
            if (searchText.length > 2) {
                filters['searchText'] = searchText;
                // setFilterState(filters);
                props.onFilterApply(filters);
            }
            if (!searchText.length && filters.hasOwnProperty('searchText')) {
                delete filters['searchText'];
                // setFilterState(filters);
                props.onFilterApply(filters);
            }
        }, 800);
        setSearchTextTimeout(timeout);
    }, [searchText]);

    // useEffect(() => {
    //     props.onFilterApply(filterState);
    // }, [filterState]);

    const resetFilters = () => {
        setSelectedTopLevelCategories([]);
        setSelectedSubCategories([]);
        setSelectedCharacteristics([]);
        setSelectedCampaignTypes([]);
        setSelectedPrices([]);
        setSelectedYears([]);
        setSearchText('');
        // setFilterState({});
        props.onFilterApply({});
    }


    return (
        <>
            { props.listType === 'custom' ? (
                <>
                    <Flex justifyContent="space-between">
                        <Box>
                            <Flex>
                                {/*<Box>*/}
                                {/*    <Select*/}
                                {/*        size="sm"*/}
                                {/*        options={[*/}
                                {/*            {*/}
                                {/*                label: "All",*/}
                                {/*                value: 0*/}
                                {/*            },*/}
                                {/*            {*/}
                                {/*                label: "With file",*/}
                                {/*                value: 1*/}
                                {/*            },*/}
                                {/*            {*/}
                                {/*                label: "Without file",*/}
                                {/*                value: 2*/}
                                {/*            }*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                                <Box>
                                    <InputGroup ml={3}>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<SearchIcon color="gray.400" />}
                                        />
                                        <Input
                                            placeholder="Search"
                                            onChange={handleSearchInputChange}
                                            value={searchText}
                                        >
                                        </Input>
                                    </InputGroup>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex direction="column" w="full" p={4} pl={0}>
                    <Flex alignItems="center" gap="1">
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Top level categories"
                            options={categories?.map((cat) => (
                                {
                                    value: cat.id,
                                    label: cat.name
                                }
                            ))}
                            onChange={setSelectedTopLevelCategories}
                            value={selectedTopLevelCategories}
                        />
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Subcategories"
                            options={subCategories.map((scat) => (
                                {
                                    value: scat.id,
                                    label: scat.name
                                }
                            ))}
                            onChange={setSelectedSubCategories}
                            value={selectedSubCategories}
                        />
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Characteristics"
                            options={characteristics?.map((char) => (
                                {
                                    value: char.id,
                                    label: char.name
                                }
                            ))}
                            onChange={setSelectedCharacteristics}
                            value={selectedCharacteristics}
                        />
                    </Flex>
                    <Flex mt={3} alignItems="center" gap="1">
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Campaign type"
                            options={SELECT_FILTERS.campaignType.map((v) => (
                                {
                                    value: v.value,
                                    label: v.name
                                }
                            ))}
                            onChange={setSelectedCampaignTypes}
                            value={selectedCampaignTypes}
                        />
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Price"
                            options={SELECT_FILTERS.price.map((v) => (
                                {
                                    value: v.value,
                                    label: v.name
                                }
                            ))}
                            onChange={setSelectedPrices}
                            value={selectedPrices}
                        />
                        <Select
                            size="sm"
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder="Year"
                            options={SELECT_FILTERS.year.map((v) => (
                                {
                                    value: v.value,
                                    label: v.name
                                }
                            ))}
                            onChange={setSelectedYears}
                            value={selectedYears}
                        />
                        <Tooltip label="reset filter" hasArrow>
                            <Button
                                onClick={resetFilters}
                                bg="white"
                                border="1px"
                                // borderRadius="100%"
                                width="24px"
                                height="30px"
                                p={0}
                                ml={5}
                                borderColor="gray.200"
                            >
                                <FilterResetIcon />
                            </Button>
                        </Tooltip>
                    </Flex>
                </Flex>
                </>
                ) : (
                    <InputGroup ml={3}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.400" />}
                        />
                        <Input
                            placeholder="Search"
                            onChange={handleSearchInputChange}
                            value={searchText}
                        >
                        </Input>
                    </InputGroup>
                )
            }
        </>
    )
}


export default ListsFilters;