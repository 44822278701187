import { Fragment } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import Loading from "components/Loading";
import ScrollbarFlex from "components/ScrollbarFlex";

//Scrollbar in the right side where represented lists that exists in add account.
function AudienceList({
  customAudiences,
  isFetchingNextPage,
  fetchNextPage,
  isFetching,
  hasNextPage,
}) {
  function handleAudienceScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 20) {
      !isFetchingNextPage && hasNextPage && fetchNextPage();
    }
  }

  const customAudiencesCount = customAudiences?.pages[0].data.data.filter((aud) => aud.subtype == 'CUSTOM').length;
  const lookalikeAudiencesCount = customAudiences?.pages[0].data.data.filter((aud) => aud.subtype == 'LOOKALIKE').length;

  return (
    <ScrollbarFlex
      direction="column"
      onScroll={handleAudienceScroll}
      overflowY="auto"
      spacing={4}
      align="stretch"
      pl={6}
      fontSize="14px"
      color="gray.900"
      lineHeight="20px"
    >
      {!isFetchingNextPage && isFetching ? (
        <Loading />
      ) : (
        customAudiences && (
          <>
            <Box>Audiences count</Box>
            <Flex
              align="center"
              mr="25px"
              mb="25px"
              borderBottom="1px"
              borderColor="#8ec7f7"
              pb="10px"
            >
              <Box>Custom: {customAudiencesCount}</Box>
              <Box ml="16px">Lookalike: {lookalikeAudiencesCount}</Box>
              <Box ml="16px" fontWeight="600">Total: {customAudiencesCount + lookalikeAudiencesCount}</Box>
            </Flex>
            {customAudiences?.pages.map((page, index) => (
              <Fragment key={index}>
                {page.data.data.map((aud) => (
                  <Box p={2} pl={0} pr={4} key={aud.id}>
                    {aud.name}
                  </Box>
                ))}
              </Fragment>
            ))}
          </>
        )
      )}
      <Flex p={2} align="center" justify="center">
        {isFetchingNextPage && <Spinner />}
      </Flex>
    </ScrollbarFlex>
  );
}

export default AudienceList;
