import { Box, Checkbox } from "@chakra-ui/react";
import { useMemo, useEffect, memo } from "react";
import audienceStore from "stores/audienceStore";

function CheckboxDisplay({
  tabIndex,
  audiences,
  searchKeyword,
  checkedItems,
  handleSingleCheck,
  selectedSubcategoryIds,
  filtersState,
}) {

  const tabNames = ["all", "custom", "lookalike"];

  const filteredAudiences = useMemo(() => {

    function comparePrices(price, range) {
      if (range.includes("<")) {
        return price < Number(range.split("<")[1]);
      } else if (range.includes("-")) {
        return (
          Number(range.split("-")[0]) <= price &&
          price <= Number(range.split("-")[1])
        );
      } else if (range.includes(">")) {
        return price > Number(range.split(">")[0]);
      }
    }

    let filteredAuds = audiences?.filter(
      (aud) =>
        (aud.name.includes(".csv") ? aud.name.split(".csv")[0] : aud.name)
          .toLowerCase()
          .includes(searchKeyword)
    );

    if (selectedSubcategoryIds?.length > 0) {
      filteredAuds = filteredAuds.filter((aud) =>
        selectedSubcategoryIds.includes(aud.subcategoryAudiencesId)
      );
    }

    if (filtersState?.campaignType) {
      filteredAuds = filteredAuds.filter((aud) => {
        if (!aud.campaignType && filtersState.campaignType === "Unknown") {
          return true;
        }
        return (
          filtersState?.campaignType.toLowerCase() ===
          aud.campaignType?.toLowerCase()
        );
      });
    }

    if (filtersState?.year) {
      filteredAuds = filteredAuds.filter(
        (aud) => aud.year === Number(filtersState.year)
      );
    }

    if (filtersState?.price) {
      filteredAuds = filteredAuds.filter((aud) =>
        comparePrices(aud.price, filtersState.price)
      );
    }

    if (filtersState?.characteristics.length > 0) {
      filteredAuds = filteredAuds.filter((aud) =>
        filtersState.characteristics.some((ch) =>
          aud.characteristics?.items.some((c) => c.characteristic.name === ch)
        )
      );
    }

    // if (filtersState?.deliveryStatus && filtersState?.deliveryStatus != "") {
    //   filteredAuds = filteredAuds.filter((aud) => aud.delivery_status?.code == Number(filtersState.deliveryStatus));
    // }

    const filteredByTabAuds = filterByTabName(tabNames[tabIndex], filteredAuds);

    return {filteredAuds, filteredByTabAuds};
  }, [
    audiences,
    searchKeyword,
    tabIndex,
    selectedSubcategoryIds,
    filtersState,
  ]);


  useEffect(() => {
    audienceStore.setState((prevState) => ({
      ...prevState,
      filteredAuds:  filteredAudiences.filteredByTabAuds,
      allAuds: filteredAudiences.filteredAuds
    }));
  }, [filteredAudiences.filteredByTabAuds]);

  function filterByTabName(tabName, audiences) {
    if (tabName === "all" || !tabName) {
      return audiences;
    } else if (tabName === "custom") {
      return audiences?.filter(
        (aud) =>
          aud.subtype == "CUSTOM"
      );
    } else if (tabName === "lookalike") {
      return audiences?.filter(
        (aud) =>
            aud.subtype == "LOOKALIKE"
      );
    }
  }

  return filteredAudiences.filteredByTabAuds.map((audience) => (
    <Box mb={4} key={audience.id} lineHeight="24px">
      <Checkbox
        isChecked={!!checkedItems.find((item) => item.id === audience.id)}
        onChange={(e) => handleSingleCheck(audience, e.target.checked)}
      >
        {audience.name}
      </Checkbox>
    </Box>
  ));
}

export default memo(CheckboxDisplay);
