const doesContainAudience = (item) => {
  return item.audience_id;
};

export const getSuccessRate = (response) => {
  const value = `${
    response.filter((item) => doesContainAudience(item)).length
  }/${response.length}`;

  return value;
};

export const getInvalidEntries = (response) => {
  return response
    .filter((item) => !doesContainAudience(item))
    .map((item) => item.name.replace('.csv', '') + ' - ' + (item.message ?? 'error'));
};