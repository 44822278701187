import { Icon } from "@chakra-ui/react";

const LogIcon = (props) => (
<Icon viewBox="0 0 32 32" {...props}>
    <path
    d="M31.4454 23.8858L20.722 3.66233C20.2845 2.83588 19.6114 2.14073 18.7782 1.65486C17.9451 1.16899 16.9849 0.911621 16.0053 0.911621C15.0257 0.911621 14.0655 1.16899 13.2324 1.65486C12.3992 2.14073 11.7261 2.83588 11.2886 3.66233L0.565189 23.8858C0.163946 24.6439 -0.0253834 25.4861 0.015135 26.3326C0.0556535 27.179 0.324678 28.0017 0.796724 28.7227C1.26877 29.4436 1.92821 30.039 2.71256 30.4525C3.49691 30.8659 4.38021 31.0836 5.27878 31.0851H26.7318C27.6304 31.0836 28.5137 30.8659 29.298 30.4525C30.0824 30.039 30.7418 29.4436 31.2139 28.7227C31.6859 28.0017 31.955 27.179 31.9955 26.3326C32.036 25.4861 31.8467 24.6439 31.4454 23.8858ZM17.5589 23.722C17.5589 24.1098 17.3956 24.4817 17.1048 24.7559C16.814 25.0301 16.4196 25.1841 16.0084 25.1841C15.5972 25.1841 15.2028 25.0301 14.912 24.7559C14.6212 24.4817 14.4579 24.1098 14.4579 23.722V23.1372C14.4579 22.7494 14.6212 22.3775 14.912 22.1034C15.2028 21.8292 15.5972 21.6751 16.0084 21.6751C16.4196 21.6751 16.814 21.8292 17.1048 22.1034C17.3956 22.3775 17.5589 22.7494 17.5589 23.1372V23.722ZM17.5589 18.751C17.5589 19.1387 17.3956 19.5106 17.1048 19.7848C16.814 20.059 16.4196 20.213 16.0084 20.213C15.5972 20.213 15.2028 20.059 14.912 19.7848C14.6212 19.5106 14.4579 19.1387 14.4579 18.751V11.733C14.4579 11.3452 14.6212 10.9733 14.912 10.6992C15.2028 10.425 15.5972 10.2709 16.0084 10.2709C16.4196 10.2709 16.814 10.425 17.1048 10.6992C17.3956 10.9733 17.5589 11.3452 17.5589 11.733V18.751Z"
    fill="#ED8936"
    />
</Icon>
);

export default LogIcon;