import { createContext, useContext, useState } from "react";
import { LINKS } from "utils/constants";

export const TCFTrackerContext = createContext({});

export const useTCFTracker = () => useContext(TCFTrackerContext);

export const TCFTrackerProvider = ({ children }) => {
  const [groupId, setGroupId] = useState("");
  const [linkName, setLinkName] = useState(LINKS.TCF_TRACKER);
  const [isGroupIdValid, setIsGroupIdValid] = useState(false);
  const [uriList, setUriList] = useState([]);

  return (
    <TCFTrackerContext.Provider
      value={{
        groupId,
        setGroupId,
        linkName,
        setLinkName,
        isGroupIdValid,
        setIsGroupIdValid,
        uriList,
        setUriList,
      }}
    >
      {children}
    </TCFTrackerContext.Provider>
  );
};
