import { Flex, Text } from "@chakra-ui/react";
import NoSelectedIcon from "components/icons/NoSelectedIcon";

function NoSelectedDisplay({ text = "You don't have a selected campaign" }) {
  return (
    <Flex
      direction="column"
      height="full"
      mr={6}
      alignItems="center"
      justifyContent="center"
    >
      <NoSelectedIcon width="91" height="87" />
      <Text color="gray.600" fontSize="14px">
        {text}
      </Text>
    </Flex>
  );
}

export default NoSelectedDisplay;
