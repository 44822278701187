import { Icon } from "@chakra-ui/react";

const FilterResetIcon = (props) => (
  <Icon viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M0.978516 3.16675V7.54175H5.35352"
      stroke="black"
      strokeWidth="1.45833"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.80872 11.1875C3.28151 12.5295 4.17761 13.6814 5.36201 14.4698C6.54642 15.2582 7.95495 15.6403 9.37539 15.5585C10.7958 15.4767 12.1512 14.9356 13.2374 14.0165C14.3235 13.0975 15.0815 11.8503 15.3972 10.463C15.7129 9.07568 15.5692 7.62334 14.9877 6.32479C14.4062 5.02625 13.4185 3.95186 12.1733 3.26351C10.9281 2.57515 9.49292 2.31012 8.08401 2.50835C6.67509 2.70657 5.36876 3.35732 4.36185 4.36253L0.978516 7.5417"
      stroke="black"
      strokeWidth="1.45833"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default FilterResetIcon;
