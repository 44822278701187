import { Icon } from "@chakra-ui/react";

function ArrowDownIcon(props) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fill="currentColor"
                d="M12 21l8-8H4z"
            />
        </Icon>
    );
}

export default ArrowDownIcon;