import { Flex, Select, Button, FormLabel, Box } from "@chakra-ui/react";
import FilterResetIcon from "components/icons/FilterResetIcon";
import { useState } from "react";
import DatePickers from "./DatePickers";

function UsageLogFilter({props}) {
    const [selectedDate, setSelectedDate] = useState(props.dateOptions[0].value);
    const [selectedUser, setSelectedUser] = useState('');

    function handleDateFilterChange(e) {
        const dateFilter = e.target.value;
        setSelectedDate(dateFilter);
        props.onDateChange(dateFilter);
    }

    function handleUserFilterChange(e) {
        const userFilter = e.target.value;
        setSelectedUser(userFilter);
        props.onUserChange(userFilter);
    }

    function handleFilterReset() {
        setSelectedDate(props.dateOptions[0].value);
        setSelectedUser('all');
        props.onReset();
    }

    const onDatesChange = (dates) => {
        props.onDateChange({custom: dates});
    }

    const dateProps = {selectedDate, onDatesChange, filterData: props.filterData};

    return (
        <Flex gap={2}>
            <Box>
                <FormLabel>Filter by date</FormLabel>
                <Select
                    value={selectedDate}
                    onChange={handleDateFilterChange}
                    mt={2}
                    width="180px"
                >
                    {props.dateOptions.map((opt) => (
                        <option style={{ fontSize: "14px" }} key={opt.value} value={opt.value}>
                            {opt.text}
                        </option>
                    ))}
                </Select>
            </Box>
            <DatePickers props={dateProps} />
            <Box>
                <FormLabel>Filter by user</FormLabel>
                <Select
                    value={selectedUser}
                    onChange={handleUserFilterChange}
                    mt={2}
                >
                    <option style={{ fontSize: "14px" }} key="all" value="all">All</option>
                    {props.userOptions && props.userOptions.map((opt) => (
                        <option style={{ fontSize: "14px" }} key={opt.value} value={opt.value}>
                            {opt.text}
                        </option>
                    ))}
                </Select>
            </Box>
            <Box>
                <FormLabel>Reset</FormLabel>
                <Button
                    onClick={handleFilterReset}
                    bg="white"
                    border="1px"
                    borderRadius="100%"
                    width="38px"
                    height="38px"
                    p={0}
                    borderColor="gray.200"
                >
                    <FilterResetIcon />
                </Button>
            </Box>
        </Flex>
    );
}

export default UsageLogFilter;