import RightSectionLayout from "components/RightSectionLayout";
import LookalikeContent from "./LookalikeContent";
import BusinessAccountSelect from "components/BusinessAccountSelect";

function LookalikeRightSection() {
  return (
    <RightSectionLayout hasBorder header={<BusinessAccountSelect />}>
      <LookalikeContent />
    </RightSectionLayout>
  );
}

export default LookalikeRightSection;
