function generateSubcategoryNumbers(audiences) {
  return audiences.reduce((prev, acc) => {
    const name = acc.subcategory?.name;

    if (!prev[name]) {
      prev[name] = 1;
    } else {
      prev[name] = prev[name] + 1;
    }

    return prev;
  }, {});
}

export default generateSubcategoryNumbers;
