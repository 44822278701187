import { chakra, Flex } from "@chakra-ui/react";

const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#CBD5E0",
    borderRadius: "24px",
  },
};

const ScrollbarFlex = chakra(Flex, {
  baseStyle: {
    ...scrollbarStyle,
  },
});

export default ScrollbarFlex;
