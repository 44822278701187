import { createContext, useContext, useState, useEffect } from "react";

export const AccountContext = createContext({});

export const useAccount = () => useContext(AccountContext);

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [business, setBusiness] = useState(null);

  useEffect(() => {
    if (business === null) {
      setAccount(null);
      if (localStorage.getItem("selectedBusiness")) {
        const selectedBusiness = localStorage.getItem("selectedBusiness");
        setBusiness(JSON.parse(selectedBusiness));
      }
    } else {
      localStorage.setItem(
        "selectedBusiness",
        business
          ? JSON.stringify({
              id: business.id,
              name: business.name,
            })
          : null
      );
    }

    if (account === null) {
      if (localStorage.getItem("selectedAccount")) {
        const selectedAccount = localStorage.getItem("selectedAccount");
        setAccount(JSON.parse(selectedAccount));
      }
    } else {
      localStorage.setItem(
        "selectedAccount",
        business && account ? JSON.stringify(account) : null
      );
    }
  }, [business, account]);

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
        business,
        setBusiness,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
