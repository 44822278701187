import { Box, Text } from "@chakra-ui/react";
import { useAccount } from "contexts/AccountContext";

function BusinessAndAccountInfo() {
  const { account, business } = useAccount();

  return (
    <Box>
      <Box mb={3}>
        <Text fontWeight="600">Business Manager:</Text>
        <Text>{business?.name}</Text>
      </Box>
      <Box>
        <Text fontWeight="600">Ad Account:</Text>
        <Text>{account?.name}</Text>
      </Box>
    </Box>
  );
}

export default BusinessAndAccountInfo;
