import AudienceManagerLayout from "pages/AudienceManager/AudienceManagerLayout";
import BusinessAndAccountInfo from "pages/AudienceManager/BusinessAndAccountInfo";
import RightSectionLayout from "components/RightSectionLayout";
import RightSectionBody from "../RightSectionBody";
import SummaryPage from "./SummaryPage";

function SummarySection() {
  return (
    <AudienceManagerLayout
      rightSection={
        <RightSectionLayout hasBorder header={<BusinessAndAccountInfo />}>
          <RightSectionBody />
        </RightSectionLayout>
      }
    >
      <SummaryPage />
    </AudienceManagerLayout>
  );
}

export default SummarySection;
