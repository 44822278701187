import axios from "axios";
import {AMPLIFY_API, AMPLIFY_USAGE_LOG_CREATION_API} from "utils/constants";
import { listAudienceLogs } from "../graphql/custom-queries";

import { gql } from "graphql-tag";
const { print } = require("graphql");



export function UsageLogService({ API, graphqlOperation }) {
    const createAudienceLogMutation = gql`
          mutation MyMutation($input: CreateAudienceLogInput!) {
            createAudienceLog(input: $input) {
              username
              audienceName
              accountName
              businessName
              numberOfUsers
              type
            }
          }
        `;

    const getUsageLog = async () => {
        const response = await API.graphql(graphqlOperation(listAudienceLogs));

        return response.data?.listAudienceLogs?.items;
    }

    const createUsageLog = async (input) => {
        const response = await API.post(AMPLIFY_API, "/log-usage", input);
        return response;
    }

    const addUsageLogToDB = async (input) => {
        try {
            const response = await axios.post(
                AMPLIFY_USAGE_LOG_CREATION_API,
                input.body
            );
            return { body: response.data };
        } catch (e) {
            return { body: e.response.data }
        }
    };

    const logToDB = async (input) => {
        const response = await axios({
            url: process.env.REACT_APP_AWS_GRAPHQL,
            method: "post",
            headers: {
                "x-api-key": process.env.REACT_APP_X_API_KEY,
            },
            data: {
                query: print(createAudienceLogMutation),
                variables: {
                    input,
                },
            },
        });
        return response;
    }

    const getUsers = async () => {
        const response = await API.get(AMPLIFY_API, "/cognito-users");
        return response.body;
    }

    return {
        getUsageLog,
        createUsageLog,
        addUsageLogToDB,
        logToDB,
        getUsers
    };
}