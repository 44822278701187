import { Flex } from "@chakra-ui/react";

function Layout({ header, footer, children }) {
  return (
    <Flex direction="column" h="full" pr={5}>
      <Flex height="14%" minHeight="110px">
        {header}
      </Flex>
      <Flex
        direction="column"
        h={footer ? "72%" : "79%"}
        align="stretch"
        border="1px"
        borderColor="gray.200"
        borderRadius={2}
      >
        {children}
      </Flex>
      {footer && (
        <Flex height="11%" justify="center">
          {footer}
        </Flex>
      )}
    </Flex>
  );
}

export default Layout;
