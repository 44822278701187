import { Icon } from "@chakra-ui/react";

const SignoutIcon = (props) => (
  <Icon viewBox="0 0 18 14" fill="none" {...props}>
    <path
      d="M5.25 7C5.25 6.83424 5.31585 6.67527 5.43306 6.55806C5.55027 6.44085 5.70924 6.375 5.875 6.375H11.5V2.3125C11.5 1.0625 10.1801 0.125 9 0.125H3.0625C2.48253 0.12562 1.92649 0.356288 1.51639 0.766389C1.10629 1.17649 0.87562 1.73253 0.875 2.3125V11.6875C0.87562 12.2675 1.10629 12.8235 1.51639 13.2336C1.92649 13.6437 2.48253 13.8744 3.0625 13.875H9.3125C9.89247 13.8744 10.4485 13.6437 10.8586 13.2336C11.2687 12.8235 11.4994 12.2675 11.5 11.6875V7.625H5.875C5.70924 7.625 5.55027 7.55915 5.43306 7.44194C5.31585 7.32473 5.25 7.16576 5.25 7Z"
      fill="#718096"
    />
    <path
      d="M16.9418 6.5582L13.8168 3.4332C13.6986 3.32094 13.5413 3.25928 13.3783 3.26137C13.2153 3.26345 13.0596 3.32912 12.9444 3.44437C12.8291 3.55962 12.7635 3.71534 12.7614 3.87831C12.7593 4.04129 12.8209 4.19863 12.9332 4.3168L14.991 6.375H11.5V7.625H14.991L12.9332 9.6832C12.8727 9.74066 12.8244 9.80965 12.791 9.88609C12.7576 9.96254 12.7398 10.0449 12.7387 10.1283C12.7377 10.2117 12.7533 10.2945 12.7847 10.3718C12.8162 10.4491 12.8628 10.5193 12.9217 10.5783C12.9807 10.6372 13.0509 10.6838 13.1282 10.7153C13.2055 10.7467 13.2883 10.7623 13.3717 10.7613C13.4551 10.7602 13.5375 10.7424 13.6139 10.709C13.6904 10.6756 13.7593 10.6273 13.8168 10.5668L16.9418 7.4418C17.0589 7.3246 17.1247 7.16569 17.1247 7C17.1247 6.83431 17.0589 6.6754 16.9418 6.5582Z"
      fill="#718096"
    />
  </Icon>
);

export default SignoutIcon;
