import ScrollbarFlex from "components/ScrollbarFlex";
import { useState, useRef } from "react";
import helperStore from "stores/helperStore";
import subcategoryStore from "stores/subcategoryStore";
import CustomCheckbox from "./CustomCheckbox";

const {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Button,
  Flex,
  forwardRef,
  chakra,
} = require("@chakra-ui/react");

const IconRef = forwardRef((props, ref) => (
  <Box ref={ref} {...props}>
    <AccordionIcon />
  </Box>
));

function CategoryAccordion({ categories = [] }) {
  const [isItemExpanded, setIsItemExpanded] = useState(false);
  const closedIconsRef = useRef([]);
  const expandedIconsRef = useRef([]);

  const subcategoryByAudienceNumbers = helperStore.useStore(
    (state) => state.subcategoryByAudienceNumbers
  );
  const checkCategoryState = subcategoryStore.useStore();
  const setCheckCategoryState = subcategoryStore.setState;

  const isAllSubcategoriesChecked = (catName) => {
    const category = categories.find((item) => item.name === catName);

    return (
      checkCategoryState[catName]?.length > 0 &&
      category?.subcategories?.items.length ===
        checkCategoryState[catName]?.length
    );
  };

  const isSubcategoriesIndeterminate = (catName) => {
    const category = categories.find((item) => item.name === catName);

    return (
      checkCategoryState[catName]?.length > 0 &&
      category?.subcategories?.items.length >
        checkCategoryState[catName]?.length
    );
  };

  function handleSingleCheck(catName, subCat, isChecked) {
    setCheckCategoryState((prevState) => {
      if (isChecked) {
        if (!prevState[catName]) {
          prevState[catName] = [];
        }
        prevState[catName].push(subCat);
      } else {
        prevState[catName] = prevState[catName].filter(
          (sub) => sub.name !== subCat.name
        );
      }
      return { ...prevState };
    });
  }

  function selectAllSubcategories(catName, isChecked) {
    setCheckCategoryState((prevState) => {
      if (isChecked) {
        if (!prevState[catName]) {
          prevState[catName] = [];
        }
        const category = categories.find((item) => item.name === catName);
        prevState[catName] = category.subcategories.items;
      } else {
        prevState[catName] = [];
      }

      return { ...prevState };
    });
  }

  function expandAll() {
    closedIconsRef.current.forEach((el) =>
      setTimeout(() => el && el.click(), 0)
    );
  }

  function closeAll() {
    expandedIconsRef.current.forEach((el) => setTimeout(() => el.click(), 0));
  }

  const isAllCategoriesChecked = () => {
    return categories
      .map((cat) => cat.name)
      .every((catName) => isAllSubcategoriesChecked(catName));
  };

  const isCategoriesIndeterminate = () => {
    return (
      Object.keys(checkCategoryState).some(
        (catName) => checkCategoryState[catName].length > 0
      ) && !isAllCategoriesChecked()
    );
  };

  function selectAllCategories(isChecked) {
    categories
      .map((cat) => cat.name)
      .forEach((catName) => selectAllSubcategories(catName, isChecked));
  }

  function computeCategoryNumber(subs) {
    if (Object.keys(subcategoryByAudienceNumbers).length > 0) {
      return subs.reduce((prev, acc) => {
        return (
          prev +
          (subcategoryByAudienceNumbers[acc.name]
            ? subcategoryByAudienceNumbers[acc.name]
            : 0)
        );
      }, 0);
    } else {
      return 0;
    }
  }

  const nonExpandStyle = {
    bg: "gray.600",
    color: "white",
    _hover: {
      bg: "gray.700",
    },
  };

  return (
    <>
      <Flex pl={4} paddingY={4}>
        <CustomCheckbox
          isChecked={categories.length > 0 && isAllCategoriesChecked()}
          isIndeterminate={isCategoriesIndeterminate()}
          onChange={(e) => selectAllCategories(e.target.checked)}
        >
          Select All
        </CustomCheckbox>
        <Button
          h="26px"
          px={2}
          fontSize="12px"
          ml="auto"
          mr={4}
          onClick={() => {
            if (isItemExpanded) {
              closeAll();
            } else {
              expandAll();
            }
            setIsItemExpanded(!isItemExpanded);
          }}
          border="1px"
          borderColor="gray.300"
          color="gray.700"
          {...(!isItemExpanded && nonExpandStyle)}
        >
          {!isItemExpanded ? "Expand" : "Close all"}
        </Button>
      </Flex>
      <ScrollbarFlex overflowY="scroll">
        <Accordion width="full" allowMultiple>
          {categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((cat, i) => (
              <AccordionItem key={cat.id}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton _expanded={{ bg: "gray.200" }}>
                        <Box flex="1" textAlign="left" color="gray.900">
                          <CustomCheckbox
                            isChecked={isAllSubcategoriesChecked(cat.name)}
                            isIndeterminate={isSubcategoriesIndeterminate(
                              cat.name
                            )}
                            onChange={(e) =>
                              selectAllSubcategories(cat.name, e.target.checked)
                            }
                          >
                            {cat.name} (
                            {computeCategoryNumber(cat.subcategories.items)})
                          </CustomCheckbox>
                        </Box>
                        <IconRef
                          ref={(el) => {
                            if (!isExpanded) {
                              closedIconsRef.current[i] = el;
                            } else {
                              expandedIconsRef.current[i] = el;
                            }
                          }}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg="gray.50">
                      {cat.subcategories.items
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((subCat) => (
                          <Box pl={4} key={subCat.id} color="gray.900">
                            <CustomCheckbox
                              isChecked={
                                !!checkCategoryState[cat.name]?.find(
                                  (item) => item.name === subCat.name
                                )
                              }
                              onChange={(e) =>
                                handleSingleCheck(
                                  cat.name,
                                  subCat,
                                  e.target.checked
                                )
                              }
                            >
                              {subCat.name}{" "}
                              <chakra.span color="gray.600">
                                (
                                {subcategoryByAudienceNumbers[subCat.name]
                                  ? subcategoryByAudienceNumbers[subCat.name]
                                  : 0}
                                )
                              </chakra.span>
                            </CustomCheckbox>
                          </Box>
                        ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
        </Accordion>
      </ScrollbarFlex>
    </>
  );
}

export default CategoryAccordion;
