// global styles go here
const styles = {
  global: (props) => ({
    body: {
      fontFamily: "Inter",
    },
  }),
};

export default styles;
