/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "audapi",
            "endpoint": "https://h9etdb3kph.execute-api.us-east-2.amazonaws.com/asdev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://glqfrorzlbbsbl4zgzhv5fr3ti.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gfklssmkz5fi7gahwacj3siy4a",
    "aws_cognito_identity_pool_id": "us-east-2:ea2a0d3b-a256-4992-af29-a9325b49964b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_4fujLOFFa",
    "aws_user_pools_web_client_id": "41cejgnrmgengathtjenc20s9u",
    "oauth": {
        "domain": "audiencemanagera4376a2b-a4376a2b-asdev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://am.production.am/",
        "redirectSignOut": "http://localhost:3000/,https://am.production.am/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "audiencemanager9d18111a33ec45ef8d3841dcfa14930c184105-asdev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;