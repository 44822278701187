import axios from "axios";
import { GOD_LEVEL_API } from "utils/constants";

export function GodLevelService(token) {
  const findAudiences = async ({ title, description, threshold }) => {
    const response = await axios.put(
      `${GOD_LEVEL_API}/audiences`,
      {
        title,
        description,
        threshold,
        tags: "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  const downloadAudience = async ({ path }) => {
    const response = await axios.post(
      `${GOD_LEVEL_API}/audiences/generated/file`,
      {
        path,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  const uploadAudience = async ({ path }) => {
    const response = await axios.put(
      `${GOD_LEVEL_API}/audiences/generated/file`,
      {
        path,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  const getStatistics = async () => {
    const response = await axios({
      url: `${GOD_LEVEL_API}/statistics`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  return {
    findAudiences,
    downloadAudience,
    uploadAudience,
    getStatistics,
  };
}
