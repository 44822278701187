import {useGetAudiences} from "../../hooks/audience";
import {
    Box,
    Checkbox,
    Flex,
    IconButton,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr, useDisclosure, useToast
} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {CopyIcon, DeleteIcon, EditIcon, ExternalLinkIcon} from "@chakra-ui/icons"
import {useNavigate} from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog"
import {useAmplify} from "../../contexts/AmplifyContext";
import {AudienceService} from "../../services/audience";
import Toast from "../../components/Toast";

function ListsTable({props}) {
    const { API, graphqlOperation } = useAmplify();
    const [checkedSelectAlls, setCheckedSelectAlls] = useState([]);
    const [indeterminateSelectAlls, setIndeterminateSelectAlls] = useState([]);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const [audToDelete, setAudToDelete] = useState(null);
    const toast = useToast();
    const toastSuccessRef = useRef();

    const openCampaignLink = (campaignLink) => {
        window.open(campaignLink, "_blank", "noreferrer");
    }

    const copyLinkToClipboard = (link) => {
        navigator.clipboard.writeText(link);
    }

    const joinCharacteristicNames = (characteristics) => {
        const names = characteristics?.map(item => item.characteristic.name);
        return names ? names.join(', ') : '';
    }

    const isChecked = (aud) => {
        return props.selectedLists.findIndex((saud) => saud.id === aud.id) !== -1;
    }
    const onCheckboxCheck = (aud, checked) => {
        if (checked) {
            props.onSelectList([...props.selectedLists, {id: aud.id, name: aud.name, backers: aud.backers, file: aud.file}]);
        } else {
            props.onSelectList(props.selectedLists.filter(saud => saud.id !== aud.id));
            if (checkedSelectAlls.includes(props.page) && !indeterminateSelectAlls.includes(props.page)) {
                setIndeterminateSelectAlls((prevState) => [...prevState, props.page]);
            }
            setCheckedSelectAlls(checkedSelectAlls.filter((page) => page !== props.page));
        }
    }

    const formatDate = (datetime) => {
        return datetime.slice(0, -5).replace('T', ' ');
    }

    const selectAll = (checked) => {

        let toSelect = [];
        if (checked) {
            toSelect = [...props.selectedLists];
            props.audiences.forEach((aud) => {
                if (!toSelect.length || !toSelect.filter((saud) => saud.id === aud.id).length) {
                    toSelect.push({id: aud.id, name: aud.name, backers: aud.backers, file: aud.file});
                }
            });
            setCheckedSelectAlls((prevState) => [...prevState, props.page]);
        } else {
            props.selectedLists.forEach((list) => {
                if (!props.audiences.filter((paud) => paud.id === list.id).length) {
                    toSelect.push(list);
                }
            });
            setCheckedSelectAlls(checkedSelectAlls.filter((page) => page !== props.page));
        }
        props.onSelectList(toSelect);
        setIndeterminateSelectAlls(indeterminateSelectAlls.filter((page) => page !== props.page));
    }

    const editList = (aud) => {
        navigate('create', {state: {aud}});
    }

    const handleListDelete = (aud) => {
        setAudToDelete(aud);
        onOpen();
    }

    const deleteList = () => {
        AudienceService({API, graphqlOperation}).deleteList({audId: audToDelete.id, fileName: audToDelete.file.key}).then((res) => {
            if (res.statusCode === 200) {
                onClose();
                toastSuccessRef.current = toast({
                    duration: null,
                    position: 'top',
                    render: () => (
                        <Toast
                            title="Succeed"
                            text={`${audToDelete.name} deleted`}
                            onClick={() => closeToast(toastSuccessRef)}
                        />
                    )
                });
                props.onListReload();
            }
        }).catch((err) => {
            console.log({err})
        })
    }

    function closeToast(ref) {
        if (ref.current) {
            toast.close(ref.current);
        }
    }

    return (
        <Box overflowX='scroll'>
            <ConfirmDialog
                isOpen={isOpen}
                onClose={onClose}
                cancelref={cancelRef}
                onConfirm={deleteList}
            ></ConfirmDialog>
            <Table variant="striped" size="sm">
                <Thead>
                    <Tr>
                        {props.listType === 'custom' &&
                            <Th>
                                <Checkbox
                                    onChange={(e) => selectAll(e.target.checked)}
                                    isChecked={checkedSelectAlls.includes(props.page)}
                                    isIndeterminate={indeterminateSelectAlls.includes(props.page)}
                                ></Checkbox>
                            </Th>
                        }
                        <Th>Name</Th>
                        <Th>No</Th>
                        <Th>Backers</Th>
                        <Th>Description</Th>
                        {props.listType === 'custom' &&
                            <>
                                <Th textAlign="center">Top level category</Th>
                                <Th>Subcategory</Th>
                                <Th>Characteristics</Th>
                                <Th>Year</Th>
                                <Th>Price</Th>
                                <Th textAlign="center">Campaign type</Th>
                                <Th textAlign="center">Campaign link</Th>
                                <Th>Raised</Th>
                            </>
                        }
                        <Th>Created</Th>
                        <Th>Updated</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.isLoading ? (
                        <Tr height="30px">
                            <Td colSpan="15" align="center">
                                <Spinner />
                            </Td>
                        </Tr>
                    ) : props.audiences?.map((aud) => {
                        const characteristics = joinCharacteristicNames(aud.characteristics?.items);
                        return (
                            <Tr key={aud.id}>
                                {props.listType === 'custom' &&
                                    <Td>
                                        <Checkbox
                                            isChecked={isChecked(aud)}
                                            onChange={(e) => onCheckboxCheck(aud, e.target.checked)}
                                        ></Checkbox>
                                    </Td>
                                }
                                <Td>{aud.name}</Td>
                                <Td isNumeric>{aud.number}</Td>
                                <Td isNumeric>{aud.backers}</Td>
                                <Td>
                                    <Tooltip label={aud.description} hasArrow>
                                        <Text>{aud.description?.slice(0,20)}...</Text>
                                    </Tooltip>
                                </Td>
                                { props.listType !== 'merged' &&
                                    <>
                                        <Td>{aud.subcategory ? aud.subcategory.categorySubcategoriesId.replace(/_/g, ' ') : 'UNCATEGORIZED'}</Td>
                                        <Td>{aud.subcategory ? aud.subcategory.name : 'UNCATEGORIZED'}</Td>
                                        <Td textAlign="center">
                                            <Tooltip label={characteristics} hasArrow>
                                                <Text>{characteristics.slice(0,20)}</Text>
                                            </Tooltip>
                                        </Td>
                                        <Td isNumeric>{aud.year}</Td>
                                        <Td isNumeric>{Intl.NumberFormat('en-US', {style:'currency','currency':'USD'}).format(aud.price)}</Td>
                                        <Td>{aud.campaignType}</Td>
                                        <Td display="table-cell">
                                            <Flex>
                                                <Tooltip label="open campaign link" hasArrow>
                                                    <IconButton
                                                        aria-label=""
                                                        background="transparent"
                                                        icon={<ExternalLinkIcon />}
                                                        onClick={() => openCampaignLink(aud.campaignLink)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="copy campaign link" hasArrow>
                                                    <IconButton
                                                        aria-label=""
                                                        background="transparent"
                                                        icon={<CopyIcon />}
                                                        onClick={() => copyLinkToClipboard(aud.campaignLink)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td>{Intl.NumberFormat('en-US', {style:'currency','currency':'USD','maximumFractionDigits':0}).format(aud.raised)}</Td>
                                    </>
                                }
                                <Td>{formatDate(aud.createdAt)}</Td>
                                <Td>{formatDate(aud.updatedAt)}</Td>
                                <Td>
                                    <Flex>
                                        <IconButton aria-label=""
                                                    background="transparent"
                                                    icon={<EditIcon />}
                                                    onClick={() => editList(aud)}
                                        />
                                        <IconButton aria-label=""
                                                    background="transparent"
                                                    icon={<DeleteIcon />}
                                                    onClick={() => handleListDelete(aud)}
                                        />
                                    </Flex>
                                </Td>
                            </Tr>
                        )}
                    )}
                </Tbody>
            </Table>
        </Box>
    )
}

export default ListsTable;