import { useGetUsageLog } from "../../hooks/usageLog";
import { useGetUsers } from "../../hooks/usageLog";
import { Container } from "@chakra-ui/react";
import UsageLogTable from "./UsageLogTable";
import UsageLogFilter from "./UsageLogFilter";
import {useEffect, useState} from "react";

function UsageLog() {
    const { data: usageLogs, refetch } = useGetUsageLog();
    const { data: allUsers } = useGetUsers();
    const [filteredUsageLogs, setFilteredUsageLogs] = useState(usageLogs);
    const [groupedLogs, setGroupedLogs] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [totals, setTotals] = useState({SendFB: 0, CreateLL: 0, CreateML: 0});

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const [filterData, setFilterData] = useState({startDate: new Date(currentYear, currentMonth, 1), endDate: currentDate, user: 'all'});
    const users = allUsers?.reduce((acc,curr) => (acc[curr.Username] = curr.Attributes[4].Value, acc), {});
    const groupDelimeter = '__';

    function groupUsageLogs(rawUsageLogs) {
        const groupedDataWithCounts = rawUsageLogs?.reduce((result, item) => {
            const key = `${item.username}${groupDelimeter}${item.accountName}${groupDelimeter}${item.type}`;
            if (!result[key]) {
                result[key] = {
                    items: [],
                    count: 0,
                    sum: 0
                };
            }
            result[key].items.push(item);
            result[key].count++;
            result[key].sum +=item.numberOfUsers;
            setTotals({SendFB: totals.SendFB + key.includes('SendFB') ? item.numberOfUsers : 0, CreateLL: totals.CreateLL + key.includes('CreateLL') ? item.numberOfUsers : 0, CreateML: totals.CreateML + key.includes('CreateML') ? item.numberOfUsers : 0})
            return result;
        }, {});

        const groupedArrayWithCounts = groupedDataWithCounts && Object.entries(groupedDataWithCounts).map(
            ([key, value]) => ({
                key,
                items: value.items,
                count: value.count,
                sum: value.sum
            })
        );

        return groupedArrayWithCounts;
    }

    function applyFilters() {
        const filterUsageLogs = usageLogs?.filter((log) => {
            const createdAt = new Date(log.createdAt);
            if (filterData.user == 'all') {
                return createdAt.getTime() >= filterData.startDate.getTime() && createdAt.getTime() <= filterData.endDate.getTime();
            }
            return createdAt.getTime() >= filterData.startDate.getTime() && createdAt.getTime() <= filterData.endDate.getTime() && log.username == filterData.user;
        });

        setFilteredUsageLogs(filterUsageLogs);
    }

    useEffect(() => {
        applyFilters();
    }, [filterData]);

    useEffect(() => {
        setFilteredUsageLogs((filteredUsageLogs) => usageLogs);
    }, [usageLogs]);

    useEffect(() => {
        if (filteredUsageLogs) {
            setGroupedLogs((groupedLogs) => Object.values(groupUsageLogs(filteredUsageLogs)) );
        }
    }, [filteredUsageLogs]);

    useEffect(() => {
        if (groupedLogs.length) {
            groupedLogs.forEach((groupedLog) => {
                const totalKey = groupedLog.key.split('__')[2];
                setTotals((prevTotals) => {
                    return { ...prevTotals, [totalKey]: prevTotals[totalKey] + (totalKey === 'SendFB' ? groupedLog.count : groupedLog.sum) }
                });
            });
        } else {
            setTotals((prevTotals) => {
                return {SendFB: 0, CreateLL: 0, CreateML: 0}
            });
        }
    }, [groupedLogs]);

    const dateOptions = [
        {text: 'This month', value: 'this_month'},
        {text: 'Previous month', value: 'previous_month'},
        {text: 'Last 30 days', value: 'last_30_days'},
        {text: 'Last 3 months', value: 'last_3_months'},
        {text: 'Last 6 months', value: 'last_6_months'},
        {text: 'Last 1 year', value: 'last_1_year'},
        {text: 'Custom', value: 'custom'}
    ];

    useEffect(() => {
        // const usernames = groupUsageLogs((usageLogs))?.map((gul) => {
        //     const keyArray = gul.key.split(groupDelimeter);
        //     return keyArray[0];
        // }).filter((item, pos, self) => { return self.indexOf(item) == pos });

        // setUserOptions((userOptions) =>  usernames?.map((username) => {
        //     return { text: users[username], value: username }
        // }) );

        setUserOptions((userOptions) => allUsers?.map((user) => {
            return { text: user.Attributes[4].Value, value: user.Username }
        }) );
    }, [allUsers]);


    const onDateChange = (date) => {
        if (date.custom) {
            setFilterData({startDate: date.custom.startDate, endDate: date.custom.endDate, user: filterData.user});
        }
        switch (date) {
            case 'this_month':
                setFilterData({startDate: new Date(currentYear, currentMonth, 1), endDate: currentDate, user: filterData.user});
                break;
            case 'previous_month':
                setFilterData({startDate: new Date(currentYear, currentMonth - 1, 1), endDate: new Date(currentYear, currentMonth, 0), user: filterData.user});
                break;
            case 'last_30_days':
                setFilterData({startDate: new Date(currentDate - 30 * 60 * 60 * 24 * 1000), endDate: currentDate, user: filterData.user});
                break;
            case 'last_3_months':
                setFilterData({startDate: new Date(currentYear, currentMonth - 3, currentDay + 1), endDate: currentDate, user: filterData.user});
                break;
            case 'last_6_months':
                setFilterData({startDate: new Date(currentYear, currentMonth - 6, currentDay + 1), endDate: currentDate, user: filterData.user});
                break;
            case 'last_1_year':
                setFilterData({startDate: new Date(currentYear - 1, currentMonth, currentDay + 1), endDate: currentDate, user: filterData.user});
                break;
            case 'custom':
                break;
            default:
                break;
        }
    }

    const onUserChange = (user) => {
        setFilterData({startDate: filterData.startDate, endDate: filterData.endDate, user: user});
    }

    const onReset = () => {
        refetch();
        setFilteredUsageLogs(usageLogs);
    }

    const filterProps = {dateOptions, userOptions, onDateChange, onUserChange, onReset, filterData};
    const tableProps = {groupedLogs, users, totals};

    return (
        <Container maxW="container.xl">
            <UsageLogFilter props={filterProps}></UsageLogFilter>
            <UsageLogTable props={tableProps} />
        </Container >
    );
}

export default UsageLog;