import { Icon } from "@chakra-ui/react";

const WarningIcon = (props) => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      d="M9.00001 17.3334C4.39751 17.3334 0.666672 13.6025 0.666672 9.00002C0.666672 4.39752 4.39751 0.666687 9.00001 0.666687C13.6025 0.666687 17.3333 4.39752 17.3333 9.00002C17.3333 13.6025 13.6025 17.3334 9.00001 17.3334ZM8.16667 11.5V13.1667H9.83334V11.5H8.16667ZM8.16667 4.83335V9.83335H9.83334V4.83335H8.16667Z"
      fill="currentColor"
    />
  </Icon>
);

export default WarningIcon;
