import { createContext, useContext, useState } from "react";

export const AdSetContext = createContext({});

export const useAdSet = () => useContext(AdSetContext);

export const AdSetProvider = ({ children }) => {
  const [adSets, setAdSets] = useState([]);

  return (
    <AdSetContext.Provider
      value={{
        adSets,
        setAdSets,
      }}
    >
      {children}
    </AdSetContext.Provider>
  );
};
