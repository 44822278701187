import {
  Box,
  Button,
  Modal as MainModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

function Modal({
  modalInfo: { campaign, adSet, numberOfLists },
  isOpen,
  onClose,
  isLoading,
  onConfirm,
}) {
  return (
    <MainModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="575px">
        <ModalHeader
          pt={5}
          pl={6}
          pr={9}
          pb={3}
          fontSize="18px"
          lineHeight="28px"
        >
          You are going to add {numberOfLists} audience
          {`${numberOfLists > 1 ? "s" : ""} `}
          in 1 ad set
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={3}>
            <Text fontWeight="600">Ad Campaign:</Text>
            <Text>{campaign}</Text>
          </Box>
          <Box mt={3}>
            <Text fontWeight="600">Ad Sets:</Text>
            <Text>{adSet}</Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            bg="white"
            _hover={{
              background: "white",
            }}
            _active={{
              background: "white",
            }}
            onClick={onClose}
            mr={3}
            color="gray.700"
            fontWeight="600"
          >
            Cancel
          </Button>
          <Button
            color="white"
            bg="gray.700"
            _hover={{
              background: "gray.800",
            }}
            fontWeight="600"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </MainModal>
  );
}

export default Modal;
