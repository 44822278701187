import { Box, Button, Flex, Text, chakra, useToast } from "@chakra-ui/react";
import { useAudience } from "contexts/AudienceContext";
import { useTCFTracker } from "contexts/TCFTrackerContext";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import NextButton from "./NextButton";
import { useAdSet } from "contexts/AdSetContext";
import { useCampaign } from "contexts/CampaignContext";
import ScrollbarFlex from "components/ScrollbarFlex";
import Toast from "components/Toast";
import { useGetUrlsByGroupId } from "hooks/tcftracker";
import { useAddRefLinksToAdSets } from "hooks/adSet";
import { useAccount } from "contexts/AccountContext";
import { useAsyncSession } from "contexts/AsyncSessionContext";
import { useUser } from "hooks/user";
import normalizeAdTriples from "utils/normalizeAdTriples";
import { LINKS } from "utils/constants";
import { useDuplicateAdSet } from "hooks/fbApi";

function RightSectionBody() {
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { account } = useAccount();
  const { adSets } = useAdSet();
  const { groupId, linkName, setUriList, uriList } = useTCFTracker();
  const { audiences } = useAudience();

  const { setAdSetAsyncIds } = useAsyncSession();

  const { mutate: addRefLinksToAdSets, isLoading: isRefLinksLoading } =
    useAddRefLinksToAdSets();

  const { campaign } = useCampaign();
  const { numberOfSelected: numberOfLists } = useAudience();
  const { mutate: getUrlsByGroupId, isLoading } = useGetUrlsByGroupId();
  const { mutate: duplicateAdSet, isLoading: isDuplicateLoading } =
    useDuplicateAdSet();

  const toast = useToast();

  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();

  const modalRef = useRef();

  const timeoutRef = useRef();

  const { data: user } = useUser();

  const isTrackerPath = location.pathname.includes("tracker");
  const isSummaryPath = location.pathname.includes("summary");
  const isAdSetSelectPath = location.pathname.includes("n-adsets");

  const bottomBorderStyle = {
    borderBottomWidth: 1,
    borderColor: "gray.200",
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function handleBackClick() {
    queryClient.removeQueries("campaigns");
    navigate(-1);
  }

  function onConfirm() {
    navigate("/audience-manager/audiences-to-n-adsets/summary");
  }

  function isIndiegogoURLValid(url) {
    return url.includes("indiegogo.com");
  }

  function generateLinksForIndiegogo(indiegogoURL) {
    const splittedUrl = indiegogoURL.split("/");

    const suffix = splittedUrl[splittedUrl.length - 1];
    const urlWithoutSuffix = splittedUrl.slice(0, -1).join("/");

    const suffixLetter = suffix.split(/[0-9]/)[0];
    const suffixNumber = Number(suffix.split(suffixLetter)[1]);

    return [...Array(numberOfLists).keys()]
      .map((_, i) => `${urlWithoutSuffix}/${suffixLetter}${suffixNumber + i}`)
      .map((item) => ({ id: item, name: item }));
  }

  function onClick() {
    if (isTrackerPath) {
      if (linkName === LINKS.INDIEGOGO) {
        if (isIndiegogoURLValid(groupId)) {
          const uris = generateLinksForIndiegogo(groupId);
          setUriList(uris);
          modalRef.current.openModal();
        } else {
          toastErrorRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Error"
                type="error"
                text={"Indiegogo URL is not valid"}
                onClick={() => closeToast(toastErrorRef)}
              />
            ),
          });
        }
      } else {
        getUrlsByGroupId(groupId, {
          onSuccess: (data) => {
            if (data?.payload.length > 0) {
              const urisFromTracker = getUrls(linkName, data.payload);
              setUriList(urisFromTracker);
              modalRef.current.openModal();
            } else {
              toastErrorRef.current = toast({
                duration: null,
                position: "top",
                render: () => (
                  <Toast
                    title="Error"
                    type="error"
                    text={"Group ID is not valid"}
                    onClick={() => closeToast(toastErrorRef)}
                  />
                ),
              });
            }
          },
          onError: (err) => {
            toastErrorRef.current = toast({
              duration: null,
              position: "top",
              render: () => (
                <Toast
                  title="Error"
                  type="error"
                  text={err?.message || err || "Error in request"}
                  onClick={() => closeToast(toastErrorRef)}
                />
              ),
            });
          },
        });
      }
    } else if (isAdSetSelectPath) {
      const splittedName = adSets[0].name.split("/");

      const suffix = splittedName[splittedName.length - 1];
      duplicateAdSet(
        { adSetId: adSets[0].id, count: numberOfLists, suffix },
        {
          onSuccess: (resp) => {
            const ids = resp.data.async_sessions.map((sess) => sess.id);
            setAdSetAsyncIds(ids);
            navigate("tracker");
          },
          onError: (err) => {
            toastErrorRef.current = toast({
              duration: null,
              position: "top",
              render: () => (
                <Toast
                  title="Error"
                  type="error"
                  text={err?.message || err || "Duplication Error"}
                  onClick={() => closeToast(toastErrorRef)}
                />
              ),
            });
          },
        }
      );
    }
  }

  function onSend() {
    const token = user.attributes["custom:token"];
    const input = {
      adTriples: normalizeAdTriples({ adSets, uriList, audiences }),
      account,
      token,
    };
    addRefLinksToAdSets(
      { body: input },
      {
        onSuccess: (data) => {
          setIsSendDisabled(true);
          const numberOfAuds = data.body
            .filter((body) => body.status === "fulfilled")
            .filter((val) => val.value.every((v) => v.adId)).length;

          toastSuccessRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Succeed"
                text={`${numberOfAuds} audiences added in <br/> Ad campaign ${campaign.name}`}
                onClick={() => closeToast(toastSuccessRef)}
              />
            ),
          });
          timeoutRef.current = setTimeout(
            () => navigate("/audience-manager"),
            2500
          );
        },
        onError: (err) => {
          console.error({ err });
          toastErrorRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Error"
                type="error"
                text={err?.message}
                onClick={() => closeToast(toastErrorRef)}
              />
            ),
          });
        },
      }
    );
  }

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  function getUrls(linkName, payload) {
    const urisByLinkName = {
      [LINKS.TCF_TRACKER]: "uri",
      [LINKS.KS_REFERRAL]: "destinationUri",
    };

    return payload
      .map((pl) => pl[urisByLinkName[linkName]])
      .map((item) => ({ id: item, name: item }));
  }

  return (
    <Flex height="full" direction="column">
      <Flex p={4} pl={6} borderBottomWidth={1} borderColor="gray.200">
        <Text fontWeight="600">No of Lists: {numberOfLists}</Text>
      </Flex>

      {campaign && (
        <>
          <Box p={4} pl={6} borderBottomWidth={1} borderColor="gray.200">
            <Text fontWeight="600">Ad Campaign:</Text>
            <Text>{campaign.name}</Text>
          </Box>
          {adSets && (
            <>
              {isTrackerPath && (
                <ScrollbarFlex
                  ml={5}
                  mr={5}
                  p={2}
                  borderWidth={1}
                  borderColor="gray.200"
                  overflow="scroll"
                  maxHeight="180px"
                  borderRadius={6}
                  flexDirection="column"
                >
                  {adSets.map((adset, i) => (
                    <Text key={adset.id + i}>{adset.name}</Text>
                  ))}
                </ScrollbarFlex>
              )}
              {isSummaryPath && (
                <Box p={4} pl={6} borderBottomWidth={1} borderColor="gray.200">
                  <Text fontWeight="600">Number of Uris: {uriList.length}</Text>
                </Box>
              )}
            </>
          )}
        </>
      )}
      <Flex flexDir="column" marginTop="auto">
        {!isTrackerPath && !isSummaryPath ? (
          <Text
            p={4}
            fontStyle="italic"
            fontWeight="400"
            color="gray.600"
            fontSize="14px"
          >
            IMPORTANT: Number of selected audiences should match to number of
            selected Ad Sets
          </Text>
        ) : (
          isTrackerPath && (
            <Text
              p={5}
              fontStyle="italic"
              fontWeight="400"
              color="gray.600"
              fontSize="14px"
            >
              Please note: Referral tags on Kickstarter can’t be deleted. This
              action is irreversible.
            </Text>
          )
        )}
        {!isSummaryPath ? (
          <Flex p={4} borderTopWidth={1} borderColor="gray.200">
            <Button
              onClick={handleBackClick}
              flexGrow={1}
              color="gray.700"
              bg="transparent"
              _hover={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mr={3}
            >
              {"<- Back"}
            </Button>
            <NextButton
              isLoading={isLoading || isDuplicateLoading}
              ref={modalRef}
              onClick={onClick}
              onConfirm={onConfirm}
              modalInfo={{
                campaign: campaign?.name,
                numberOfLists,
              }}
              disabled={adSets.length === 0 || (isTrackerPath && !groupId)}
            />
          </Flex>
        ) : (
          <Box py={4} px={6} borderTop="1px" borderColor="gray.200">
            <Button
              width="full"
              onClick={onSend}
              color="white"
              bg="gray.700"
              _hover={{
                background: "gray.800",
              }}
              isLoading={isRefLinksLoading}
              disabled={isSendDisabled}
            >
              Send
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

export default RightSectionBody;
