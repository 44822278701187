export function UserService({ Auth }) {
  const getSignedInUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return cognitoUser;
  };

  return {
    getSignedInUser,
  };
}
