import { Box } from "@chakra-ui/react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname + "/*" });

  const linkProps = {
    borderBottomColor: "gray.800",
    borderBottomWidth: 2,
  };

  return (
    <Box p={5} {...(match && linkProps)}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </Box>
  );
}

export default CustomLink;
