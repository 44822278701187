import Layout from "./Layout";
import LogoSection from "./LogoSection";
import NavSection from "./NavSection";
import SignOutMenu from "./SignOutMenu";
import {useNavigate} from "react-router-dom";

function Header() {
    const navigate = useNavigate();

    const onNavigate = (path) => {
        console.log(path)
        navigate(path);
    }

    return (
    <Layout>
      <LogoSection />
      <NavSection />
      <SignOutMenu onNavigate={onNavigate} />
    </Layout>
  );
}

export default Header;
