import { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { useAccount } from "contexts/AccountContext";
import { useGetAllAdAccounts } from "hooks/fbApi";
import BusinessSelect from "components/BusinessSelect";
import AccountSelect from "components/AccountSelect";

function BusinessAccountSelect() {
  const { data: allAdAccounts } = useGetAllAdAccounts();
  const { account, business, setAccount, setBusiness } = useAccount();

  const businesses = useMemo(() => {
    if (allAdAccounts) {
      return allAdAccounts.data.reduce((prev, acc) => {
        if (acc.business) {
          const existingBusiness = prev.find(
            (item) => item.name === acc.business.name
          );
          if (!existingBusiness) {
            prev.push({
              name: acc.business.name,
              id: acc.business.id,
              client_ad_accounts: {
                data: [
                  {
                    id: acc.id,
                    name: acc.name,
                  },
                ],
              },
            });
          } else {
            existingBusiness.client_ad_accounts.data.push({
              id: acc.id,
              name: acc.name,
            });
          }
        }
        return prev;
      }, []);
    }
  }, [allAdAccounts]);

  return (
    <Box color="gray.700" bg="blue.300" p={4} borderRadius={4}>
      <BusinessSelect
        key="businessSelect"
        businesses={businesses}
        onBusinessChange={setBusiness}
        business={business}
      />
      <AccountSelect
        key="accountSelect"
        businesses={businesses}
        business={business}
        onAccountChange={setAccount}
        account={account}
      />
    </Box>
  );
}

export default BusinessAccountSelect;
