import {
  Spinner,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
} from "@chakra-ui/react";
import SignoutIcon from "components/icons/SignoutIcon";
import LogIcon from "components/icons/LogIcon";
import { useSignOut } from "hooks/auth";
import { useUser } from "hooks/user";
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import {DB_MANAGEMENT_ALLOWED_USERS, USAGE_LOG_ALLOWED_USERS} from "../../../../utils/constants";


function SignOutMenu() {
  const { data: user } = useUser();
  const token = user?.attributes["custom:token"];
  const userId = JSON.parse(user?.attributes.identities)[0].userId;
  const { mutate: signOut, isLoading } = useSignOut();
  const navigate = useNavigate();
  const [path, setPath] = useState('');

  function onSignOut() {
    let arr = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("selected")) {
        arr.push(localStorage.key(i));
      }
    }
    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }

    signOut();
  }

  const handleNavigate = (path) => {
    setPath(path);
  }

  useEffect(() => {
    if (path) {
      navigate(path);
      setPath('');
    }
  }, [path, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Flex
      borderLeftColor="gray.200"
      borderLeftWidth={1}
      align="center"
      justify="center"
      px={5}
    >
      {token && userId && (
        <Menu width="50px">
          <MenuButton>
            <Image
              borderRadius="full"
              src={`${process.env.REACT_APP_FB_API_GRAPH_URL}/${userId}/picture?type=small&access_token=${token}`}
              alt="Profile Picture"
              w={10}
              h={10}
            />
          </MenuButton>
          <MenuList>
            {USAGE_LOG_ALLOWED_USERS.includes(user.username) && (
                <MenuItem
                    onClick={() => handleNavigate('usage-log')}
                    icon={<LogIcon width="18px" height="14px"/>}
                    _hover={{
                      color: "blue.400",
                    }}
                >
                  Usage Log
                </MenuItem>
            )}
            {/*{DB_MANAGEMENT_ALLOWED_USERS.includes(user.username) && (*/}
                <MenuItem
                    onClick={() => handleNavigate('db-manager')}
                    icon={<LogIcon width="18px" height="14px"/>}
                    _hover={{
                      color: "blue.400",
                    }}
                >
                  DB Management
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigate('db-manager/attributes')}
                    icon={<LogIcon width="18px" height="14px"/>}
                    _hover={{
                      color: "blue.400",
                    }}
                >
                  Characteristics
                </MenuItem>
            {/*)}*/}
            <MenuItem
              onClick={onSignOut}
              icon={<SignoutIcon width="18px" height="14px" />}
              _hover={{
                color: "red.400",
              }}
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
}

export default SignOutMenu;
