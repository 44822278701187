import { useInfiniteQuery, useQuery, useMutation } from "react-query";
import { useUser } from "hooks/user";
import { FBApiService } from "services/fbApi";

const useFBApiFactory = () => {
  const { data: user } = useUser();
  const token = user?.attributes["custom:token"];
  return FBApiService(token);
};

export const useGetBusinesses = () => {
  const query = useQuery("fbBusinesses", useFBApiFactory().getBusinesses);

  return query;
};

export const useGetAllAdAccounts = () => {
  const query = useQuery("allAdAccounts", useFBApiFactory().getAllAdAccounts);

  return query;
};

export const useGetCustomAudiences = (id, options) => {
  const query = useInfiniteQuery(
    [options.name || "audiences", id],
    useFBApiFactory().getCustomAudiences(id),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.data.paging?.cursors.after;
      },
      ...options,
    }
  );

  return query;
};

export const useGetCampaigns = (id, options) => {
  const query = useInfiniteQuery(
    [options?.name || "campaigns", id],
    useFBApiFactory().getCampaigns(id),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data.paging?.cursors.after;
      },
      ...options,
    }
  );

  return query;
};

export const useSearchAudience = () => {
  const mutation = useMutation(useFBApiFactory().searchAudience);
  return mutation;
};

export const useGetAdSets = (id, options) => {
  const query = useInfiniteQuery(
    [options?.name || "adSets", id],
    useFBApiFactory().getAdSets(id),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.data.paging?.cursors.after;
      },
      ...options,
    }
  );

  return query;
};

export const useGetAdsetAsyncStatuses = (sessionIds, options) => {
  const query = useQuery(
    [options?.name || "adSetAsyncStatuses"],
    useFBApiFactory().getAdSetAsyncStatuses(sessionIds),
    {
      ...options,
    }
  );

  return query;
};

export const useDuplicateAdSet = () => {
  const mutation = useMutation(useFBApiFactory().duplicateAdSet);
  return mutation;
};

export const useRenameDuplicatedAdSets = () => {
  const mutation = useMutation(useFBApiFactory().renameDuplicatedAdSets);
  return mutation;
};

export const useAddCustomAudiencesToAdSet = () => {
  const mutation = useMutation(useFBApiFactory().addCustomAudiencesToAdSet);
  return mutation;
};

export const useCreateLookalikes = () => {
  const mutation = useMutation(useFBApiFactory().createLookalikes);
  return mutation;
};

export const useDeleteCustomAudiences = () => {
    const mutation = useMutation(useFBApiFactory().deleteCustomAudiences);
    return mutation;
};