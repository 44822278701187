import { createContext, useContext, useState, useEffect } from "react";

export const AudienceContext = createContext({});

export const useAudience = () => useContext(AudienceContext);

export const AudienceProvider = ({ children }) => {
  const [audiences, setAudiences] = useState([]);
  const [numberOfSelected, setNumberOfSelected] = useState(0);
  const [numberOfLoadedAudiences, setNumberOfLoadedAudiences] = useState(0);

  useEffect(() => {
    setNumberOfSelected(audiences.length);
  }, [audiences]);

  return (
    <AudienceContext.Provider
      value={{
        audiences,
        setAudiences,
        numberOfSelected,
        numberOfLoadedAudiences,
        setNumberOfLoadedAudiences,
      }}
    >
      {children}
    </AudienceContext.Provider>
  );
};
