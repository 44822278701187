import { useCallback, useState, cloneElement, useRef } from "react";
import { useAudience } from "contexts/AudienceContext";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  Tabs,
  useToast,
  Select,
  IconButton
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import config from "aws-exports";
import { useCustomEventListener } from "utils/onCustomEventHook";
import ResetIcon from "components/icons/ResetIcon";
import Layout from "./Layout";
import SendToFbSection from "./SendToFbSection";
import SelectAllButton from "./SelectAllButton";
import SearchField from "./SearchField";
import audienceStore from "stores/audienceStore";
import { useSyncWithGsheet, useSyncWithS3 } from "hooks/sync";
import { useGetAudiences } from "hooks/audience";
import Toast from "components/Toast";
import ArrowUpIcon from "../../../components/icons/ArrowUpIcon";
import ArrowDownIcon from "../../../components/icons/ArrowDownIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { useDeleteCustomAudiences } from "../../../hooks/fbApi";

function LayoutWrapper({ children, hasFooter, checkedItems, setSelectedAuds }) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const allAuds = audienceStore.useStore(
      (state) => state.allAuds
  );

  const numberOfFiltered = audienceStore.useStore(
      (state) => state.filteredAuds
  )?.length;

  const numberOfAll = allAuds?.length ?? 0;
  const numberOfCustom = allAuds?.filter((aud) => aud.subtype == 'CUSTOM').length;
  const numberOfLookalike = allAuds?.filter((aud) => aud.subtype == 'LOOKALIKE').length;

  const { mutate: syncWithS3, isLoading: isS3SyncLoading } = useSyncWithS3();
  const { mutate: syncWithGsheet, isLoading: isGsheetSyncLoading } =
      useSyncWithGsheet();
  const { refetch } = useGetAudiences();

  const { numberOfLoadedAudiences } = useAudience();

  const toast = useToast();
  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();

  const [sortParam, setSortParam] = useState('default');
  const [sortDir, setSortDir] = useState('asc');

  const { mutate: deleteCustomAudiences, isLoading } = useDeleteCustomAudiences();

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  const location = useLocation();
  const isDatabasePath = location.pathname.includes("audience-db");
  const isAudienceManagerPath = location.pathname.includes("audience-manager");
  const isLookalikePath = location.pathname.includes("lookalike");

  const [triggerAudsRefetch, setTriggerAudsRefetch] = useState(false);


  useCustomEventListener("customAudience:unselectAll", () => {
    setSelectedAuds((prevState) => ({ ...prevState, selectedAuds: [] }));
  });

  const allChecked =
      checkedItems.length !== 0 && checkedItems.length === numberOfFiltered;

  const isIndeterminate =
      checkedItems.length > 0 && checkedItems.length < numberOfFiltered;

  function handleSingleCheck(audience) {
    const newCheckedItems = [...checkedItems];
    const index = newCheckedItems.findIndex((item) => item.id === audience.id);

    if (index >= 0) {
      newCheckedItems.splice(index, 1);
    } else {
      newCheckedItems.push(audience);
    }

    setSelectedAuds((prevState) => ({
      ...prevState,
      selectedAuds: newCheckedItems,
    }));
  }

  function handleAllCheck(isChecked, allAudiences) {
    if (isChecked) {
      setSelectedAuds((prevState) => ({
        ...prevState,
        selectedAuds: allAudiences,
      }));
    } else {
      setSelectedAuds((prevState) => ({
        ...prevState,
        selectedAuds: [],
      }));
    }
  }

  function handleSortParam(e) {
    setSortParam(e.target.value);
  }

  function handleSortDir() {
    setSortDir(sortDir == 'asc' ? 'desc' : 'asc');
  }

  const refetchFBAudiences = () => {
    setTriggerAudsRefetch(true);
  }

  function handleAudienceDelete() {
    deleteCustomAudiences(
        {
          input: checkedItems,
        },
        {
          onSuccess: (result) => {
            let successMessages = "";
            let errorMessages = "";

            result.forEach((res) => {
              if (res.result.success) {
                successMessages += (res.name + ' deleted<br/>');
              } else {
                errorMessages += (res.name + ' ' + res.result.message + '<br/>');
              }
            });

            if (successMessages) {
              toastSuccessRef.current = toast({
                duration: null,
                position: "top",
                render: () => (
                    <Toast
                        title="Succeed"
                        text={successMessages}
                        onClick={() => closeToast(toastSuccessRef)}
                    />
                ),
              });
            }
            if (errorMessages) {
              toastErrorRef.current = toast({
                duration: null,
                position: "top",
                render: () => (
                    <Toast
                        title="Error"
                        type="error"
                        text={errorMessages}
                        onClick={() => closeToast(toastErrorRef)}
                    />
                ),
              });
            }
            refetchFBAudiences();
          },
          onError: (err) => {
            console.error({err});
            toastErrorRef.current = toast({
              duration: null,
              position: "top",
              render: () => (
                  <Toast
                      title="Error"
                      type="error"
                      text={err.message}
                      onClick={() => closeToast(toastErrorRef)}
                  />
              ),
            });
          },

        }
    );
  }

  const handleSearch = useCallback((value) => {
    const keyword = value.toLowerCase();
    setSearchKeyword(keyword);
  }, []);

  return (
      children && (
          <Layout
              header={
                <Flex direction="column" w="full">
                  <SearchField onSearch={handleSearch} />
                  <Flex height="full" alignItems="center">
                    <SelectAllButton
                        allChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                    />
                    {!isDatabasePath &&
                        <Flex gap="2" ml="6">
                          <Select
                              width="160px"
                              onChange={handleSortParam}
                          >
                            <option value="default">Default</option>
                            <option value="name">Name</option>
                            <option value="created">Date created</option>
                            <option value="type">Type</option>
                          </Select>
                          <IconButton
                              colorScheme='blue'
                              icon={sortDir == 'asc' ? <ArrowUpIcon/> : <ArrowDownIcon/>}
                              onClick={handleSortDir}
                          />
                          <IconButton
                              colorScheme='blue'
                              icon={<DeleteIcon />}
                              disabled={checkedItems.length === 0}
                              onClick={handleAudienceDelete}
                              isLoading={isLoading}
                          />
                        </Flex>
                    }
                    <Flex ml="auto" justifyContent="center" alignItems="center">
                      {isDatabasePath && (
                          <Box mr={2}>
                            {checkedItems.length}/
                            {numberOfFiltered >= 0
                                ? numberOfFiltered
                                : numberOfLoadedAudiences}
                          </Box>
                      )}
                      <Button
                          leftIcon={<ResetIcon height="13px" width="13px" />}
                          color="gray.700"
                          height="24px"
                          disabled={checkedItems.length === 0}
                          onClick={() =>
                              setSelectedAuds((prevState) => ({
                                ...prevState,
                                selectedAuds: [],
                              }))
                          }
                          fontSize="13px"
                          p={1}
                          pr={2}
                          bg="none"
                          border="1px"
                          borderColor="gray.700"
                          borderRadius="6px"
                      >
                        Reset
                      </Button>
                      {/*{isDatabasePath && (*/}
                      {/*    <Button*/}
                      {/*        color="gray.700"*/}
                      {/*        height="24px"*/}
                      {/*        onClick={() => {*/}
                      {/*          syncWithS3(*/}
                      {/*              {*/}
                      {/*                body: {*/}
                      {/*                  // xApiKey: config.aws_appsync_apiKey,*/}
                      {/*                },*/}
                      {/*              },*/}
                      {/*              {*/}
                      {/*                onSuccess: (resp) => {*/}
                      {/*                  if (resp.body.addedAudiences.length === 0) {*/}
                      {/*                    toastSuccessRef.current = toast({*/}
                      {/*                      duration: null,*/}
                      {/*                      position: "top",*/}
                      {/*                      render: () => (*/}
                      {/*                          <Toast*/}
                      {/*                              title="No Lists to Sync"*/}
                      {/*                              onClick={() => closeToast(toastSuccessRef)}*/}
                      {/*                          />*/}
                      {/*                      ),*/}
                      {/*                    });*/}
                      {/*                    return;*/}
                      {/*                  }*/}
                      {/*                  syncWithGsheet(*/}
                      {/*                      {*/}
                      {/*                        body: {*/}
                      {/*                          // xApiKey: config.aws_appsync_apiKey,*/}
                      {/*                        },*/}
                      {/*                      },*/}
                      {/*                      {*/}
                      {/*                        onSuccess: (resp) => {*/}
                      {/*                          toastSuccessRef.current = toast({*/}
                      {/*                            duration: null,*/}
                      {/*                            position: "top",*/}
                      {/*                            render: () => (*/}
                      {/*                                <Toast*/}
                      {/*                                    title="Succeed"*/}
                      {/*                                    text={`${*/}
                      {/*                                        resp.body.length*/}
                      {/*                                    } lists have been synced <br/> ${resp.body*/}
                      {/*                                        .map((l) => l + "<br/>")*/}
                      {/*                                        .join(" ")}`}*/}
                      {/*                                    onClick={() =>*/}
                      {/*                                        closeToast(toastSuccessRef)*/}
                      {/*                                    }*/}
                      {/*                                />*/}
                      {/*                            ),*/}
                      {/*                          });*/}
                      {/*                          refetch();*/}
                      {/*                        },*/}
                      {/*                        onError: (err) => {*/}
                      {/*                          console.error({ err });*/}
                      {/*                          toastErrorRef.current = toast({*/}
                      {/*                            duration: null,*/}
                      {/*                            position: "top",*/}
                      {/*                            render: () => (*/}
                      {/*                                <Toast*/}
                      {/*                                    title="Error"*/}
                      {/*                                    type="error"*/}
                      {/*                                    text={err?.message}*/}
                      {/*                                    onClick={() =>*/}
                      {/*                                        closeToast(toastErrorRef)*/}
                      {/*                                    }*/}
                      {/*                                />*/}
                      {/*                            ),*/}
                      {/*                          });*/}
                      {/*                        },*/}
                      {/*                      }*/}
                      {/*                  );*/}
                      {/*                },*/}
                      {/*                onError: (err) => {*/}
                      {/*                  toastErrorRef.current = toast({*/}
                      {/*                    duration: null,*/}
                      {/*                    position: "top",*/}
                      {/*                    render: () => (*/}
                      {/*                        <Toast*/}
                      {/*                            title="Error"*/}
                      {/*                            type="error"*/}
                      {/*                            text={err?.message}*/}
                      {/*                            onClick={() => closeToast(toastErrorRef)}*/}
                      {/*                        />*/}
                      {/*                    ),*/}
                      {/*                  });*/}
                      {/*                },*/}
                      {/*              }*/}
                      {/*          );*/}
                      {/*        }}*/}
                      {/*        fontSize="13px"*/}
                      {/*        p={2}*/}
                      {/*        bg="none"*/}
                      {/*        border="1px"*/}
                      {/*        borderColor="gray.700"*/}
                      {/*        borderRadius="6px"*/}
                      {/*        ml={2}*/}
                      {/*        isLoading={isS3SyncLoading || isGsheetSyncLoading}*/}
                      {/*    >*/}
                      {/*      Sync*/}
                      {/*    </Button>*/}
                      {/*)}*/}
                    </Flex>
                  </Flex>
                </Flex>
              }
              footer={hasFooter && <SendToFbSection audiences={checkedItems} />}
          >
            {(isAudienceManagerPath || isLookalikePath) && (
                <Tabs
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    defaultIndex={0}
                    onChange={(index) => {
                      setSelectedAuds((prevState) => ({
                        ...prevState,
                        selectedAuds: [],
                      }));
                      setTabIndex(index);
                    }}
                >
                  <TabList>
                    <Tab>All {numberOfAll}</Tab>
                    <Tab>Custom {numberOfCustom}</Tab>
                    <Tab>Lookalike {numberOfLookalike}</Tab>
                  </TabList>
                </Tabs>
            )}
            {cloneElement(children, {
              ...((isAudienceManagerPath || isLookalikePath) && { tabIndex }),
              searchKeyword,
              checkedItems,
              handleSingleCheck,
              handleAllCheck,
              sortParam,
              sortDir,
              triggerAudsRefetch
            })}
          </Layout>
      )
  );
}

export default LayoutWrapper;
