import { Flex, Spinner } from "@chakra-ui/react";

function Loading() {
  return (
    <Flex align="center" justify="center" h="100vh" width="100%">
      <Spinner />
    </Flex>
  );
}

export default Loading;
