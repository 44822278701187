import { useEffect } from "react";
import { useGetCustomAudiences } from "hooks/fbApi";
import { useAccount } from "contexts/AccountContext";
import RightSectionLayout from "components/RightSectionLayout";
import AudienceList from "./AudienceList";
import BusinessAccountSelect from "components/BusinessAccountSelect";

function FBAudienceSection() {
  const { account, setAccount, setBusiness } = useAccount();
  const {
    data: customAudiences,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useGetCustomAudiences(account?.id, {
    name: "fbaudience-display",
    enabled: Boolean(account?.id),
  });

  useEffect(() => {
    setAccount(null);
    setBusiness(null);
  }, []);

  return (
    <RightSectionLayout header={<BusinessAccountSelect />}>
      <AudienceList
        customAudiences={customAudiences}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </RightSectionLayout>
  );
}

export default FBAudienceSection;
