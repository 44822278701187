import { createContext, useContext, useState } from "react";

export const CampaignContext = createContext({});

export const useCampaign = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
  const [campaign, setCampaign] = useState(null);

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        setCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
