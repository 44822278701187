import { Icon } from "@chakra-ui/react";

const ResetIcon = (props) => (
  <Icon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M7.5 3.422C7.5 3.422 8.07094 3.14075 6 3.14075C5.25832 3.14075 4.5333 3.36068 3.91661 3.77274C3.29993 4.18479 2.81928 4.77046 2.53545 5.45568C2.25162 6.14091 2.17736 6.89491 2.32206 7.62233C2.46675 8.34976 2.8239 9.01795 3.34835 9.5424C3.8728 10.0668 4.54098 10.424 5.26841 10.5687C5.99584 10.7134 6.74984 10.6391 7.43506 10.3553C8.12029 10.0715 8.70596 9.59082 9.11801 8.97413C9.53007 8.35745 9.75 7.63243 9.75 6.89075"
      stroke="#2D3748"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6 1.35974L7.875 3.23474L6 5.10974"
      stroke="#2D3748"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default ResetIcon;
