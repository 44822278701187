import { Box, Flex } from "@chakra-ui/react";

function RightSectionLayout({ header, children, hasBorder }) {
  return (
    <Flex
      direction="column"
      overflowY="auto"
      {...(hasBorder && {
        border: "1px",
        borderColor: "gray.200",
        height: "full",
        borderTopWidth: 0,
      })}
    >
      <Box
        p={4}
        px={6}
        pb={5}
        {...(hasBorder && {
          borderBottomWidth: "1px",
          borderColor: "gray.200",
        })}
      >
        {header}
      </Box>
      {children}
    </Flex>
  );
}

export default RightSectionLayout;
