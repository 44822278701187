import { Container, Flex } from "@chakra-ui/react";

function BodyLayout({ topSection, leftSection, middleSection, rightSection }) {
  return (
    <Container maxW="container.xl" height="90vh" p={0}>
      <Flex h="100%">
        <Flex direction="column" w="67%" p={0}>
          {topSection && <Flex maxH="20%">{topSection.element}</Flex>}
          <Flex minH="80%">
            <Flex pb={10} h="100%" w="100%">
              <Flex
                w="100%"
                border="1px"
                borderColor="gray.200"
                borderRadius={2}
              >
                {leftSection && (
                  <Flex direction="column" w="40%" bg={leftSection.bg} mr={6}>
                    {leftSection.element}
                  </Flex>
                )}
                <Flex
                  direction="column"
                  bg={"middleSection.bg"}
                  w={leftSection ? "60%" : "100%"}
                  ml={leftSection ? 0 : 5}
                >
                  {middleSection.element}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" w="33%" bg={rightSection.bg}>
          {rightSection.element}
        </Flex>
      </Flex>
    </Container>
  );
}

export default BodyLayout;
