/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAudience = /* GraphQL */ `
  query GetAudience($id: ID!) {
    getAudience(id: $id) {
      id
      name
      campaignType
      campaignLink
      godLevel
      backers
      year
      raised
      price
      description
      type
      number
      file {
        bucket
        region
        key
      }
      subcategory {
        id
        name
        category {
          id
          name
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        createdAt
        updatedAt
        categorySubcategoriesId
      }
      characteristics {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      subcategoryAudiencesId
    }
  }
`;
export const listAudiences = /* GraphQL */ `
  query ListAudiences(
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      subcategories {
        items {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAudienceLog = /* GraphQL */ `
  query GetAudienceLog($id: ID!) {
    getAudienceLog(id: $id) {
      username
      audienceName
      accountName
      businessName
      numberOfUsers
      id
      createdAt
      updatedAt
    }
  }
`;
export const listAudienceLogs = /* GraphQL */ `
  query ListAudienceLogs(
    $filter: ModelAudienceLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudienceLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        username
        audienceName
        accountName
        businessName
        numberOfUsers
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubcategory = /* GraphQL */ `
  query GetSubcategory($id: ID!) {
    getSubcategory(id: $id) {
      id
      name
      category {
        id
        name
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          campaignType
          campaignLink
          godLevel
          backers
          year
          raised
          price
          description
          type
          number
          createdAt
          updatedAt
          subcategoryAudiencesId
        }
        nextToken
      }
      createdAt
      updatedAt
      categorySubcategoriesId
    }
  }
`;
export const listSubcategories = /* GraphQL */ `
  query ListSubcategories(
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category {
          id
          name
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        createdAt
        updatedAt
        categorySubcategoriesId
      }
      nextToken
    }
  }
`;
export const getCharacteristic = /* GraphQL */ `
  query GetCharacteristic($id: ID!) {
    getCharacteristic(id: $id) {
      id
      name
      audiences {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCharacteristics = /* GraphQL */ `
  query ListCharacteristics(
    $filter: ModelCharacteristicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharacteristics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        audiences {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCharacteristicAudiences = /* GraphQL */ `
  query GetCharacteristicAudiences($id: ID!) {
    getCharacteristicAudiences(id: $id) {
      id
      audienceID
      characteristicID
      audience {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      characteristic {
        id
        name
        audiences {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCharacteristicAudiences = /* GraphQL */ `
  query ListCharacteristicAudiences(
    $filter: ModelCharacteristicAudiencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharacteristicAudiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        audienceID
        characteristicID
        audience {
          id
          name
          campaignType
          campaignLink
          godLevel
          backers
          year
          raised
          price
          description
          type
          number
          createdAt
          updatedAt
          subcategoryAudiencesId
        }
        characteristic {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const audByName = /* GraphQL */ `
  query AudByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      nextToken
    }
  }
`;
export const audienceByName = /* GraphQL */ `
  query AudienceByName(
    $type: String!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audienceByName(
      type: $type
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      nextToken
    }
  }
`;
