import { useSyncExternalStore } from "use-sync-external-store/shim";

function createStore(initialState) {
  let currentState = initialState;
  const listeners = new Set();
  const subscribe = (listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };
  return {
    getState: () => currentState,
    setState: (fn) => {
      currentState = fn(currentState);
      listeners.forEach((listener) => listener());
    },
    subscribe,
    useStore: (selector = (state) => state) =>
      useSyncExternalStore(subscribe, () => selector(currentState)),
  };
}

export default createStore;
