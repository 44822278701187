import { useAmplify } from "contexts/AmplifyContext";
import { AudienceService } from "services/audience";
import { useMutation, useQuery } from "react-query";

const useAudienceFactory = () => {
  const { API, graphqlOperation } = useAmplify();
  return AudienceService({ API, graphqlOperation });
};

export const useSingleCreate = () => {
  const mutation = useMutation(useAudienceFactory().createSingle);
  return mutation;
};

export const useMultipleCreate = () => {
  const mutation = useMutation(useAudienceFactory().createMultiple);

  return mutation;
};

export const useGetAudiences = (limit, filter) => {
  const audienceFactory = useAudienceFactory();
  const query = useQuery("allAudiences", () =>
      audienceFactory.getAudiences(limit, filter));
  return query;
};

export const useGetCategories = () => {
  const audienceFactory = useAudienceFactory();

  const query = useQuery("allCategories", () =>
    audienceFactory.getCategories()
  );

  return query;
};

export const useGetCharacteristics = () => {
  const audienceFactory = useAudienceFactory();

  return useQuery("allCharacteristics", () =>
    audienceFactory.getCharacteristics()
  );
};

export const useAddCustomAudiencesToFB = () => {
  const mutation = useMutation(useAudienceFactory().addCustomAudiencesToFB);
  return mutation;
};

export const useCheckForDuplicates = () => {
  const mutation = useMutation(useAudienceFactory().checkForDuplicates);
  return mutation;
};
