import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Textarea,
} from "@chakra-ui/react";

function FindAudiencesForm({ onSubmit, isLoading, formData, setFormData }) {
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <Flex
      border="1px"
      borderColor="gray.200"
      borderRadius={4}
      alignItems="center"
      w="full"
      flexDir="column"
      mt={3}
      py={4}
    >
      <Flex flexDir="column" alignItems="center" w="70%">
        <FormControl>
          <FormLabel fontSize="18px">Title</FormLabel>
          <Input
            placeholder="Title"
            onChange={(e) =>
              setFormData({
                ...formData,
                title: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel fontSize="18px">Description</FormLabel>
          <Textarea
            placeholder="Description"
            onChange={(e) =>
              setFormData({
                ...formData,
                description: e.target.value,
              })
            }
          />
          <>
            <Box mt={4}>
              <Text>Threshold</Text>
              <Slider
                aria-label="slider-ex-6"
                onChange={(val) =>
                  setFormData({
                    ...formData,
                    threshold: val,
                  })
                }
              >
                <SliderMark value={25} {...labelStyles}>
                  25%
                </SliderMark>
                <SliderMark value={50} {...labelStyles}>
                  50%
                </SliderMark>
                <SliderMark value={75} {...labelStyles}>
                  75%
                </SliderMark>
                <SliderMark
                  value={formData.threshold}
                  textAlign="center"
                  bg="blue.400"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {formData.threshold}%
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
          </>
        </FormControl>
        <Button
          onClick={onSubmit}
          mt={8}
          color="white"
          bg="gray.700"
          _hover={{
            background: "gray.800",
          }}
          isLoading={isLoading}
        >
          Find Audiences
        </Button>
      </Flex>
    </Flex>
  );
}

export default FindAudiencesForm;
