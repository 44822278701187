import { useAmplify } from "contexts/AmplifyContext";
import { useMutation } from "react-query";
import { AdSetService } from "services/adSet";

const useAdSetFactory = () => {
  const { API } = useAmplify();
  return AdSetService({ API });
};

export const useAddRefLinksToAdSets = () => {
  const mutation = useMutation(useAdSetFactory().addRefLinksToAdSets);
  return mutation;
};
