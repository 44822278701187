import { Box, Container, Flex, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ListsTable from "./ListsTable";
import ListsFilters from "./ListsFilters";
import ActionsSection from "./ActionsSection";
import { Pagination } from "./Pagination";
import { AudienceService } from "../../services/audience";
import { useAmplify } from "../../contexts/AmplifyContext";
// import { useUser } from "../../hooks/user";
// import { DB_MANAGEMENT_ALLOWED_USERS } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

function DBManager() {
    // const { data: user } = useUser();
    const navigate = useNavigate();
    // if (!DB_MANAGEMENT_ALLOWED_USERS.includes(user.username)) {
    //     navigate('/');
    // }

    const { API, graphqlOperation } = useAmplify();
    const [filterState, setFilterState] = useState({listType: 'custom'});
    const [selectedLists, setSelectedLists] = useState([]);
    const [listType, setListType] = useState('custom');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [sortDir, setSortDir] = useState("DESC");
    const [audiences, setAudiences] = useState([]);
    const [total, setTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [paginationParams, setPaginationParams] = useState({total, page, perPage});
    const [tabIndex, setTabIndex] = useState(0);
    const [backers, setBackers] = useState(0);
    const [checkedLists, setCheckedLists] = useState([]);


    const getAudiences = (params) => {
        setIsLoading(true);
        AudienceService({API, graphqlOperation}).getAudiencesFromDB(params).then((res) => {
            setAudiences(res.items);
            setTotal(res.total);
            setBackers(res.backers);
            setPaginationParams({total: res.total, page, perPage});
            setIsLoading(false);
            const filterKeys = Object.keys(filterState);
            if (filterKeys.length === 1 && filterKeys[0] === 'listType') {
                setGrandTotal(res.total)
            }
        });
    }

    const onFilterApply = (filters) => {
        if (page !== 1) {
            setPage(1);
        }
        setFilterState(filters);
    }

    useEffect(() => {
        getAudiences({perPage, page, sortDir, filters: filterState});
    }, [filterState, page]);

    const onListReload = () => {
        getAudiences({perPage, page, sortDir, filters: filterState});
    }

    const onTabChange = (tab) => {
        setPage(1);
        setListType(tab);
        setTabIndex(tab === 'merged' ? 1 : 0);
        setFilterState({listType: tab});
    }

    const onSelectList = (lists) => {
        setSelectedLists(lists);
    }

    useEffect(() => {
        setCheckedLists(selectedLists)
    }, [selectedLists]);

    const onMergeLists = () => {
        setTabIndex(1);
        onTabChange('merged');
        setSelectedLists([]);
    }

    const onPageSelect = (pageNum) => {
        setPage(pageNum);
        // setPaginationParams({total, page: pageNum, perPage});
    }

    const tableProps = { listType, audiences, isLoading, onSelectList, total, page, selectedLists, onListReload }
    const filterProps = { listType, filterState, onFilterApply }
    const actionProps = { selectedLists, onSelectList, onMergeLists, listType }
    const paginationProps = { ...paginationParams, onPageSelect }


    return(
        <Container maxW="container.xl">
            <Tabs
                isFitted
                isLazy
                variant="enclosed"
                mt={3}
                index={tabIndex}
                onChange={(index) => onTabChange(index ? 'merged' : 'custom')}
            >
                <TabList>
                    <Tab>Custom lists</Tab>
                    <Tab>Merged lists</Tab>
                </TabList>
            </Tabs>

            <Flex justifyContent="flex-end" mt={3}>
                <ActionsSection
                    checkedLists={checkedLists}
                    onSelectList={onSelectList}
                    onMergeLists={onMergeLists}
                    listType={listType}
                ></ActionsSection>
            </Flex>

            <Box mt={3} mb={3}>
                <ListsFilters props={filterProps}></ListsFilters>
            </Box>

            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Text>Total found: {total} of {grandTotal} / Backers: {backers.toLocaleString()} Selected: {selectedLists.length}</Text>
                {(total > 0 && !isLoading) &&
                    <Pagination props={paginationProps}></Pagination>
                }
            </Flex>

            <ListsTable props={tableProps}></ListsTable>

            <Flex justifyContent="flex-end" alignItems="center" mt={3}>
                {!isLoading &&
                    <Pagination props={paginationProps}></Pagination>
                }
            </Flex>

        </Container>
    )
}

export default DBManager;