import { useEffect, useState } from "react";
import {Button, ButtonGroup, IconButton, Text} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

export function Pagination({props}) {
    const paginationNumbers = Array.from({length: Math.ceil(props.total / props.perPage)}, (_, index) => index + 1);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        setLastPage(Math.ceil(props.total / props.perPage));
    }, [props.total]);
    const onPageButtonClick = (num) => {
        if (num > 0 && num <= lastPage) {
            props.onPageSelect(num);
        }
    }
    
    return (
        <ButtonGroup>
            <IconButton
                aria-label="Previous"
                icon={<ChevronLeftIcon />}
                disabled={props.page === 1}
                onClick={() => onPageButtonClick(props.page - 1)}
            ></IconButton>
            {lastPage > 0 &&
                <>
                    {lastPage < 9 ? paginationNumbers.map((number) => (
                        <Button
                            key={number}
                            onClick={(num) => onPageButtonClick(number)}
                            colorScheme={number === props.page ? 'blue' : 'gray'}
                        >
                            {number}
                        </Button>
                    )) : (
                        <>
                            <Button
                                key="1"
                                onClick={(num) => onPageButtonClick(1)}
                                colorScheme={1 === props.page ? 'blue' : 'gray'}
                            >
                                1
                            </Button>
                            {(props.page > 4) &&
                                <Text>...</Text>
                            }
                            {props.page > 3 &&
                                <Button
                                    key={props.page - 2}
                                    onClick={(num) => onPageButtonClick(props.page - 2)}
                                    colorScheme={(props.page - 2) === props.page ? 'blue' : 'gray'}
                                >
                                    {props.page - 2}
                                </Button>
                            }
                            {props.page > 2 &&
                                <Button
                                    key={props.page - 1}
                                    onClick={(num) => onPageButtonClick(props.page - 1)}
                                    colorScheme={(props.page - 1) === props.page ? 'blue' : 'gray'}
                                >
                                    {props.page - 1}
                                </Button>
                            }
                            {(props.page > 1 && props.page < lastPage) &&
                                <Button
                                    key={props.page}
                                    onClick={(num) => onPageButtonClick(props.page)}
                                    colorScheme={props.page === props.page ? 'blue' : 'gray'}
                                >
                                    {props.page}
                                </Button>
                            }
                            {(props.page < lastPage - 2) &&
                                <>
                                    <Button
                                        key={props.page + 1}
                                        onClick={(num) => onPageButtonClick(props.page + 1)}
                                        colorScheme={(props.page + 1) === props.page ? 'blue' : 'gray'}
                                    >
                                        {props.page + 1}
                                    </Button>
                                    <Button
                                        key={props.page + 2}
                                        onClick={(num) => onPageButtonClick(props.page + 2)}
                                        colorScheme={(props.page + 2) === props.page ? 'blue' : 'gray'}
                                    >
                                        {props.page + 2}
                                    </Button>
                                    <Text>...</Text>
                                </>
                            }

                            <Button
                                key={lastPage}
                                onClick={(num) => onPageButtonClick(lastPage)}
                                colorScheme={lastPage === props.page ? 'blue' : 'gray'}
                            >
                                {lastPage}
                            </Button>
                        </>
                    )}
                </>
            }
            <IconButton
                aria-label="Next"
                icon={<ChevronRightIcon />}
                disabled={props.page === lastPage}
                onClick={() => onPageButtonClick(props.page + 1)}
            ></IconButton>
        </ButtonGroup>
    )
}