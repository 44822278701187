import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import FilterSelect from "./FilterSelect";
import { SELECT_FILTERS } from "utils/constants";
import filterStore from "stores/filterStore";
import FilterResetIcon from "components/icons/FilterResetIcon";
import DownIcon from "components/icons/DownIcon";

function OtherFiltersSection() {
  const filterStoreState = filterStore.useStore();
  const setfilterStore = filterStore.setState;

  return (
    <Flex direction="column" w="full" p={4} pl={0}>
      <Flex alignItems="center">
        <Menu closeOnSelect={false}>
          <MenuButton
            width="700px"
            fontSize="12px"
            fontWeight="700"
            color="gray.900"
            border="1px solid"
            borderColor="gray.200"
            as={Button}
            colorScheme="white"
            p={0}
            pr={4}
            pl={4}
            mr={2}
          >
            <Flex w="full">
              <Text>
                Characteristics({filterStoreState.characteristics.length})
              </Text>{" "}
              <DownIcon ml="auto" />
            </Flex>
          </MenuButton>
          <MenuList maxH="80vh" overflowY="scroll">
            <MenuOptionGroup
              onChange={(value) =>
                setfilterStore((prevState) => ({
                  ...prevState,
                  characteristics: value,
                }))
              }
              type="checkbox"
              value={filterStoreState.characteristics}
            >
              {SELECT_FILTERS.characteristics.map((v) => (
                <MenuItemOption key={v.value} value={v.value}>
                  {v.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <FilterSelect
          onChange={(e) =>
            setfilterStore((prevState) => ({
              ...prevState,
              campaignType: e.target.value,
            }))
          }
          placeholder="Campaign Type"
          options={SELECT_FILTERS.campaignType}
          value={filterStoreState.campaignType}
        />
        <FilterSelect
          onChange={(e) =>
            setfilterStore((prevState) => ({
              ...prevState,
              price: e.target.value,
            }))
          }
          placeholder="Pricing"
          options={SELECT_FILTERS.price}
          value={filterStoreState.price}
        />
        <FilterSelect
          onChange={(e) =>
            setfilterStore((prevState) => ({
              ...prevState,
              year: e.target.value,
            }))
          }
          placeholder="Year"
          options={SELECT_FILTERS.year}
          value={filterStoreState.year}
        />
          {/*<FilterSelect*/}
          {/*    onChange={(e) =>*/}
          {/*        setfilterStore((prevState) => ({*/}
          {/*            ...prevState,*/}
          {/*            deliveryStatus: e.target.value,*/}
          {/*        }))*/}
          {/*    }*/}
          {/*    placeholder="Delivery Status"*/}
          {/*    options={SELECT_FILTERS.deliveryStatus}*/}
          {/*    value={filterStoreState.deliveryStatus}*/}
          {/*/>*/}
        <Flex>
          <Button
            onClick={() => {
              setfilterStore(() => ({
                characteristics: [],
                campaignType: "",
                price: "",
                year: "",
              }));
            }}
            bg="white"
            border="1px"
            borderRadius="100%"
            width="38px"
            height="38px"
            p={0}
            borderColor="gray.200"
          >
            <FilterResetIcon />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default OtherFiltersSection;
