import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useAudience } from "contexts/AudienceContext";
import { useAddCustomAudiencesToAdSet } from "hooks/fbApi";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { useCustomEventListener } from "utils/onCustomEventHook";
import NextButton from "./NextButton";
import Toast from "components/Toast";

function RightSectionBody() {
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { payload: adSet, setPayload: setAdSet } =
    useCustomEventListener("adSet:select");
  const { payload: campaign } = useCustomEventListener("campaign:select", () =>
    setAdSet("")
  );
  const { numberOfSelected: numberOfLists, audiences } = useAudience();

  const { mutate: addCustomAudiencesToAdSet, isLoading } =
    useAddCustomAudiencesToAdSet();

  const toast = useToast();
  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();
  const modalRef = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  function handleBackClick() {
    queryClient.removeQueries("campaigns");
    navigate(-1);
  }

  async function onConfirm() {
    addCustomAudiencesToAdSet(
      { id: adSet.id, audiences },
      {
        onSuccess: () => {
          setIsNextDisabled(true);
          modalRef.current.closeModal();
          toastSuccessRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Succeed"
                text={`${numberOfLists} audiences added in <br/> Ad campaign ${campaign.name} <br /> Ad Set ${adSet.name}`}
                onClick={() => closeToast(toastSuccessRef)}
              />
            ),
          });
          timeoutRef.current = setTimeout(
            () => navigate("/audience-manager"),
            2000
          );
        },
        onError: (err) => {
          modalRef.current.closeModal();
          console.error({ err });
          toastErrorRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Error"
                type="error"
                text={err?.response.data.error.error_user_msg}
                onClick={() => closeToast(toastErrorRef)}
              />
            ),
          });
        },
      }
    );
  }

  return (
    <Flex height="full" direction="column">
      <Flex p={4} pl={6} borderBottomWidth={1} borderColor="gray.200">
        <Text fontWeight="600">No of Lists: {numberOfLists}</Text>
      </Flex>

      {campaign && (
        <>
          <Box mt={6} pl={6}>
            <Text fontWeight="600">Ad Campaign:</Text>
            <Text>{campaign.name}</Text>
          </Box>
          {adSet && (
            <Box mt={3} pl={6}>
              <Text fontWeight="600">Ad Sets:</Text>
              <Text>{adSet.name}</Text>
            </Box>
          )}
        </>
      )}
      <Flex p={4} borderTopWidth={1} borderColor="gray.200" marginTop="auto">
        <Button
          onClick={handleBackClick}
          flexGrow={1}
          color="gray.700"
          bg="transparent"
          _hover={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mr={3}
        >
          {"<- Back"}
        </Button>
        <NextButton
          ref={modalRef}
          onConfirm={onConfirm}
          modalInfo={{
            campaign: campaign?.name,
            adSet: adSet?.name,
            numberOfLists,
          }}
          disabled={!(adSet && campaign) || isNextDisabled}
          isLoading={isLoading}
        />
      </Flex>
    </Flex>
  );
}

export default RightSectionBody;
