import { Box, Flex, Text } from "@chakra-ui/react";
import CloseIcon from "components/icons/CloseIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import WarningIcon from "components/icons/WarningIcon";

function Toast({ type = "success", title, text, onClick, invalidEntryList }) {
  const typeObj = {
    success: {
      bg: "gray.800",
      icon: <SuccessIcon mr={3} boxSize={5} />,
    },
    error: {
      bg: "red.500",
      icon: <WarningIcon mr={3} boxSize={5} />,
    },
  };

  return (
    <Box
      position="relative"
      borderRadius={6}
      color="white"
      p={3}
      bg={typeObj[type].bg}
    >
      <Flex fontWeight="700" alignItems="center">
        {typeObj[type].icon}
        {title}
      </Flex>
      <Text mt={1} dangerouslySetInnerHTML={{ __html: text }}></Text>
      {invalidEntryList &&
        invalidEntryList.map((item) => (
          <Text key={item} mt={1}>
            {item}
          </Text>
        ))}
      <CloseIcon
        boxSize={3}
        cursor="pointer"
        position="absolute"
        onClick={onClick}
        right="3"
        top="3"
      />
    </Box>
  );
}

export default Toast;
