import { useAmplify } from "contexts/AmplifyContext";
import { UsageLogService } from "services/usageLog";
import {useMutation, useQuery} from "react-query";

const useUsageLogFactory = () => {
  const { API, graphqlOperation, Amplify } = useAmplify();
  return UsageLogService({ API, graphqlOperation });
};

export const useGetUsageLog = () => {
  const usageLogFactory = useUsageLogFactory();
  const query = useQuery("allAudienceLogs", () =>
    usageLogFactory.getUsageLog()
  );

  return query;
};

export const useGetUsers = () => {
  const usageLogFactory = useUsageLogFactory();
  const query = useQuery("allCognitoUsers", () =>
      usageLogFactory.getUsers()
  );

  return query;
};


export const useCreateUsageLog = (input) => {
  const mutation = useMutation(useUsageLogFactory().createUsageLog);
  return mutation;
}