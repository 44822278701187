import RightSectionLayout from "components/RightSectionLayout";
import SendToAds from "./SendToAds";
import BusinessAccountSelect from "components/BusinessAccountSelect";

function AdSetSection() {
  return (
    <RightSectionLayout hasBorder header={<BusinessAccountSelect />}>
      <SendToAds />
    </RightSectionLayout>
  );
}

export default AdSetSection;
