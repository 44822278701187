import CategorySection from "pages/AudienceDB/CategorySection";
import OtherFiltersSection from "pages/AudienceDB/OtherFiltersSection";
import BodyLayout from "pages/Layout/BodyLayout";
import AdSetSection from "./AdSetSection";
import FBAudienceSelectSection from "./FBAudienceSelectSection";

function AudienceManager() {
  return (
    <BodyLayout
      topSection={{ element: <OtherFiltersSection /> }}
      leftSection={{ element: <CategorySection />, bg: "gray.100" }}
      middleSection={{ element: <FBAudienceSelectSection /> }}
      rightSection={{ element: <AdSetSection /> }}
    />
  );
}

export default AudienceManager;
