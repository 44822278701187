import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text
} from '@chakra-ui/react'

function UsageLogTable({props}) {
    return (
        // <TableContainer maxWidth="100%">
            <Table variant="striped"  size="lg">
                <Thead>
                    <Tr>
                        <Th>User</Th>
                        <Th>Ad Account</Th>
                        <Th>Num of Lists sent</Th>
                        <Th>Num of LL created</Th>
                        <Th>Num of ML created</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.groupedLogs && props.groupedLogs
                        .map((log) => (
                            <Tr key={log.key}>
                                <Td>{props.users && props.users[log.key.split("__")[0]]}</Td>
                                <Td>{log.key.split("__")[1]}</Td>
                                <Td><Text align="center">{log.key.split("__")[2] == 'SendFB' ? log.count : 0}</Text></Td>
                                <Td><Text align="center">{log.key.split("__")[2] == 'CreateLL' ? log.sum : 0}</Text></Td>
                                <Td><Text align="center">{log.key.split("__")[2] == 'CreateML' ? log.sum : 0}</Text></Td>
                            </Tr>
                        ))
                    }
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th colSpan={2}>Total</Th>
                        <Th><Text align="center">{props.totals.SendFB}</Text></Th>
                        <Th><Text align="center">{props.totals.CreateLL}</Text></Th>
                        <Th><Text align="center">{props.totals.CreateML}</Text></Th>
                    </Tr>
                </Tfoot>
            </Table>
        // </TableContainer>
    );
}


export default UsageLogTable;