/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAudience = /* GraphQL */ `
  mutation CreateAudience(
    $input: CreateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    createAudience(input: $input, condition: $condition) {
      id
      name
      campaignType
      campaignLink
      godLevel
      backers
      year
      raised
      price
      description
      type
      number
      file {
        bucket
        region
        key
      }
      subcategory {
        id
        name
        category {
          id
          name
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        createdAt
        updatedAt
        categorySubcategoriesId
      }
      characteristics {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      subcategoryAudiencesId
    }
  }
`;
export const updateAudience = /* GraphQL */ `
  mutation UpdateAudience(
    $input: UpdateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    updateAudience(input: $input, condition: $condition) {
      id
      name
      campaignType
      campaignLink
      godLevel
      backers
      year
      raised
      price
      description
      type
      number
      file {
        bucket
        region
        key
      }
      subcategory {
        id
        name
        category {
          id
          name
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        createdAt
        updatedAt
        categorySubcategoriesId
      }
      characteristics {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      subcategoryAudiencesId
    }
  }
`;
export const deleteAudience = /* GraphQL */ `
  mutation DeleteAudience(
    $input: DeleteAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    deleteAudience(input: $input, condition: $condition) {
      id
      name
      campaignType
      campaignLink
      godLevel
      backers
      year
      raised
      price
      description
      type
      number
      file {
        bucket
        region
        key
      }
      subcategory {
        id
        name
        category {
          id
          name
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        createdAt
        updatedAt
        categorySubcategoriesId
      }
      characteristics {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      subcategoryAudiencesId
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      subcategories {
        items {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      subcategories {
        items {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      subcategories {
        items {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAudienceLog = /* GraphQL */ `
  mutation CreateAudienceLog(
    $input: CreateAudienceLogInput!
    $condition: ModelAudienceLogConditionInput
  ) {
    createAudienceLog(input: $input, condition: $condition) {
      username
      audienceName
      accountName
      businessName
      numberOfUsers
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateAudienceLog = /* GraphQL */ `
  mutation UpdateAudienceLog(
    $input: UpdateAudienceLogInput!
    $condition: ModelAudienceLogConditionInput
  ) {
    updateAudienceLog(input: $input, condition: $condition) {
      username
      audienceName
      accountName
      businessName
      numberOfUsers
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudienceLog = /* GraphQL */ `
  mutation DeleteAudienceLog(
    $input: DeleteAudienceLogInput!
    $condition: ModelAudienceLogConditionInput
  ) {
    deleteAudienceLog(input: $input, condition: $condition) {
      username
      audienceName
      accountName
      businessName
      numberOfUsers
      id
      createdAt
      updatedAt
    }
  }
`;
export const createSubcategory = /* GraphQL */ `
  mutation CreateSubcategory(
    $input: CreateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    createSubcategory(input: $input, condition: $condition) {
      id
      name
      category {
        id
        name
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          campaignType
          campaignLink
          godLevel
          backers
          year
          raised
          price
          description
          type
          number
          createdAt
          updatedAt
          subcategoryAudiencesId
        }
        nextToken
      }
      createdAt
      updatedAt
      categorySubcategoriesId
    }
  }
`;
export const updateSubcategory = /* GraphQL */ `
  mutation UpdateSubcategory(
    $input: UpdateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    updateSubcategory(input: $input, condition: $condition) {
      id
      name
      category {
        id
        name
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          campaignType
          campaignLink
          godLevel
          backers
          year
          raised
          price
          description
          type
          number
          createdAt
          updatedAt
          subcategoryAudiencesId
        }
        nextToken
      }
      createdAt
      updatedAt
      categorySubcategoriesId
    }
  }
`;
export const deleteSubcategory = /* GraphQL */ `
  mutation DeleteSubcategory(
    $input: DeleteSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    deleteSubcategory(input: $input, condition: $condition) {
      id
      name
      category {
        id
        name
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          campaignType
          campaignLink
          godLevel
          backers
          year
          raised
          price
          description
          type
          number
          createdAt
          updatedAt
          subcategoryAudiencesId
        }
        nextToken
      }
      createdAt
      updatedAt
      categorySubcategoriesId
    }
  }
`;
export const createCharacteristic = /* GraphQL */ `
  mutation CreateCharacteristic(
    $input: CreateCharacteristicInput!
    $condition: ModelCharacteristicConditionInput
  ) {
    createCharacteristic(input: $input, condition: $condition) {
      id
      name
      audiences {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCharacteristic = /* GraphQL */ `
  mutation UpdateCharacteristic(
    $input: UpdateCharacteristicInput!
    $condition: ModelCharacteristicConditionInput
  ) {
    updateCharacteristic(input: $input, condition: $condition) {
      id
      name
      audiences {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharacteristic = /* GraphQL */ `
  mutation DeleteCharacteristic(
    $input: DeleteCharacteristicInput!
    $condition: ModelCharacteristicConditionInput
  ) {
    deleteCharacteristic(input: $input, condition: $condition) {
      id
      name
      audiences {
        items {
          id
          audienceID
          characteristicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCharacteristicAudiences = /* GraphQL */ `
  mutation CreateCharacteristicAudiences(
    $input: CreateCharacteristicAudiencesInput!
    $condition: ModelCharacteristicAudiencesConditionInput
  ) {
    createCharacteristicAudiences(input: $input, condition: $condition) {
      id
      audienceID
      characteristicID
      audience {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      characteristic {
        id
        name
        audiences {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCharacteristicAudiences = /* GraphQL */ `
  mutation UpdateCharacteristicAudiences(
    $input: UpdateCharacteristicAudiencesInput!
    $condition: ModelCharacteristicAudiencesConditionInput
  ) {
    updateCharacteristicAudiences(input: $input, condition: $condition) {
      id
      audienceID
      characteristicID
      audience {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      characteristic {
        id
        name
        audiences {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharacteristicAudiences = /* GraphQL */ `
  mutation DeleteCharacteristicAudiences(
    $input: DeleteCharacteristicAudiencesInput!
    $condition: ModelCharacteristicAudiencesConditionInput
  ) {
    deleteCharacteristicAudiences(input: $input, condition: $condition) {
      id
      audienceID
      characteristicID
      audience {
        id
        name
        campaignType
        campaignLink
        godLevel
        backers
        year
        raised
        price
        description
        type
        number
        file {
          bucket
          region
          key
        }
        subcategory {
          id
          name
          createdAt
          updatedAt
          categorySubcategoriesId
        }
        characteristics {
          nextToken
        }
        createdAt
        updatedAt
        subcategoryAudiencesId
      }
      characteristic {
        id
        name
        audiences {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
