import { useEffect, useState } from "react";
import { off, on } from "utils/events";

export const useCustomEventListener = (eventName, callback, args) => {
  const [payload, setPayload] = useState(null);

  function setEvent({ detail: payload }) {
    setPayload(payload);
    if (callback) {
      Array.isArray(args) ? callback(payload, ...args) : callback(payload);
    }
  }

  useEffect(() => {
    on(eventName, setEvent);
    return () => {
      off(eventName, setEvent);
    };
  }, []);

  return {
    payload,
    setPayload,
  };
};
