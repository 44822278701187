import { Icon } from "@chakra-ui/react";

const NoSelectedIcon = (props) => (
  <Icon viewBox="0 0 92 87" {...props}>
    <path
      d="M48.2978 86.9218C72.1307 86.9218 91.451 67.6014 91.451 43.7685C91.451 19.9356 72.1307 0.615234 48.2978 0.615234C24.4649 0.615234 5.14453 19.9356 5.14453 43.7685C5.14453 67.6014 24.4649 86.9218 48.2978 86.9218Z"
      fill="#E2E8F0"
    />
    <path
      d="M87.7267 15.9754L38.1833 10.6086C36.8896 10.4677 35.7112 11.4155 35.5703 12.7092L28.9099 74.2158C28.769 75.5095 29.7168 76.6879 31.0105 76.8288L80.5539 82.1955C81.8476 82.3364 83.0259 81.3886 83.1668 80.0949L89.8273 18.5883C89.9682 17.2947 89.0203 16.1163 87.7267 15.9754Z"
      fill="#CBD5E0"
    />
    <path
      d="M78.8498 78.9441L26.7447 73.2955C26.1555 73.2314 25.72 72.6935 25.7841 72.1043L32.7263 8.03598C32.7904 7.44679 33.3283 7.0113 33.9175 7.07534L86.0226 12.7239C86.6118 12.788 87.0473 13.3259 86.9833 13.9151L80.041 77.9834C79.977 78.5726 79.439 79.0081 78.8498 78.9441Z"
      fill="white"
    />
    <path
      d="M55.2692 18.0522L38.0673 16.1949C37.8496 16.1693 37.683 15.9644 37.7087 15.7466L37.952 13.4411C37.9776 13.2233 38.1826 13.0568 38.4003 13.0824L55.6022 14.9397C55.8199 14.9653 55.9864 15.1702 55.9608 15.388L55.7175 17.6935C55.7047 17.9113 55.4997 18.0778 55.2692 18.0522Z"
      fill="#E2E8F0"
    />
    <path
      d="M79.2466 27.761L37.3114 23.214C37.0937 23.1883 36.9272 22.9834 36.9528 22.7657L37.1962 20.4601C37.2218 20.2424 37.4267 20.0759 37.6445 20.1015L79.5796 24.6485C79.7974 24.6741 79.9639 24.8791 79.9383 25.0968L79.6949 27.4023C79.6693 27.6201 79.4643 27.7866 79.2466 27.761Z"
      fill="#E2E8F0"
    />
    <path
      d="M78.4912 34.7927L36.5561 30.2457C36.3383 30.2201 36.1718 30.0151 36.1974 29.7974L36.4408 27.4919C36.4664 27.2741 36.6713 27.1076 36.8891 27.1332L78.8242 31.6803C79.042 31.7059 79.2085 31.9108 79.1829 32.1286L78.9395 34.4341C78.9139 34.6518 78.709 34.8055 78.4912 34.7927Z"
      fill="#E2E8F0"
    />
    <path
      d="M77.7227 41.8118L35.7875 37.2647C35.5698 37.2391 35.4033 37.0342 35.4289 36.8164L35.6722 34.5109C35.6979 34.2932 35.9028 34.1266 36.1205 34.1523L78.0557 38.6993C78.2734 38.7249 78.4399 38.9298 78.4143 39.1476L78.171 41.4531C78.1453 41.6837 77.9404 41.8374 77.7227 41.8118Z"
      fill="#E2E8F0"
    />
    <path
      d="M76.9668 48.8438L35.0317 44.2967C34.8139 44.2711 34.6474 44.0662 34.673 43.8484L34.9164 41.5429C34.942 41.3251 35.1469 41.1586 35.3647 41.1842L77.2998 45.7313C77.5176 45.7569 77.6841 45.9618 77.6585 46.1796L77.4151 48.4851C77.3895 48.7029 77.1846 48.8694 76.9668 48.8438Z"
      fill="#E2E8F0"
    />
    <path
      d="M76.1985 55.8759L45.7782 52.5841C45.5605 52.5585 45.394 52.3535 45.4196 52.1358L45.663 49.8302C45.6886 49.6125 45.8935 49.446 46.1113 49.4716L76.5315 52.7634C76.7493 52.789 76.9158 52.9939 76.8902 53.2117L76.6468 55.5172C76.6212 55.735 76.4291 55.9015 76.1985 55.8759Z"
      fill="#E2E8F0"
    />
    <path
      d="M75.4424 62.9077L33.5072 58.3607C33.2895 58.3351 33.123 58.1301 33.1486 57.9124L33.392 55.6068C33.4176 55.3891 33.6225 55.2226 33.8403 55.2482L75.7754 59.7952C75.9932 59.8209 76.1597 60.0258 76.1341 60.2435L75.8907 62.5491C75.8651 62.7668 75.6601 62.9205 75.4424 62.9077Z"
      fill="#E2E8F0"
    />
    <path
      d="M74.6743 69.9268L32.7392 65.3797C32.5214 65.3541 32.3549 65.1492 32.3805 64.9314L32.6239 62.6259C32.6495 62.4081 32.8545 62.2416 33.0722 62.2673L75.0073 66.8143C75.2251 66.8399 75.3916 67.0448 75.366 67.2626L75.1226 69.5681C75.097 69.7987 74.9049 69.9524 74.6743 69.9268Z"
      fill="#E2E8F0"
    />
    <path
      opacity="0.2"
      d="M43.3637 21.5782C36.2934 15.7247 25.816 16.7237 19.9625 23.7941C14.6086 30.2624 14.98 39.5742 20.5005 45.5942L17.8748 48.7707L16.7988 47.8869L2.58136 65.076C1.8897 65.9085 2.00497 67.151 2.83753 67.8554L5.5017 70.0457C6.33426 70.7374 7.57669 70.6221 8.28116 69.7895L22.4986 52.6005L21.4227 51.7167L24.0485 48.5401C30.9907 52.831 40.2257 51.4605 45.5668 44.9922C51.4203 37.9091 50.4341 27.4317 43.3637 21.5782ZM43.2485 43.0709C38.4581 48.8604 29.8764 49.6673 24.0869 44.8769C18.2974 40.0865 17.4905 31.5048 22.2681 25.7153C27.0585 19.9259 35.6402 19.1061 41.4297 23.8965C47.2319 28.6997 48.0517 37.2814 43.2485 43.0709Z"
      fill="#A0AEC0"
    />
    <path
      opacity="0.2"
      d="M42.2088 42.9925C47.9311 37.2702 47.9311 27.9925 42.2088 22.2702C36.4865 16.5479 27.2089 16.5479 21.4866 22.2702C15.7643 27.9925 15.7643 37.2702 21.4866 42.9925C27.2089 48.7147 36.4865 48.7147 42.2088 42.9925Z"
      fill="#A0AEC0"
    />
    <path
      opacity="0.6"
      d="M31.887 43.609C31.887 43.5834 31.8742 43.545 31.8742 43.5193C31.8486 43.1607 32.1303 42.8533 32.489 42.8277C32.758 42.8149 33.0141 42.7893 33.2831 42.7508C38.8548 41.9567 42.7486 36.7821 41.9673 31.2103C41.8392 30.3009 41.5831 29.4043 41.2244 28.5718C41.0835 28.2388 41.2372 27.8545 41.5702 27.7136C41.9033 27.5727 42.2875 27.7264 42.4284 28.0594C42.8383 29.0073 43.1201 30.0063 43.2738 31.0438C44.1576 37.3328 39.7642 43.1735 33.4752 44.0701C33.1806 44.1085 32.8732 44.147 32.5786 44.1598C32.2328 44.1598 31.951 43.9292 31.887 43.609Z"
      fill="white"
    />
    <path
      d="M23.8486 45.9111L20.3057 42.9797L16.3863 47.7168L19.9292 50.6482L23.8486 45.9111Z"
      fill="#718096"
    />
    <path
      d="M42.4414 19.8235C35.3711 13.97 24.8937 14.9691 19.0402 22.0394C13.1867 29.1097 14.1729 39.5871 21.2433 45.4406C28.3136 51.2941 38.791 50.3079 44.6445 43.2375C50.498 36.1544 49.5117 25.677 42.4414 19.8235ZM42.3389 41.3163C37.5485 47.1057 28.9668 47.9127 23.1773 43.1223C17.3879 38.3319 16.5809 29.7502 21.3585 23.9607C26.1489 18.1712 34.7306 17.3515 40.5201 22.1419C46.3224 26.9323 47.1293 35.514 42.3389 41.3163Z"
      fill="#718096"
    />
    <path
      d="M5.39918 68.9697L1.09552 65.4089C0.71126 65.0887 0.660026 64.5251 0.980239 64.1409L15.8894 46.1321L21.5764 50.8328L6.66723 68.8544C6.34702 69.2387 5.78344 69.2899 5.39918 68.9697Z"
      fill="#718096"
    />
  </Icon>
);

export default NoSelectedIcon;
