import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loading from "components/Loading";
import Home from "./Home";
import Login from "./Login";
import Layout from "./Layout";
import { useUser } from "hooks/user";
import AudienceDB from "./AudienceDB";
import GodLevel from "./GodLevel";
import Lookalike from "./Lookalike";
import AudienceManager from "./AudienceManager";
import AudiencesToOneAdSetSection from "./AudienceManager/AudiencesToOneAdSetSection";
import AudiencesToNAdSetsSection from "./AudienceManager/AudiencesToNAdSetsSection";
import TrackerSection from "./AudienceManager/AudiencesToNAdSetsSection/TrackerSection";
import SummarySection from "./AudienceManager/AudiencesToNAdSetsSection/SummarySection";
import UsageLog from "./UsageLog";
import { USAGE_LOG_ALLOWED_USERS } from "../utils/constants";
import DBManager from "./DBManager";
import {ListForm} from "./DBManager/ListForm";
import {ListAttributes} from "./DBManager/ListAttributes";

export const RouterProvider = BrowserRouter;

export const Router = () => {
  const { data: user, isLoading: isUserLoading } = useUser();

  if (isUserLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      {user && (
        <>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/audience-db" element={<AudienceDB />} />
            <Route path="db-manager">
              <Route index path="/db-manager" element={<DBManager />} />
              <Route path="create" element={<ListForm />} />
              <Route path="attributes" element={<ListAttributes />} />
            </Route>
            <Route path="/god-level" element={<GodLevel />} />
            <Route path="/lookalike" element={<Lookalike />} />
            <Route path="/audience-manager">
              <Route index element={<AudienceManager />} />
              <Route
                path="audiences-to-1-adset"
                element={<AudiencesToOneAdSetSection />}
              />
              <Route path="audiences-to-n-adsets">
                <Route index element={<AudiencesToNAdSetsSection />} />
                <Route path="tracker" element={<TrackerSection />} />
                <Route path="summary" element={<SummarySection />} />
              </Route>
            </Route>
            {USAGE_LOG_ALLOWED_USERS.includes(user.username) && (
                <>
                  <Route path="/usage-log" element={<UsageLog />}  />
                </>
            )}
          </Route>
        </>
      )}
      {!user && (
        <>
          <Route path="/login" element={<Login />} />
        </>
      )}
      <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
    </Routes>
  );
};
