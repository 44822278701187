export const FB_API_GRAPH_URL = process.env.REACT_APP_FB_API_GRAPH_URL;
export const AMPLIFY_API = process.env.REACT_APP_AMPLIFY_API;
export const AMPLIFY_AUDIENCE_CREATION_API = process.env.REACT_APP_AMPLIFY_AUDIENCE_CREATION_API;
export const AMPLIFY_USAGE_LOG_CREATION_API = 'https://y4xjacef2wspjg552bd5cika6e0bcynv.lambda-url.us-east-2.on.aws/';

export const GOD_LEVEL_API = process.env.REACT_APP_GOD_LEVEL_API;

export const GOD_LEVEL_WIDTHS = {
  nameWidth: "20%",
  scoreWidth: "30%",
  sizeWidth: "20%",
  actionWidth: "30%",
};

export const USAGE_LOG_ALLOWED_USERS = ['facebook_6699124740121334', 'facebook_6053616748080542', 'facebook_276978855016947', 'facebook_2827281257402677'];
export const DB_MANAGEMENT_ALLOWED_USERS = ['facebook_6699124740121334', 'facebook_6053616748080542', 'facebook_276978855016947', 'facebook_2827281257402677'];

export const COUNTRY_DATA_LIST = [
  {
    name: "worldwide",
    list: [
      "DZ",
      "AO",
      "BJ",
      "BW",
      "BF",
      "BI",
      "CM",
      "CV",
      "CF",
      "TD",
      "KM",
      "CI",
      "CD",
      "DJ",
      "EG",
      "GQ",
      "ER",
      "ET",
      "GA",
      "GH",
      "GN",
      "GW",
      "KE",
      "LS",
      "LR",
      "LY",
      "MG",
      "MW",
      "ML",
      "MR",
      "MU",
      "YT",
      "MA",
      "MZ",
      "NA",
      "NE",
      "NG",
      "CG",
      "RE",
      "RW",
      "SH",
      "ST",
      "SN",
      "SC",
      "SL",
      "SO",
      "ZA",
      "SS",
      "SZ",
      "TZ",
      "GM",
      "TG",
      "TN",
      "UG",
      "EH",
      "ZM",
      "ZW",
      "AF",
      "AM",
      "AZ",
      "BH",
      "BD",
      "BT",
      "BN",
      "KH",
      "CN",
      "CY",
      "GE",
      "HK",
      "IN",
      "ID",
      "IQ",
      "IL",
      "JP",
      "JO",
      "KZ",
      "KW",
      "KG",
      "LA",
      "LB",
      "MO",
      "MY",
      "MV",
      "MN",
      "MM",
      "NP",
      "OM",
      "PK",
      "PS",
      "PH",
      "QA",
      "SA",
      "SG",
      "KR",
      "LK",
      "TW",
      "TJ",
      "TH",
      "TL",
      "TR",
      "TM",
      "AE",
      "UZ",
      "VN",
      "YE",
      "AG",
      "AW",
      "BB",
      "VG",
      "KY",
      "CW",
      "DM",
      "DO",
      "GD",
      "GP",
      "HT",
      "JM",
      "MQ",
      "MS",
      "PR",
      "KN",
      "MF",
      "VC",
      "SX",
      "LC",
      "BS",
      "TT",
      "TC",
      "VI",
      "BZ",
      "CR",
      "SV",
      "GT",
      "HN",
      "NI",
      "PA",
      "AL",
      "AD",
      "AT",
      "BY",
      "BE",
      "BA",
      "BG",
      "HR",
      "CZ",
      "DK",
      "EE",
      "FO",
      "FI",
      "FR",
      "DE",
      "GI",
      "GR",
      "GG",
      "HU",
      "IS",
      "IE",
      "IM",
      "IT",
      "JE",
      "XK",
      "LV",
      "LI",
      "LT",
      "LU",
      "MK",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "RS",
      "SK",
      "SI",
      "ES",
      "SJ",
      "SE",
      "CH",
      "UA",
      "GB",
      "BM",
      "CA",
      "GL",
      "MX",
      "PM",
      "US",
      "AS",
      "AU",
      "CK",
      "FM",
      "FJ",
      "PF",
      "GU",
      "KI",
      "MH",
      "NR",
      "NC",
      "NZ",
      "NU",
      "NF",
      "MP",
      "PW",
      "PG",
      "PN",
      "WS",
      "SB",
      "TK",
      "TO",
      "TV",
      "VU",
      "WF",
      "AR",
      "BO",
      "BR",
      "CL",
      "CO",
      "EC",
      "FK",
      "GF",
      "GY",
      "PY",
      "PE",
      "SR",
      "UY",
      "VE",
    ],
  },
  {
    name: "europe",
    list: [
      "AL",
      "AD",
      "AT",
      "BY",
      "BE",
      "BA",
      "BG",
      "HR",
      "CZ",
      "DK",
      "EE",
      "FO",
      "FI",
      "FR",
      "DE",
      "GI",
      "GR",
      "GG",
      "HU",
      "IS",
      "IE",
      "IM",
      "IT",
      "JE",
      "XK",
      "LV",
      "LI",
      "LT",
      "LU",
      "MK",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "RS",
      "SK",
      "SI",
      "ES",
      "SJ",
      "SE",
      "CH",
      "UA",
      "GB",
    ],
  },
  {
    name: "asia",
    list: [
      "AF",
      "AM",
      "AZ",
      "BH",
      "BD",
      "BT",
      "BN",
      "KH",
      "CN",
      "CY",
      "GE",
      "HK",
      "IN",
      "ID",
      "IQ",
      "IL",
      "JP",
      "JO",
      "KZ",
      "KW",
      "KG",
      "LA",
      "LB",
      "MO",
      "MY",
      "MV",
      "MN",
      "MM",
      "NP",
      "OM",
      "PK",
      "PS",
      "PH",
      "QA",
      "SA",
      "SG",
      "KR",
      "LK",
      "TW",
      "TJ",
      "TH",
      "TL",
      "TR",
      "TM",
      "AE",
      "UZ",
      "VN",
      "YE",
    ],
  },
  {
    name: "us",
    list: ["US"],
  },
  {
    name: "gb",
    list: ["GB"],
  },
  {
    name: "ca",
    list: ["CA"],
  },
  {
    name: "au",
    list: ["AU"],
  },
  {
    name: "sg",
    list: ["SG"],
  },
  {
    name: "de",
    list: ["DE"],
  },
  {
    name: "nl",
    list: ["NL"],
  },
  {
    name: "fr",
    list: ["FR"],
  },
  {
    name: "it",
    list: ["IT"],
  },
  {
    name: "be",
    list: ["BE"],
  },
  {
    name: "jp",
    list: ["JP"],
  }
];

export const LINKS = {
  TCF_TRACKER: "tcfTracker",
  KS_REFERRAL: "ksReferral",
  INDIEGOGO: "indiegogo",
};

export const SELECT_FILTERS = {
  characteristics: [
    { value: "Smart", name: "Smart" },
    { value: "Sustainable", name: "Sustainable" },
    { value: "Wellbeing", name: "Wellbeing" },
    { value: "Robotics", name: "Robotics" },
    { value: "Portable", name: "Portable" },
    { value: "Gadgets", name: "Gadgets" },
    { value: "Travel", name: "Travel" },
    { value: "DIY", name: "DIY" },
    { value: "Mystery", name: "Mystery" },
    { value: "3D-printed", name: "3D-printed" },
    { value: "Weight loss", name: "Weight loss" },
    { value: "Digital", name: "Digital" },
    { value: "Wearable", name: "Wearable" },
    { value: "IOT", name: "IOT" },
    { value: "Productivity", name: "Productivity" },
    { value: "Retro", name: "Retro" },
    { value: "Alcohol", name: "Alcohol" },
    { value: "Wireless", name: "Wireless" },
    { value: "Eywear", name: "Eywear" },
    { value: "Organizers", name: "Organizers" },
  ],
  campaignType: [
    { value: "KSS", name: "Kickstarter" },
    { value: "IGG", name: "Indiegogo" },
    { value: "Ecommerce", name: "Ecommerce" },
    { value: "Unknown", name: "Unknown" },
  ],
  price: [
    { value: "<100", name: "<100" },
    { value: "101-250", name: "101-250" },
    { value: "251-500", name: "251-500" },
    { value: "501-1000", name: "501-1000" },
    { value: "1001>", name: "1001+" },
  ],
  year: [
    ...[...Array(new Date().getFullYear() - 2011).keys()].map((v) => ({
      value: v + 2012,
      name: v + 2012,
    })),
  ],
  // deliveryStatus: [
  //   { value: 200, name: "Active" },
  //   { value: 300, name: "Too small" },
  //   { value: 400, name: "Not usable" }
  // ]
};
