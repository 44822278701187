import { Flex, Text, Switch } from "@chakra-ui/react";
import { useAudience } from "contexts/AudienceContext";
import { Link } from "react-router-dom";
import { useState } from "react";
import FbAdsButton from "./FbAdsButton";

function SendToAds() {
  const { numberOfSelected: numberOfLists } = useAudience();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Flex height="full" direction="column" p={6}>
      <Text mb={4} fontWeight="600">
        {numberOfLists} {numberOfLists > 1 ? "lists" : "list"} selected
      </Text>
      <Flex alignItems="center" mb={9}>
        1 AdSet
        <Switch
          onChange={(e) => setIsChecked(e.target.checked)}
          size="sm"
          mx={3}
        />
        N AdSets
      </Flex>
      {numberOfLists === 0 ? (
        <FbAdsButton disabled />
      ) : (
        <Link to={isChecked ? "audiences-to-n-adsets" : "audiences-to-1-adset"}>
          <FbAdsButton />
        </Link>
      )}
    </Flex>
  );
}

export default SendToAds;
