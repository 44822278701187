import {useEffect, useMemo, useRef, useState} from "react";
import { useCustomEventListener } from "utils/onCustomEventHook";
import { Flex, Spinner } from "@chakra-ui/react";
import { useGetCustomAudiences } from "hooks/fbApi";
import { useAudience } from "contexts/AudienceContext";
import { useAccount } from "contexts/AccountContext";
import Loading from "components/Loading";
import ScrollbarFlex from "components/ScrollbarFlex";
import NoSelectedDisplay from "components/NoSelectedDisplay";
import CheckboxDisplay from "components/CheckboxDisplay";
import { useGetAudiences } from "hooks/audience";
import { useSubcategoriesSelect } from "utils/useSubcategoriesSelect";
import filterStore from "stores/filterStore";
import generateSubcategoryNumbers from "utils/generateSubcategoryNumbers";
import helperStore from "stores/helperStore";

function AudienceSelect({
  tabIndex,
  checkedItems,
  handleSingleCheck,
  handleAllCheck,
  searchKeyword,
  sortParam,
  sortDir,
  triggerAudsRefetch
}) {
  const { setAudiences, setNumberOfLoadedAudiences } = useAudience();
  const { account, setAccount, setBusiness } = useAccount();
  const {
    data: customAudiences,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    hasNextPage,
    refetch
  } = useGetCustomAudiences(account?.id, {
    enabled: Boolean(account?.id),
    name: "fbaudience-select",
  });

  const { data: dbAuds } = useGetAudiences();
  const filtersState = filterStore.useStore();
  const deferredSelected = useSubcategoriesSelect();

  const dbCustomAuds = useRef([]);


  const audsWithInfo = useMemo(() => {
    function addFilteringInfo(data, dbAuds) {
      dbCustomAuds.current = [];
      return data?.map((d) => {
        const idx = dbAuds?.findIndex((aud) => d.name === aud.name + "/fbApi");
        if (idx >= 0) {
          const item = { ...dbAuds[idx], id: d.id, subtype: d.subtype };
          dbCustomAuds.current.push(item);
          return item;
        } else {
          return d;
        }
      });
    }

    function sortAuds(fbAuds) {
      switch (sortParam) {
        case 'name':
          return fbAuds.sort((a,b) => {
            if (sortDir === 'asc') {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
            }
            if (sortDir === 'desc') {
              if (a.name > b.name) return -1;
              if (a.name < b.name) return 1;
            }
            return 1;
          });
          break;
        case 'created':
          return fbAuds?.sort((a,b) => {
            return sortDir === 'asc' ? a.time_created - b.time_created : b.time_created - a.time_created;
          });
          break;
        case 'type':
          return fbAuds?.sort((a,b) => {
            if (sortDir === 'asc') {
              if (a.subtype < b.subtype) return -1;
              if (a.subtype > b.subtype) return 1;
            }
            if (sortDir === 'desc') {
              if (a.subtype > b.subtype) return -1;
              if (a.subtype < b.subtype) return 1;
            }
            return 1;
          });
          break;
        default:
          return fbAuds?.sort((a,b) => {
            return sortDir === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
      }
    }

    const auds = customAudiences?.pages.reduce(
      (prevValue, currentValue) => [...prevValue, ...currentValue.data.data],
      []
    );


    const audsWithInfo = addFilteringInfo(auds, dbAuds);

    return sortAuds(audsWithInfo);
  }, [customAudiences, dbAuds, sortParam, sortDir]);

  const { payload: isChecked } = useCustomEventListener(
    "customAudience:selectAll"
  );

  useEffect(() => {
    if (audsWithInfo && dbCustomAuds.current.length > 0) {
      helperStore.setState((prevState) => ({
        ...prevState,
        subcategoryByAudienceNumbers: generateSubcategoryNumbers(
          dbCustomAuds.current
        ),
      }));
    }
  }, [audsWithInfo]);

  useEffect(() => {
    const audiences = customAudiences?.pages.reduce(
      (prevValue, currentValue) => [...prevValue, ...currentValue.data.data],
      []
    );
    handleAllCheck(isChecked, audiences);
  }, [isChecked]);

  useEffect(() => {
    if (customAudiences?.pages.length > 0) {
      const audLength = customAudiences.pages.reduce(
        (prevValue, currentValue) => {
          return prevValue + currentValue.data.data.length;
        },
        0
      );
      setNumberOfLoadedAudiences(audLength);
    }
  }, [customAudiences]);

  useEffect(() => {
    setAudiences(checkedItems);
  }, [checkedItems]);

  useEffect(() => {
    if (triggerAudsRefetch) {
      refetch();
    }
  }, [triggerAudsRefetch]);


  function handleAudienceScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 20) {
      !isFetchingNextPage && hasNextPage && fetchNextPage();
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return !isFetchingNextPage && isFetching ? (
    <Loading />
  ) : customAudiences ? (
    <ScrollbarFlex
      direction="column"
      onScroll={handleAudienceScroll}
      overflowY="auto"
      overflowX="hidden"
      spacing={4}
      align="stretch"
      pt={2}
      color="gray.900"
      backgroundColor="blue.50"
      width="full"
      pl={2}
    >
      <CheckboxDisplay
        tabIndex={tabIndex}
        audiences={audsWithInfo}
        searchKeyword={searchKeyword}
        checkedItems={checkedItems}
        handleSingleCheck={handleSingleCheck}
        selectedSubcategoryIds={deferredSelected}
        filtersState={filtersState}
      />

      <Flex p={2} align="center" justify="center">
        {isFetchingNextPage && <Spinner />}
      </Flex>
    </ScrollbarFlex>
  ) : (
    <NoSelectedDisplay />
  );
}

export default AudienceSelect;
