import { useState, useRef } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import {
  useDownloadAudience,
  useFindAudiences,
  useGetStatistics,
  useUploadAudience,
} from "hooks/godLevel";
import BackersInfo from "./BackersInfo";
import FindAudiencesForm from "./FindAudiencesForm";
import GeneratedLists from "./GeneratedLists";
import Toast from "components/Toast";
import { UsageLogService } from "../../services/usageLog";
import {useUser} from "../../hooks/user";

function GodLevel() {
  const [generatedLists, setGeneratedLists] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    threshold: 50,
  });

  const { mutate: findAudiences, isLoading } = useFindAudiences();
  const { mutate: downloadAudience } = useDownloadAudience();
  const { mutate: uploadAudience } = useUploadAudience();
  const { data: statistics, isLoading: isStatisticsLoading } =
    useGetStatistics();

  const toast = useToast();

  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();

  const { data: user } = useUser();

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  const handleSubmit = () => {
    const data = { ...formData, threshold: formData.threshold / 100 };

    findAudiences(data, {
      onSuccess: (resp) => {
        const respData = resp.data;
        setGeneratedLists(respData);

        const usageLogInput = {
          "username": user.username,
          "audienceName": respData.map((list) => { return list[0] }).join(","),
          "accountName": "",
          "businessName": "No ad account",
          "numberOfUsers": respData.length,
          "type": "CreateML"
        };
        try {
          UsageLogService({}).logToDB(usageLogInput);
        } catch($error) {

        }
      },
      onError: (err) => {
        console.err({ err });
      },
    });
  };

  async function handleDownload(path) {
    downloadAudience(
      { path },
      {
        onSuccess: (resp) => {
          const csvURL = URL.createObjectURL(
            new Blob([resp], { type: "text/csv" })
          );

          const anchor = document.createElement("a");
          anchor.href = csvURL;
          anchor.download = path.split("/")[2];

          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(csvURL);
        },
        onError: (err) => {
          console.err({ err });
        },
      }
    );
  }

  async function handleUpload(path) {
    uploadAudience(
      { path },
      {
        onSuccess: (resp) => {
          toastSuccessRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Succeed"
                text={`${resp.data.message}`}
                onClick={() => closeToast(toastSuccessRef)}
              />
            ),
          });
        },
        onError: (err) => {
          toastErrorRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Error"
                type="error"
                text={err?.message}
                onClick={() => closeToast(toastErrorRef)}
              />
            ),
          });
        },
      }
    );
  }

  return (
    <Flex justifyContent="center">
      <Flex flexDir="column" alignItems="center" width="75%" mt={6}>
        <BackersInfo info={statistics?.data} isLoading={isStatisticsLoading} />
        <FindAudiencesForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          formData={formData}
          setFormData={setFormData}
        />
        {Object.keys(generatedLists).length > 0 && (
          <GeneratedLists
            lists={generatedLists}
            onDownload={handleDownload}
            onUpload={handleUpload}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default GodLevel;
