import AudienceSelect from "./AudienceSelect";
import LayoutWithSelectAll from "pages/AudienceDB/CustomAudienceSection/LayoutWithSelectAll";

function FBAudienceSelectSection() {
  return (
    <LayoutWithSelectAll>
      <AudienceSelect />
    </LayoutWithSelectAll>
  );
}

export default FBAudienceSelectSection;
