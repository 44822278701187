import Audiences from "./Audiences";
import LayoutWithSelectAll from "./LayoutWithSelectAll";

function CustomAudienceSection() {
  return (
    <LayoutWithSelectAll hasFooter>
      <Audiences />
    </LayoutWithSelectAll>
  );
}

export default CustomAudienceSection;
