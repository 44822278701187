import axios from "axios";

export function TCFTrackerService() {
  const getUrlsByGroupId = async (groupId) => {
    const response = await axios({
      url: `https://tcftracker.com/api/v1/landing-uris?filter[bulkId]=${groupId}`,
    });

    return response.data.data;
  };

  return {
    getUrlsByGroupId,
  };
}
