import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button, Flex, IconButton, Input, InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Text, Textarea, useToast
} from "@chakra-ui/react";
import ScrollbarFlex from "../../components/ScrollbarFlex";
import CloseIcon from "../../components/icons/CloseIcon";
import { useNavigate } from "react-router-dom";
import { useAmplify } from "contexts/AmplifyContext";
import { AudienceService } from "../../services/audience";
import Toast from "../../components/Toast";

function ActionsSection({ checkedLists, onSelectList, onMergeLists, listType }) {
    const [mergeButtonIsDisabled, setMergeButtonIsDisabled] = useState(true);
    const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalBackers, setTotalBackers] = useState(0);
    const navigate = useNavigate();
    const fileUpload = React.createRef();
    const { API, graphqlOperation } = useAmplify();
    const [mergedListName, setMergedListName] = useState('');
    const [mergedListDesc, setMergedListDesc] = useState('');
    const toast = useToast();
    const toastSuccessRef = useRef();
    const toastErrorRef = useRef();


    useEffect(() => {
        const backers = checkedLists.reduce((sum, currentList) => sum + currentList.backers, 0)
        setTotalBackers(backers)

        if (checkedLists.length > 1) {
            setMergeButtonIsDisabled(false);
        } else {
            setMergeButtonIsDisabled(true);
        }
    }, [checkedLists]);

    const openMergeModal = () => {
        setMergeModalIsOpen(true);
    }
    const onMergeModalClose = () => {
        setMergeModalIsOpen(false);
    }

    function closeToast(ref) {
        if (ref.current) {
            toast.close(ref.current);
        }
    }

    const handleMerge = () => {
        setIsLoading(true);
        AudienceService({ API, graphqlOperation }).mergeLists({name: mergedListName, description: mergedListDesc, lists: checkedLists}).then((res) => {
            setIsLoading(false);
            if (res.merge.error) {
                toastErrorRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Merge error"
                            type="error"
                            text={res.merge.error}
                            onClick={() => closeToast(toastErrorRef)}
                        />
                    ),
                });
            } else {
                toastSuccessRef.current = toast({
                    duration: null,
                    position: "top",
                    render: () => (
                        <Toast
                            title="Succeed"
                            text={`${checkedLists.length} lists are merged successfully`}
                            onClick={() => closeToast(toastSuccessRef)}
                        />
                    ),
                });
                onMergeModalClose();
                // onSelectList([]);
                onMergeLists();
                setMergedListName('');
                setMergedListDesc('');
            }
        });
    }

    const addListClickHandle = () => {
        navigate('create');
    }

    const handleFileInputClick = () => {
        fileUpload.current?.click()
    }

    const handleFileSelect = (e) => {
        const files = e.target.files;
        const invalidFiles = [];
        Array.from(files).forEach((file) => {
            if (!file.name.endsWith('.csv')) {
                invalidFiles.push(file);
            }
        });

        if (invalidFiles.length) {
            e.target.value = '';
            alert('Only CSV files allowed');
        } else {
            navigate('create', {state: {files}})
        }
    }

    return (
        <>

            <Flex>
                <Button isDisabled={mergeButtonIsDisabled} onClick={openMergeModal}>Merge</Button>
                <InputGroup ml={3}>
                    <Button isDisabled={listType === 'merged'} onClick={handleFileInputClick}>Upload</Button>
                    <input
                        hidden
                        type="file"
                        accept=".csv"
                        multiple={true}
                        ref={fileUpload}
                        onChange={handleFileSelect}
                    />
                </InputGroup>
            </Flex>

            <Modal isOpen={mergeModalIsOpen} onClose={onMergeModalClose}>
                <ModalOverlay />
                <ModalContent maxW="575px">
                    <ModalHeader
                        pt={5}
                        pl={6}
                        pr={9}
                        pb={3}
                        fontSize="18px"
                        lineHeight="28px"
                    >
                        Merge lists
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder="Merged list name"
                            onChange={(e) => {setMergedListName(e.target.value)}}
                            value={mergedListName}
                        ></Input>
                        <Textarea
                            placeholder="Merged list description"
                            mt={3}
                            mb={3}
                            rows={5}
                            onChange={(e) => {setMergedListDesc(e.target.value)}}
                            value={mergedListDesc}
                        />
                        <ScrollbarFlex
                            direction="column"
                            h="full"
                            overflowY="scroll"
                            align="stretch"
                            border="1px"
                            borderColor="gray.200"
                            borderRadius={2}
                            p={4}
                            pt={2}
                            maxH="250px"
                        >
                            {checkedLists?.map((list) => (
                                <Box p={2} pl={0} pr={3} key={list.id}>
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Flex justifyContent="space-between" width="80%">
                                            <Text mr={2}>{list.name}</Text>
                                            <Text>{list.backers ?? 'N/A'}</Text>
                                        </Flex>
                                        {checkedLists.length > 2 &&
                                            <IconButton
                                                aria-label=""
                                                background="transparent"
                                                icon={<CloseIcon boxSize="14px" color="red.500" />}
                                                onClick={() => onSelectList(checkedLists.filter((slist) => slist.id !== list.id))}
                                            ></IconButton>
                                        }
                                    </Flex>
                                </Box>
                            ))}
                        </ScrollbarFlex>
                        <Flex justifyContent="space-around">
                            <Text as="b">Number of lists: {checkedLists.length}</Text>
                            <Text as="b">Total backers: {totalBackers}</Text>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            bg="white"
                            _hover={{
                                background: "white",
                            }}
                            _active={{
                                background: "white",
                            }}
                            onClick={onMergeModalClose}
                            mr={3}
                            color="gray.700"
                            fontWeight="600"
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={isLoading}
                            color="white"
                            bg="gray.700"
                            _hover={{
                                background: "gray.800",
                            }}
                            fontWeight="600"
                            onClick={handleMerge}
                        >
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ActionsSection;