import { useSocialSignIn } from "hooks/auth";
import LoginButton from "./LoginButton";

function Login() {
  const { mutate: socialSignIn, isSuccess } = useSocialSignIn();

  return <LoginButton socialSignIn={socialSignIn} isLoading={isSuccess} />;
}

export default Login;
