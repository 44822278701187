import { useMemo } from "react";
import { Box, Flex, chakra, useToast } from "@chakra-ui/react";
import ScrollbarFlex from "components/ScrollbarFlex";
import Toast from "components/Toast";
import { useAdSet } from "contexts/AdSetContext";
import { useAudience } from "contexts/AudienceContext";
import { useTCFTracker } from "contexts/TCFTrackerContext";
import normalizeAdTriples from "utils/normalizeAdTriples";
import { useAsyncSession } from "contexts/AsyncSessionContext";
import {
  useGetAdsetAsyncStatuses,
  useRenameDuplicatedAdSets,
} from "hooks/fbApi";
import { useEffect, useRef, useState } from "react";
import { useCampaign } from "contexts/CampaignContext";
import LoadingWithBg from "components/LoadingWithBg";

function SummaryPage() {
  const { adSets, setAdSets } = useAdSet();
  const { uriList } = useTCFTracker();
  const { audiences } = useAudience();
  const { campaign } = useCampaign();
  const { adSetAsyncIds } = useAsyncSession();

  const toast = useToast();
  const toastErrorRef = useRef();
  const [renamedAdSets, setRenamedAdSets] = useState([]);

  const { data: adSetAsyncStatuses } = useGetAdsetAsyncStatuses(adSetAsyncIds, {
    refetchInterval: (data) =>
      data?.some((d) => d?.data?.status === "IN_PROGRESS") ? 2000 : false,
  });

  const { mutate: renameDuplicatedAdSets, isLoading: isRenamingLoading } =
    useRenameDuplicatedAdSets();

  useEffect(() => {
    if (adSetAsyncStatuses?.length > 0) {
      if (adSetAsyncStatuses.some((st) => st.data.status === "FAILED")) {
        toastErrorRef.current = toast({
          duration: null,
          position: "top",
          render: () => (
            <Toast
              title="Error"
              type="error"
              text={"Adset duplication failed"}
              onClick={() => closeToast(toastErrorRef)}
            />
          ),
        });
      } else if (
        adSetAsyncStatuses.every((st) => st.data.status === "COMPLETED")
      ) {
        renameDuplicatedAdSets(
          { campaignId: campaign.id, adSetName: adSets[0].name },
          {
            onSuccess: (resp) => {
              setRenamedAdSets(
                resp.map(({ data }) => ({ id: data.id, name: data.name }))
              );
            },
            onError: (err) => {
              toastErrorRef.current = toast({
                duration: null,
                position: "top",
                render: () => (
                  <Toast
                    title="Error"
                    type="error"
                    text={err?.message || "Renaming failed"}
                    onClick={() => closeToast(toastErrorRef)}
                  />
                ),
              });
            },
          }
        );
      }
    }
  }, [adSetAsyncStatuses]);

  useEffect(() => {
    if (renamedAdSets.length > 0) {
      const newAdSets = [adSets[0], ...renamedAdSets];
      setAdSets(newAdSets);
    }
  }, [renamedAdSets]);

  const adTriples = useMemo(() => {
    if (renamedAdSets && uriList && audiences) {
      if (renamedAdSets.length > 0) {
        const newAdSets = [adSets[0], ...renamedAdSets];
        return normalizeAdTriples({
          adSets: newAdSets,
          uriList,
          audiences,
        });
      }
    }
  }, [renamedAdSets, uriList, audiences]);

  function renderList(triples = [], name) {
    return triples.map((triple) => (
      <Box pl={4} key={triple[name].id} height="100px">
        {triple[name].name}
      </Box>
    ));
  }

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  if (
    isRenamingLoading ||
    adSetAsyncStatuses?.some((st) => st.data.status === "IN_PROGRESS")
  ) {
    return (
      <LoadingWithBg text="The action in progress, it can take a few minutes, thank you for your patience !" />
    );
  }

  return (
    <Flex justifyContent="center" width="100%">
      <Flex
        width="95%"
        flexDir="column"
        border="1px"
        borderColor="gray.200"
        borderTopEndRadius={4}
        borderTopStartRadius={4}
      >
        <Flex>
          <Box
            width="40%"
            bg="gray.50"
            borderTopStartRadius={4}
            pl={4}
            py={1}
            mb={5}
            fontWeight="600"
            color="gray.700"
          >
            Link
          </Box>
          <Box
            width="30%"
            bg="gray.50"
            pl={4}
            py={1}
            mb={5}
            fontWeight="600"
            color="gray.700"
          >
            Ad Sets
          </Box>
          <Box
            width="30%"
            bg="gray.50"
            borderTopEndRadius={4}
            pl={4}
            py={1}
            mb={5}
            fontWeight="600"
            color="gray.700"
          >
            Lists
          </Box>
        </Flex>
        <ScrollbarFlex width="100%" overflowY="scroll" height="full">
          <Box width="40%">
            <chakra.span color="blue.500" as="u">
              {renderList(adTriples, "uri")}
            </chakra.span>
          </Box>
          <Box width="30%">{renderList(adTriples, "adSet")}</Box>
          <Box width="30%">{renderList(adTriples, "audience")}</Box>
        </ScrollbarFlex>
      </Flex>
    </Flex>
  );
}

export default SummaryPage;
