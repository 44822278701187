import { Box } from "@chakra-ui/react";
import CustomLink from "./CustomLink";
// import { DB_MANAGEMENT_ALLOWED_USERS } from "../../../utils/constants";
import { useUser } from "../../../hooks/user";

function NavSection() {
    const { data: user } = useUser();

    return (
    <>
        <Box>
            <CustomLink to="/god-level">ML</CustomLink>
        </Box>
        <Box>
            <CustomLink to="/lookalike">Lookalikes</CustomLink>
        </Box>
        <Box>
            <CustomLink to="/audience-manager">Audiences on FB</CustomLink>
        </Box>
        <Box>
            <CustomLink to="/audience-db">Database</CustomLink>
        </Box>
        {/*{DB_MANAGEMENT_ALLOWED_USERS.includes(user.username) &&*/}
        {/*    <Box>*/}
        {/*        <CustomLink to="/db-manager">DB Management</CustomLink>*/}
        {/*    </Box>*/}
        {/*}*/}
    </>
    );
}

export default NavSection;
