import audienceStore from "stores/audienceStore";
import LayoutWrapper from "./LayoutWrapper";

function LayoutWithSelectAll({ hasFooter, children }) {
  const selectedAudsState = audienceStore.useStore(
    (state) => state.selectedAuds
  );

  return (
    <LayoutWrapper
      checkedItems={selectedAudsState}
      setSelectedAuds={audienceStore.setState}
      hasFooter={hasFooter}
    >
      {children}
    </LayoutWrapper>
  );
}

export default LayoutWithSelectAll;
