import { Icon } from "@chakra-ui/react";

function DeleteIcon() {
    return (
        <Icon
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M4 7V21a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7M18 2H6a2 2 0 0 0-2 2v1h16V4a2 2 0 0 0-2-2zM14 11V17M10 11V17" />
        </Icon>
    );
}

export default DeleteIcon;