function normalizeAdTriples({ adSets, uriList, audiences }) {
  if (adSets && uriList && audiences) {
    const clonedUriList = [...uriList];

    return adSets?.map((adSet, i) => {
      const adSetSuffix =
        adSet.name.split("/")[adSet.name.split("/").length - 1];

      const uri = clonedUriList.find((uri) => uri.name.includes(adSetSuffix));

      const uriIndex = clonedUriList.indexOf(uri);

      if (uriIndex > -1) {
        clonedUriList.splice(uriIndex, 1);
      }
      return {
        adSet,
        uri: uri || clonedUriList.pop(),
        audience: audiences[i],
      };
    });
  }
}

export default normalizeAdTriples;
