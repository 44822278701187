import createStore from "./createStore";

const filterStore = createStore({
  characteristics: [],
  campaignType: "",
  price: "",
  year: "",
});

export default filterStore;
