import {
  Box,
  Button,
  Flex,
  Modal as MainModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import ScrollbarFlex from "components/ScrollbarFlex";
import { useCheckForDuplicates } from "hooks/audience";
import { useUser } from "hooks/user";
import { useAccount } from "contexts/AccountContext";
import { useEffect, useMemo, useState } from "react";
import CloseIcon from "components/icons/CloseIcon";

// TODO: this is a child component for CustomAudiences section but it has some business logic in it,
// let's discuss where we can move this because it's difficult to find, it should be somewhere top level
function Modal({
  isOpen,
  onClose,
  handleSend,
  audiences,
  isLoading,
  duplicates,
  setDuplicates,
}) {
  const { data: user } = useUser();
  const { account } = useAccount();
  const [highlightedAudiences, setHighlightedAudiences] = useState([]);

  const { mutate: checkForDuplicates, isLoading: isDuplicateCheckLoading } =
    useCheckForDuplicates();

  const audiencesWithoutDuplicate = useMemo(() => {
    return audiences.filter((aud) => !duplicates.includes(aud.name));
  }, [audiences, duplicates]);

  useEffect(() => {
    setHighlightedAudiences((prev) =>
      prev.filter((audName) => !duplicates.includes(audName))
    );
  }, [duplicates]);

  useEffect(() => {
    if (isOpen) {
      const token = user.attributes["custom:token"];
      const lambdaInput = {
        body: {
          token,
          audiences: audiences.map((aud) => aud.name),
          account,
        },
      };

      checkForDuplicates(lambdaInput, {
        onSuccess: (response) => {
          const highlights = audiences
            .filter((aud) => {
              const respDuplicates = response?.body.map(
                (res) => res.name.replace('/fbApi', '')
              );
              return respDuplicates.find((dupl) => dupl.includes(aud.name));
            })
            .map((aud) => aud.name);
          setHighlightedAudiences(highlights);
        },
        onError: (err) => {
          console.error({ err });
        },
      });
    }
  }, [isOpen]);

  function onModalClose() {
    setDuplicates([]);
    setHighlightedAudiences([]);
    onClose();
  }

  return (
    <MainModal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent maxW="575px">
        <ModalHeader
          pt={5}
          pl={6}
          pr={9}
          pb={3}
          fontSize="18px"
          lineHeight="28px"
        >
          Are you sure you want to send these lists to Facebook ?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ScrollbarFlex
            direction="column"
            h="full"
            overflowY="scroll"
            align="stretch"
            border="1px"
            borderColor="gray.200"
            borderRadius={2}
            p={4}
            pt={2}
            maxH="350px"
          >
            {audiencesWithoutDuplicate.map((audience) => (
              <Box p={2} pl={0} pr={3} key={audience.id}>
                <Flex
                  color={
                    highlightedAudiences.includes(audience.name)
                      ? `red.500`
                      : `gray.600`
                  }
                >
                  {highlightedAudiences.includes(audience.name) ? (
                    <Flex
                      onClick={() =>
                        setDuplicates((prev) => [...prev, audience.name])
                      }
                      _hover={{
                        cursor: "pointer",
                      }}
                      alignItems="center"
                    >
                      <Text mr={2}>{audience.name}</Text>{" "}
                      <CloseIcon boxSize="14px" color="red.500" />
                    </Flex>
                  ) : (
                    <Text mr={2}>{audience.name}</Text>
                  )}
                </Flex>
              </Box>
            ))}
          </ScrollbarFlex>
          <Flex width="full" mt={3}>
            {isDuplicateCheckLoading ? (
              <Flex
                alignItems="center"
                fontWeight="600"
                fontSize="14px"
                color="gray.700"
              >
                Checking for Duplicates <Spinner ml={3} size="sm" />
              </Flex>
            ) : (
              highlightedAudiences.length > 0 && (
                <Button
                  isLoading={isDuplicateCheckLoading}
                  fontSize="14px"
                  border="1px"
                  borderRadius={6}
                  borderColor="gray.200"
                  color="gray.700"
                  bg="white"
                  onClick={() => setDuplicates(highlightedAudiences)}
                >
                  Remove All Duplicates
                </Button>
              )
            )}
            <Text
              mt={2}
              textAlign="right"
              lineHeight="24px"
              fontWeight="600"
              color="gray.600"
              ml="auto"
            >
              Total Number of Lists: {audiencesWithoutDuplicate.length}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            bg="white"
            _hover={{
              background: "white",
            }}
            _active={{
              background: "white",
            }}
            onClick={onModalClose}
            mr={3}
            color="gray.700"
            fontWeight="600"
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            color="white"
            bg="gray.700"
            _hover={{
              background: "gray.800",
            }}
            fontWeight="600"
            onClick={handleSend}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </MainModal>
  );
}

export default Modal;
