import { Icon } from "@chakra-ui/react";

const SuccessIcon = (props) => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      d="M8.99999 17.3333C4.39749 17.3333 0.666656 13.6025 0.666656 8.99996C0.666656 4.39746 4.39749 0.666626 8.99999 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 8.99999 17.3333ZM8.16916 12.3333L14.0608 6.44079L12.8825 5.26246L8.16916 9.97663L5.81166 7.61913L4.63332 8.79746L8.16916 12.3333Z"
      fill="currentColor"
    />
  </Icon>
);

export default SuccessIcon;
