import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle } from "react";
import Modal from "./Modal";

function NextButton(
  { modalInfo, disabled, isLoading, onConfirm, onClick },
  ref
) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    closeModal() {
      onClose();
    },
    openModal() {
      onOpen();
    },
  }));

  return (
    <>
      <Button
        onClick={onClick}
        disabled={disabled}
        isLoading={isLoading}
        flexGrow={3}
        color="white"
        bg="gray.700"
        _hover={{
          background: "gray.800",
        }}
      >
        {"Next ->"}
      </Button>
      <Modal
        onConfirm={onConfirm}
        modalInfo={modalInfo}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
      />
    </>
  );
}

export default forwardRef(NextButton);
