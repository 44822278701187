import { Flex } from "@chakra-ui/react";

function Layout({ children }) {
  return (
    <Flex
      w="full"
      justify="flex-end"
      align="center"
      fontWeight="600"
      fontSize="14px"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
    >
      {children}
    </Flex>
  );
}

export default Layout;
