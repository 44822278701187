import { Container, Flex } from "@chakra-ui/react";

function AudienceManagerLayout({ children, rightSection }) {
  return (
    <Container maxW="container.xl" h="90vh" p={0}>
      <Flex h="100%">
        <Flex w="67%" p={4} pb={10}>
          {children}
        </Flex>
        <Flex direction="column" w="33%">
          {rightSection}
        </Flex>
      </Flex>
    </Container>
  );
}

export default AudienceManagerLayout;
