import { Flex } from "@chakra-ui/react";
import { useAccount } from "contexts/AccountContext";
import { useGetCampaigns } from "hooks/fbApi";
import CampaignListSelect from "components/CampaignListSelect";
import ColumnLayout from "components/ColumnLayout";

function CampaignWithAdSetSelect({ children }) {
  const { account } = useAccount();

  const {
    data: campaigns,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    hasNextPage,
  } = useGetCampaigns(account?.id);

  return (
    <Flex w="100%" border="1px" borderColor="gray.200" borderRadius={4}>
      <ColumnLayout
        borderTopStartRadius={4}
        title="Ad Campaigns"
        hasRightBorder
        border
        columnWidth="50%"
      >
        <CampaignListSelect
          campaigns={campaigns}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
        />
      </ColumnLayout>
      <ColumnLayout
        borderTopEndRadius={4}
        title="Ad Sets"
        columnWidth="50%"
        pl={0}
      >
        {children}
      </ColumnLayout>
    </Flex>
  );
}

export default CampaignWithAdSetSelect;
