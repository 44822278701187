import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import DownloadIcon from "components/icons/DownloadIcon";
import ThreeDotIcon from "components/icons/ThreeDotIcon";
import { GOD_LEVEL_WIDTHS } from "utils/constants";
import { addCommasToNumber } from "utils/helpers";

function ListRow({ item, onDownload, onUpload }) {
  const scorePercent = Math.round(item[1] * 100);
  return (
    <Flex
      border="1px"
      borderColor="gray.200"
      alignItems="center"
      borderTop="none"
      py={2}
    >
      <Box width={GOD_LEVEL_WIDTHS.nameWidth} pl={3}>
        {item[0]}
      </Box>
      <Box width={GOD_LEVEL_WIDTHS.scoreWidth}>
        <Flex w="full" alignItems="center">
          <Flex borderRadius={4} height="4px" w="70%" bg="gray.200">
            <Flex
              borderLeftRadius={4}
              height="4px"
              width={`${scorePercent}%`}
              bg="gray.700"
            ></Flex>
          </Flex>
          <Text w="20%" ml={4}>
            {scorePercent}%
          </Text>
        </Flex>
      </Box>
      <Box width={GOD_LEVEL_WIDTHS.sizeWidth}>{addCommasToNumber(item[2])}</Box>
      <Box width={GOD_LEVEL_WIDTHS.actionWidth} textAlign="right" mr={5}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<ThreeDotIcon />}
            variant="outline"
            borderRadius={100}
            bg="gray.100"
          />
          <MenuList>
            <MenuItem onClick={() => onUpload(item[3])}>
              Upload to Custom Audiences
            </MenuItem>
            <MenuItem
              onClick={() => onDownload(item[3])}
              icon={<DownloadIcon width="15px" height="20px" />}
            >
              Download the Audience
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

export default ListRow;
