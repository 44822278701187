import { useMutation, useQuery } from "react-query";
import { GodLevelService } from "services/godLevel";

const useGodLevelFactory = () => {
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6dHJ1ZSwiaWF0IjoxNjY5OTA5NjQxLCJqdGkiOiJmOGNlOWQwYi0wY2QxLTRlMmUtYTMzMi0xMjRlMDBiNTdmNjQiLCJuYmYiOjE2Njk5MDk2NDEsInR5cGUiOiJhY2Nlc3MiLCJzdWIiOjEsImlzX2FkbWluIjpmYWxzZX0.uHV8Rud0V1V_Gvb0QUpyxnSZklYGyC3kmMsyssaZRSo";
  return GodLevelService(token);
};

export const useFindAudiences = () => {
  const mutation = useMutation(useGodLevelFactory().findAudiences);
  return mutation;
};

export const useDownloadAudience = () => {
  const mutation = useMutation(useGodLevelFactory().downloadAudience);
  return mutation;
};

export const useUploadAudience = () => {
  const mutation = useMutation(useGodLevelFactory().uploadAudience);
  return mutation;
};

export const useGetStatistics = () => {
  const query = useQuery(
    "godLevelStatistics",
    useGodLevelFactory().getStatistics
  );

  return query;
};
