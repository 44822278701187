import CategorySection from "./CategorySection";
import CustomAudienceSection from "./CustomAudienceSection";
import FBAudienceSection from "./FBAudienceSection";
import BodyLayout from "pages/Layout/BodyLayout";
import OtherFiltersSection from "./OtherFiltersSection";

function AudienceDB() {
  return (
    <BodyLayout
      topSection={{ element: <OtherFiltersSection /> }}
      leftSection={{ element: <CategorySection />, bg: "gray.100" }}
      middleSection={{ element: <CustomAudienceSection /> }}
      rightSection={{ element: <FBAudienceSection />, bg: "blue.50" }}
    />
  );
}

export default AudienceDB;
