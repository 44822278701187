import { useAmplify } from "contexts/AmplifyContext";
import { UserService } from "services/user";
import { useQuery } from "react-query";

const useUserFactory = () => {
  const { API, graphqlOperation, Amplify } = useAmplify();
  return UserService({ API, graphqlOperation, Auth: Amplify.Auth });
};

export const useUser = () => {
  const query = useQuery("user", useUserFactory().getSignedInUser, {
    retry: (failureCount, error) =>
      error.statusCode === 404 && failureCount <= 3,
  });

  return query;
};
