import React, { createContext, useContext } from "react";

export const AmplifyContext = createContext({});

export const useAmplify = () => useContext(AmplifyContext);

export const AmplifyProvider = ({
  children,
  Amplify,
  graphqlOperation,
  API,
  Storage,
}) => {
  return (
    <AmplifyContext.Provider
      value={{ Amplify, API, graphqlOperation, Storage }}
    >
      {children}
    </AmplifyContext.Provider>
  );
};
