export const sleep = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const addPointsToNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const addCommasToNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatForCreateLookalike = (params) => {
  const ratios = params.similarity
    .filter((s) => s.isChecked)
    .map((s) => s.name);
  const countryData = params.locations
    .filter((l) => l.isChecked)
    .map((l) => l.name);
  const merge = params.merge;

  return { ratios, countryData, merge };
};
