import axios from "axios";
import { createAudience } from "graphql/mutations";
import { listCategories, audienceByName } from "graphql/custom-queries";
import { AMPLIFY_API, AMPLIFY_AUDIENCE_CREATION_API } from "utils/constants";
import {listCharacteristicAudiences, listCharacteristics} from "../graphql/queries";

export function AudienceService({ API, graphqlOperation }) {
  const createSingle = async (input) => {
    const response = await API.graphql(
      graphqlOperation(createAudience, { input })
    );

    return response.data;
  };

  const createMultiple = async (audiences) => {
    const response = await Promise.all(
      audiences.map((audience) => createSingle(audience))
    );

    return response;
  };

  const getAudiences = async (limit = 2000, filter = {}, onlyItems = true) => {
    const response = await API.graphql(
      graphqlOperation(audienceByName, { type: "Audience", limit: limit, filter: filter })
    );

    if (onlyItems) {
      return response.data?.audienceByName?.items;
    }
    return response.data?.audienceByName;
  };

  const getCategories = async () => {
    const response = await API.graphql(graphqlOperation(listCategories));

    return response.data?.listCategories?.items;
  };
  const getCharacteristics = async () => {
    const response = await API.graphql(graphqlOperation(listCharacteristics));

    return response.data?.listCharacteristics?.items;
  };

  const addCustomAudiencesToFB = async (input) => {
    try {
      const response = await axios.post(
          AMPLIFY_AUDIENCE_CREATION_API,
          input.body
      );
      return { body: response.data };
    } catch (e) {
      return { body: e.response.data }
    }
  };

  const checkForDuplicates = async (input) => {
    return await API.post(
      AMPLIFY_API,
      "/audience-duplicate-check",
      input
    );
  };

  const getCharacteristicAudiences = async (limit = 5000, filter = {}) => {
    const response = await API.graphql(
        graphqlOperation(listCharacteristicAudiences, {limit: limit, filter: filter})
    );

    return response.data?.listCharacteristicAudiences?.items;
  };

  const getAudiencesFromDB = async (input) => {
    const response = await API.post(AMPLIFY_API, "/audience-lists", {headers: {'Content-Type': 'application/json'}, body: input});
    return response.lists;
  }

  const mergeLists = async (input) => {
    const response = await API.post(AMPLIFY_API, "/audience-merge-lists", {headers: {'Content-Type': 'application/json'}, body: input});
    return response;
  }

  const createLists = async (input) => {
    const response = await API.post(AMPLIFY_API, "/audience-create", {headers: {'Content-Type': 'application/json'}, body: input});
    return response;
  }

  const updateList = async (input) => {
    const response = await API.post(AMPLIFY_API, "/audience-update", {headers: {'Content-Type': 'application/json'}, body: input});
    return response;
  }

  const deleteList = async (input) => {
    const response = await API.post(AMPLIFY_API, "/audience-delete", {headers: {'Content-Type': 'application/json'}, body: input});
    return response;
  }

  return {
    createMultiple,
    getAudiences,
    getCategories,
    addCustomAudiencesToFB,
    checkForDuplicates,
    getCharacteristics,
    getCharacteristicAudiences,
    getAudiencesFromDB,
    mergeLists,
    createLists,
    updateList,
    deleteList
  };
}
