import AudienceManagerLayout from "pages/AudienceManager/AudienceManagerLayout";
import BusinessAndAccountInfo from "pages/AudienceManager/BusinessAndAccountInfo";
import CampaignWithAdSetSelect from "pages/AudienceManager/CampaignWithAdSetSelect";
import RightSectionLayout from "components/RightSectionLayout";
import RightSectionBody from "./RightSectionBody";
import AdSetListSelect from "./AdSetListSelect";

function AudiencesToNAdSetsSection() {
  return (
    <AudienceManagerLayout
      rightSection={
        <RightSectionLayout hasBorder header={<BusinessAndAccountInfo />}>
          <RightSectionBody />
        </RightSectionLayout>
      }
    >
      <CampaignWithAdSetSelect>
        <AdSetListSelect />
      </CampaignWithAdSetSelect>
    </AudienceManagerLayout>
  );
}

export default AudiencesToNAdSetsSection;
