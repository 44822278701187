import { Box } from "@chakra-ui/react";

function CustomRadio({ name = "radio", onRadioChange, children }) {
  return (
    <Box className="container">
      <Box
        as="div"
        sx={{
          ".container": {
            display: "block",
            position: "relative",
            paddingLeft: "25px",
            cursor: "pointer",
          },

          ".container input": {
            position: "absolute",
            opacity: "0",
            cursor: "pointer",
          },

          ".checkmark": {
            position: "absolute",
            top: "50%",
            left: "0",
            height: "18px",
            width: "18px",
            backgroundColor: "#fff",
            border: "2px solid #E2E8F0",
            borderRadius: "50%",
            marginTop: "-9px",
          },

          ".container:hover input ~ .checkmark": {
            backgroundColor: "#ccc",
          },

          ".container input:checked ~ .checkmark": {
            backgroundColor: "#2196F3",
            border: "2px solid #2196F3",
          },

          ".container input:disabled ~ .checkmark": {
            backgroundColor: "#ccc",
            cursor: "not-allowed",
          },

          ".checkmark:after": {
            content: "''",
            position: "absolute",
            display: "none",
          },

          ".container input:checked ~ .checkmark:after": {
            display: "block",
          },

          ".container .checkmark:after": {
            top: "4px",
            left: "4px",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            background: "white",
          },
        }}
      >
        <label className="container">
          <input
            onChange={(e) => onRadioChange(e.target.value)}
            type="radio"
            name={name}
            value={JSON.stringify(children)}
          />
          <span className="checkmark"></span>
          {children.name}
        </label>
      </Box>
    </Box>
  );
}

export default CustomRadio;
