import { useRef, useState } from "react";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { useAudience } from "contexts/AudienceContext";
import { useCreateLookalikes } from "hooks/fbApi";
import CreateLookalikeButton from "./CreateLookalikeButton";
import Toast from "components/Toast";
import { useAccount } from "contexts/AccountContext";
import ParameterSection from "./ParameterSection";
import { formatForCreateLookalike } from "utils/helpers";
import { UsageLogService } from "../../../services/usageLog";
import {API} from "aws-amplify";
import {useUser} from "../../../hooks/user";
import { useCreateUsageLog } from "../../../hooks/usageLog";

function LookalikeContent() {
  const queryClient = useQueryClient();
  const { numberOfSelected: numberOfLists } = useAudience();
  const { mutate: createLookalikes, isLoading } = useCreateLookalikes();
  const { audiences } = useAudience();
  const { account } = useAccount();
  const { data: user } = useUser();
  const { mutate: createUsageLog } = useCreateUsageLog();

  const [params, setParams] = useState(() => {
    const params = {
      similarity: [
        { name: "1", label: "1%", isChecked: true },
        { name: "2", label: "2%", isChecked: true },
        { name: "3", label: "3%", isChecked: true },
      ],
      merge: false,
      locations: [
        { name: "worldwide", label: "Worldwide", isChecked: true },
        { name: "europe", label: "Europe", isChecked: true },
        { name: "asia", label: "Asia", isChecked: true },
        { name: "us", label: "United States", isChecked: true },
        { name: "gb", label: "United Kingdom", isChecked: true },
        { name: "de", label: "Germany", isChecked: true },
        { name: "nl", label: "Netherlands", isChecked: true },
        { name: "fr", label: "France", isChecked: true },
        { name: "it", label: "Italy", isChecked: true },
        { name: "be", label: "Belgium", isChecked: true },
        { name: "ca", label: "Canada", isChecked: true },
        { name: "au", label: "Australia", isChecked: true },
        { name: "jp", label: "Japan", isChecked: true },
        { name: "sg", label: "Singapore", isChecked: true },
      ],
    };

    return params;
  });

  const toast = useToast();
  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  function handleLookalikesCreate() {
    const input = audiences.map((aud) => ({
      accountId: account.id,
      audienceId: aud.id,
      audienceName: aud.name,
    }));

    function refetchFBAudiences() {
      queryClient.setQueriesData(["fbaudience-select", account.id]);
      queryClient.invalidateQueries(["fbaudience-select", account.id], {
        refetchPage: (_, index) => index === 0,
      });
    }

    createLookalikes(
      {
        input: {
          items: input,
          params: formatForCreateLookalike(params),
        },
      },
      {
        onSuccess: (result) => {
          let successMessages = "";
          let errorMessages = "";
          let llCount = 0;

          result.forEach((res) => {
            successMessages += res.successMessages.join("<br/>");
            errorMessages += res.errorMessages.join("<br/>");
            llCount += res.successMessages.length;
          });

          if (successMessages) {
            toastSuccessRef.current = toast({
              duration: null,
              position: "top",
              render: () => (
                <Toast
                  title="Succeed"
                  text={successMessages}
                  onClick={() => closeToast(toastSuccessRef)}
                />
              ),
            });
            const usageLogInput = {
              username: user.username,
              audienceName: input.map((inp) => { return inp.audienceName }).join(","),
              accountName: account.name,
              businessName: "Bizman - Business Manager",
              numberOfUsers: llCount,
              type: "CreateLL",
            };
            console.log({usageLogInput,successMessages})
            try {
              UsageLogService({API}).logToDB(usageLogInput);
              // const usageLog = UsageLogService({API}).logToDB(usageLogInput);
              // usageLog && console.log({usageLog});
              // createUsageLog(
              //     {input},
              //     {
              //       onSuccess: (result) => {
              //         console.log(result);
              //       },
              //       onError: (err) => {
              //         console.log(err)
              //       }
              //     }
              // );
              // const usageLog = UsageLogService({API}).addUsageLogToDB(input);
            } catch (error) {
              console.log({error})
            }
          }
          if (errorMessages) {
            toastErrorRef.current = toast({
              duration: null,
              position: "top",
              render: () => (
                <Toast
                  title="Error"
                  type="error"
                  text={errorMessages}
                  onClick={() => closeToast(toastErrorRef)}
                />
              ),
            });
          }

          refetchFBAudiences();
        },
        onError: (err) => {
          toastErrorRef.current = toast({
            duration: null,
            position: "top",
            render: () => (
              <Toast
                title="Error"
                type="error"
                text={err?.message}
                onClick={() => closeToast(toastErrorRef)}
              />
            ),
          });
        },
      }
    );

    // createUsageLog(
    //     {
    //       input: {
    //         "username": "facebook_2827281257402677",
    //         "audienceName": "383_Unit1 Faro_e-commerce_KSS.csv",
    //         "accountName": "Test add account",
    //         "businessName": "Bizman - Business Manager",
    //         "numberOfUsers": 90,
    //         "type": "CreateLL"
    //       },
    //     },
    //     {
    //       onSuccess: (result) => {
    //         console.log({result});
    //       },
    //       onError: (err) => {
    //         console.log({err})
    //       }
    //     }
    // );
  }

  function handleReset() {
    setParams((oldParams) => {
      for (const param in oldParams) {
        if(Array.isArray(oldParams[param])) {
          for (const p of oldParams[param]) {
            if (!p.isChecked) {
              p.isChecked = true;
            }
          }
        } else if (typeof oldParams[param] == 'boolean') {
          oldParams[param] = false;
        }
      }

      return { ...oldParams };
    });
  }

  function handleCheck(checkInfo) {
    setParams((oldParams) => {
      const updatedParams = oldParams[checkInfo.keyName].reduce((prev, acc) => {
        if (acc.name === checkInfo.name) {
          acc.isChecked = checkInfo.isChecked;
        }
        return [...prev, acc];
      }, []);

      oldParams[checkInfo.keyName] = updatedParams;

      return { ...oldParams };
    });
  }

  return (
    <Flex direction="column" overflowY="hidden" h="full">
      <Flex direction="column" p={5} pb={0} h="85%" w="full">
        <Flex direction="column" maxH="20%" w="full">
          <Text fontWeight="600">
            {numberOfLists} {numberOfLists > 1 ? "lists" : "list"} selected
          </Text>
          <Box borderBottom="1px" borderColor="gray.200" pb={4}>
            <Text>
              You are going to create lookalikes with the following parameters:
            </Text>
          </Box>
        </Flex>
        <Flex minH="50%" w="full">
          <ParameterSection
            params={params}
            onReset={handleReset}
            onCheck={handleCheck}
          />
        </Flex>
      </Flex>

      <Flex
        mt="auto"
        borderTop="1px"
        borderColor="gray.200"
        w="full"
        px={6}
        py={5}
        h="15%"
        alignItems="center"
      >
        <CreateLookalikeButton
          onClick={handleLookalikesCreate}
          isLoading={isLoading}
          disabled={
            params.similarity.every((item) => !item.isChecked) ||
            params.locations.every((item) => !item.isChecked)
          }
        />
      </Flex>
    </Flex>
  );
}

export default LookalikeContent;
