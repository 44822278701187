import { Fragment } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import Loading from "components/Loading";
import ScrollbarFlex from "components/ScrollbarFlex";
import CustomRadio from "components/CustomRadio";
import { trigger } from "utils/events";
import NoSelectedDisplay from "components/NoSelectedDisplay";
import { useCustomEventListener } from "utils/onCustomEventHook";
import { useGetAdSets } from "hooks/fbApi";

function AdSetListSelect() {
  const { payload: campaign } = useCustomEventListener("campaign:select");

  const {
    data: adSets,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    hasNextPage,
  } = useGetAdSets(campaign?.id, {
    enabled: Boolean(campaign?.id),
  });

  function handleAdSetScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 20) {
      !isFetchingNextPage && hasNextPage && fetchNextPage();
    }
  }
  return (
    <ScrollbarFlex
      direction="column"
      onScroll={handleAdSetScroll}
      overflowY="auto"
      overflowX="hidden"
      spacing={4}
      align="stretch"
      color="gray.900"
      width="full"
      height="full"
      pl={6}
    >
      {!isFetchingNextPage && isFetching ? (
        <Loading />
      ) : !adSets ? (
        <NoSelectedDisplay />
      ) : (
        adSets?.pages?.map((page, index) => (
          <Fragment key={index}>
            {page.data.data.map((ad) => (
              <Box mb={4} color="gray.900" key={ad.id} lineHeight="24px">
                <CustomRadio
                  name="adSet"
                  onRadioChange={(adSet) =>
                    trigger("adSet:select", JSON.parse(adSet))
                  }
                >
                  {ad}
                </CustomRadio>
              </Box>
            ))}
          </Fragment>
        ))
      )}
      <Flex p={2} align="center" justify="center">
        {isFetchingNextPage && <Spinner />}
      </Flex>
    </ScrollbarFlex>
  );
}

export default AdSetListSelect;
