import { useRef, useState } from "react";
import { useAccount } from "contexts/AccountContext";
import { useQueryClient } from "react-query";
import { useUser } from "hooks/user";
import { useAddCustomAudiencesToFB } from "hooks/audience";
import config from "aws-exports";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { getSuccessRate, getInvalidEntries } from "utils/queryResponses";
import { trigger } from "utils/events";
import Toast from "components/Toast";
import FbButton from "./FbButton";
import Modal from "./Modal";

function SendToFbSection({ audiences }) {
  const [duplicates, setDuplicates] = useState([]);

  const { data: user } = useUser();
  const { account, business } = useAccount();
  const { mutate: addCustomAudiencesToFB, isLoading } =
    useAddCustomAudiencesToFB();

  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const toastSuccessRef = useRef();
  const toastErrorRef = useRef();

  function closeToast(ref) {
    if (ref.current) {
      toast.close(ref.current);
    }
  }

  function closeModal() {
    setDuplicates([]);
    onClose();
  }

  async function handleSend() {
    const token = user.attributes["custom:token"];
    // const username = user.attributes.name;
    const username = user.username;
    const filteredAudiences = audiences
      .map((aud) => aud.name)
      .filter((audName) => !duplicates.includes(audName));

    const lambdaInput = {
      body: {
        account,
        business: {
          id: business.id,
          name: business.name,
        },
        username,
        token,
        audiences: filteredAudiences,
        xApiKey: config.aws_appsync_apiKey,
      },
    };

    addCustomAudiencesToFB(lambdaInput, {
      onSuccess: (response) => {
        handleSuccess(response);
      },
      onError: (err) => {
        toastErrorRef.current = toast({
          duration: null,
          position: "top",
          render: () => (
            <Toast
              title="Error"
              type="error"
              text={err?.message}
              onClick={() => closeToast(toastErrorRef)}
            />
          ),
        });
      },
    });
  }

  function handleSuccess({ body: response }) {
    closeModal();
    if (response.error) {
      toastErrorRef.current = toast({
        duration: null,
        position: "top",
        render: () => (
            <Toast
              title=""
              type="error"
              text={response.error}
              onClick={() => closeToast(toastErrorRef)}
            />
        ),
      });
    }
    if (response && Array.isArray(response)) {
      if (getInvalidEntries(response).length > 0) {
        toastErrorRef.current = toast({
          duration: null,
          position: "top",
          render: () => (
            <Toast
              title="Invalid Entries"
              type="error"
              invalidEntryList={getInvalidEntries(response)}
              onClick={() => closeToast(toastErrorRef)}
            />
          ),
        });
      }

      if (Number(getSuccessRate(response).charAt(0)) > 0) {
        toastSuccessRef.current = toast({
          duration: null,
          position: "top",
          render: () => (
            <Toast
              title="Succeed"
              text={`${getSuccessRate(response)} of Lists was sent to Facebook`}
              onClick={() => closeToast(toastSuccessRef)}
            />
          ),
        });
      }
    }
    refetchAccountAudiences();
    trigger("customAudience:unselectAll");
  }

  function refetchAccountAudiences() {
    queryClient.setQueriesData(["fbaudience-display", account.id]);
    queryClient.invalidateQueries(["fbaudience-display", account.id], {
      refetchPage: (_, index) => index === 0,
    });
  }

  return (
    <>
      <FbButton
        isDisabled={audiences.length === 0 || !account?.id}
        onOpen={onOpen}
      />
      <Modal
        handleSend={handleSend}
        audiences={audiences}
        duplicates={duplicates}
        setDuplicates={setDuplicates}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

export default SendToFbSection;
